import { GridColDef } from '@mui/x-data-grid-premium';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { DecedentNameCell } from './DecedentNameCell';
import { DashboardDataGridProps } from './useDashboardDataGridAPI.hook';
import { format } from "date-fns";

export function InHouseDataGrid({ data, isLoading }: DashboardDataGridProps) {
  const columns: GridColDef[] = [
    {
      field: 'caseID',
      headerName: 'CaseID',
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'decedentName',
      headerName: 'Decedent',
      width: 180,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: params => <DecedentNameCell {...params} />,
    },
    {
      field: 'storageLocation',
      headerName: 'Storage Location',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'examPhysician',
      headerName: 'Pathologist',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'investigator',
      headerName: 'MLDI',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'checkinDateTime',
      headerName: 'Check-In Date',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      type: 'dateTime',
      valueGetter: params => {
        return params.value != null ? new Date(params.value) : null;
      },
      valueFormatter: params => {
        return params.value ? format(new Date(params.value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
  ];

  return (
    <SiloDataGrid
      loading={isLoading}
      listresults={data}
      columnsInput={columns}
      idcolumn='caseID'
      gridheight={200}
      gridcolor='#006400'
      title='In House '
      tooltip='In House'
      casecount
      rowsperpage={50}
      filterModel={undefined}
      columnsVisibility={undefined}
    />
  );
}
