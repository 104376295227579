import AddIcon from '@mui/icons-material/Add';
import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  TextField,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CondensedDataGrid from '../components/CondensedDataGrid';
import { useAuth } from '../utils/auth/AuthService';

export default function CaseNotes({ department }) {
  const [showInput, setShowInput] = useState(false);
  const [noteTypeOptions, setNoteTypeOptions] = useState([]);
  const [caseNotes, setCaseNotes] = useState([]);
  const { REACT_APP_API_URL } = process.env;
  const [selectedNote, setSelectedNote] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { user } = useAuth();
  const formik = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLaptop = useMediaQuery(theme.breakpoints.between('md', 'lg'));

  const OTHER_NOTE_TYPE_SEQ = 'ED4531F2-3757-456D-AEF7-9EB9322CC0F2';

  const isOtherNoteTypeSelected = selectedNote?.noteType?.optionSeq === OTHER_NOTE_TYPE_SEQ;
  const isOtherNoteTypeEmpty = isOtherNoteTypeSelected && !selectedNote?.otherNoteType;

  const handleAddNoteClick = () => {
    setShowInput(true);
    setSelectedNote({
      supplementalCaseInfoDetailsSeq: uuidv4(),
      noteType: null,
      note: '',
      department,
      createdBy: {
        optionSeq: user.userSeq,
        optionName: `${user?.userLastName} ${user?.userFirstName}`,
      },
      createdOn: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    });
  };

  const handleRowClick = params => {
    if (
      String(user.userSeq).toLowerCase() === String(params.row.createdBy.optionSeq).toLowerCase()
    ) {
      setShowInput(true);
      const plainTextNote = { ...params.row, note: convertHtmlToPlainText(params.row.note) };
      setSelectedNote(plainTextNote);
    } else {
      setOpenSnackbar(true);
      setShowInput(false);
    }
  };

  const handleInputChange = (name, value) => {
    const updatedNote = { ...selectedNote, [name]: value };
    setSelectedNote(updatedNote);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSubmit = async () => {
    let formData = new FormData();
    formData.append('caseNote', JSON.stringify(selectedNote));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);
    formData.append('userSeq', user?.userSeq);

    if (user && user.token) {
      fetch(REACT_APP_API_URL + 'updatecasenote', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occurred');
          }
          return res.json();
        })
        .then(data => {})
        .catch(e => {
          alert(e);
        })
        .finally(() => {
          fetchCaseNotes();
          setShowInput(false);
        });
    } else {
      console.log('user or token is null');
    }
    return false;
  };

  const stripHtmlTags = html => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const caseNotesFileName = formik.values?.caseSummary?.caseNumber
    ? `${formik.values?.caseSummary?.caseNumber}-Case Notes`
    : 'Case Notes';

  const exceljsPreProcess = ({ workbook, worksheet }) => {
    worksheet.insertRow(1, []);
    worksheet.insertRow(2, []);

    worksheet.mergeCells('A1:E1');
    const titleCell = worksheet.getCell('A1');
    titleCell.value = caseNotesFileName;
    titleCell.alignment = { vertical: 'middle', horizontal: 'center' };
    titleCell.font = { bold: true, size: 16 };

    worksheet.getColumn('A').alignment = { vertical: 'top', horizontal: 'left' };

    const noteColumn = worksheet.getColumn('B');
    noteColumn.width = 50;
    noteColumn.alignment = { wrapText: true };

    worksheet.getColumn('C').alignment = { vertical: 'top', horizontal: 'left' };

    worksheet.getColumn('D').alignment = { vertical: 'top', horizontal: 'left' };
    worksheet.getColumn('D').width = 18;

    worksheet.getColumn('E').alignment = { vertical: 'top', horizontal: 'left' };
    worksheet.getColumn('E').width = 18;
  };

  const exceljsPostProcess = ({ worksheet }) => {
    const noteColumn = worksheet.getColumn('B');

    noteColumn.eachCell(cell => {
      cell.value = stripHtmlTags(cell.value);
    });

    const headerRow = worksheet.getRow(3);
    headerRow.eachCell(cell => {
      cell.font = { bold: true };
    });

    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell, colNumber) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={() => handleAddNoteClick()}>
          New Note
        </Button>

        <GridToolbarExport
          excelOptions={{ exceljsPreProcess, exceljsPostProcess, fileName: caseNotesFileName }}
          printOptions={{ disableToolbarButton: true }}
        />
      </GridToolbarContainer>
    );
  }

  const convertHtmlToPlainText = html => {
    if (!html) return '';

    html = html.replace(/<b>/gi, '*').replace(/<\/b>/gi, '*');
    html = html.replace(/<strong>/gi, '*').replace(/<\/strong>/gi, '*');

    html = html.replace(/<i>/gi, '_').replace(/<\/i>/gi, '_');
    html = html.replace(/<em>/gi, '_').replace(/<\/em>/gi, '_');

    html = html.replace(/<u>/gi, '__').replace(/<\/u>/gi, '__');

    html = html.replace(/<br\s*\/?>/gi, '\n');
    html = html.replace(/<\/?p>/gi, '\n');

    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  const caseNotesColumns = [
    {
      field: 'supplementalCaseInfoDetailsSeq',
      headerName: 'SupplementalCaseInfoDetailsSeq',
      hide: true,
      editable: false,
    },
    {
      field: 'noteType',
      headerName: 'Note Type',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: params => {
        if (params.row.noteType?.optionSeq === OTHER_NOTE_TYPE_SEQ) {
          return params.row.otherNoteType || 'Other';
        }
        return params.value ? params.value.optionName : ''; // Display optionName
      },
      renderCell: params => (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          {params.value} {/* Ensure optionName is rendered */}
        </div>
      ),
    },
    {
      field: 'note',
      headerName: 'Note',
      minWidth: 200, // Slightly increased minimum width for the note field
      flex: 4, // Increased flex to give this field the most space
      editable: false,
      renderCell: params => (
        <div
          style={{ whiteSpace: 'pre-wrap', display: 'flex', alignItems: 'flex-start' }}
          dangerouslySetInnerHTML={{ __html: params.value }}
        />
      ),
    },
    {
      field: 'department',
      headerName: 'Department',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: params => params.value?.optionName || '', // Display optionName
      renderCell: params => (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          {params.value} {/* Ensure optionName is rendered */}
        </div>
      ),
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: params => params.value?.optionName || '', // Display optionName
      renderCell: params => (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          {params.value} {/* Ensure optionName is rendered */}
        </div>
      ),
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: params => (params.value ? format(new Date(params.value), 'MM/dd/yyyy, HH:mm') : null), // Format the date
      renderCell: params => (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          {params.value} {/* Render formatted date */}
        </div>
      ),
    },
  ];

  const defaultSortModel = [
    {
      field: 'createdOn',
      sort: 'desc',
    },
  ];

  const fetchNoteType = async () => {
    fetch(REACT_APP_API_URL + 'getnotetypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setNoteTypeOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchCaseNotes = async () => {
    const response = await fetch(
      REACT_APP_API_URL + 'getcasenotes?caseSeq=' + formik.values?.caseSummary?.caseSeq,
      {
        method: 'GET',
      }
    );
    const data = await response.json();
    setCaseNotes(data);
  };

  useEffect(() => {
    fetchNoteType();
    fetchCaseNotes();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            You do not have permission to edit this note.
          </Alert>
        </Snackbar>
        <Grid item xs={12}>
          <CondensedDataGrid
            columnsVisibility={{ supplementalCaseInfoDetailsSeq: false }}
            listresults={caseNotes || []}
            columnsInput={caseNotesColumns}
            gridheight={caseNotes?.length > 0 ? 'auto' : 120}
            idcolumn="supplementalCaseInfoDetailsSeq"
            rowsperpage={5}
            gridcolor="#4b21a6"
            tooltip="A list of case notes associated with this case"
            toolbarComponent={CustomToolbar}
            onRowClick={params => handleRowClick(params)}
            sortModel={defaultSortModel}
            checkboxSelection={true}
            sx={{ minHeight: '400px', maxHeight: 'auto' }} // Reduced layout shift
          />
        </Grid>
      </Grid>

      {showInput && (
        <Dialog
          open={showInput}
          onClose={() => setShowInput(false)}
          PaperProps={{
            style: {
              minWidth: isMobile ? '95%' : isLaptop ? '70%' : '50%',
              margin: '0 auto',
              minHeight: '400px', // Stabilized height
            },
          }}
        >
          <DialogTitle>
            <Typography variant="subtitle1" color="gray" textTransform="uppercase">
              <strong>Add/Edit Notes</strong>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item lg={4} xs={12} md={6} sm={12}>
                <Autocomplete
                  id="noteType"
                  name="noteType"
                  options={noteTypeOptions}
                  value={selectedNote ? selectedNote.noteType : null}
                  onChange={(event, newValue) => handleInputChange('noteType', newValue)}
                  getOptionLabel={option => option.optionName}
                  renderInput={params => <TextField {...params} label="Note Type" />}
                />
              </Grid>

              {isOtherNoteTypeSelected && (
                <Grid item lg={4} xs={12} md={6} sm={12}>
                  <TextField
                    label="Other Note Type"
                    name="otherNoteType"
                    type="text"
                    value={selectedNote ? selectedNote.otherNoteType : ''}
                    onChange={e => handleInputChange(e.target.name, e.target.value)}
                    fullWidth
                  />
                </Grid>
              )}

              <Grid item lg={12} xs={12} md={12} sm={12}>
                <TextField
                  label="Note"
                  name="note"
                  type="text"
                  multiline
                  value={selectedNote ? selectedNote.note : ''}
                  onChange={e => handleInputChange(e.target.name, e.target.value)}
                  fullWidth
                  InputProps={{
                    inputComponent: TextareaAutosize,
                    inputProps: {
                      minRows: 1,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <DialogActions style={{ justifyContent: 'right', marginTop: '1rem' }}>
              <Button type="submit" variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
              <Button color="error" onClick={() => setShowInput(false)}>
                Cancel
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
