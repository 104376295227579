import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import CaseNotes from '../../../components/CaseNotes';
import CaseDocuments from '../../../components/CaseRecords/CaseDocuments';
import { useAuth } from '../../../utils/auth/AuthService';
import { AssignmentTypes } from '../../../utils/constants/constants';
import CertificationView from './CertificationView';
import ChargeableRequestsView from './RecordsModules/ChargeableRequestsView';

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useLocation } from 'react-router';
import useStickyState from '../../../utils/functions/useStickyState';
import { CourtDocumentsView } from './RecordsModules/CourtDocumentsView';

export default function RecordsView({ showNotes = true }) {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useStickyState(
    0,
    'case.records.tab',
    location.state?.cmscaseid
  );
  const { user } = useAuth();
  const formik = useFormikContext();

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const authorizedToEdit = user.roleCheck([
    'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
    'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
    'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
    '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
    '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
  ]);

  const voidCaseAccess = user.roleCheck(['451', 'f45']); //Sys admin, case super admin

  return (
    <form>
      <TableContainer component={Paper} variant='outlined'>
        <Table sx={{ minWidth: 650 }}>
          <TableBody>
            <TableRow>
              <TableCell component='th' scope='row'>
                <Typography variant='subtitle1' color='gray' sx={{ fontSize: '.675rem' }}>
                  <strong>CASE STATUS</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <FormControl>
                  <RadioGroup
                    id='caseCertification.caseStatus'
                    name='caseCertification.caseStatus'
                    value={
                      formik.values != null ? formik.values?.caseCertification?.caseStatus : ''
                    }
                    onChange={formik.handleChange}
                    row
                  >
                    <FormControlLabel
                      disabled={!formik.status.editing || !authorizedToEdit}
                      value='O'
                      control={<Radio />}
                      label={<Typography variant='body2'>Open</Typography>}
                    />
                    <FormControlLabel
                      disabled={!formik.status.editing || !authorizedToEdit}
                      value='C'
                      control={<Radio />}
                      label={<Typography variant='body2'>Closed</Typography>}
                    />
                    {voidCaseAccess && (
                      <FormControlLabel
                        disabled={!formik.status.editing}
                        value='V'
                        control={<Radio />}
                        label={<Typography variant='body2'>Void</Typography>}
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <br />
      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#555', m: 0, mt: 0, mb: 0, pb: 0 }} />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label='case records tabs'>
            <Tab label='Documents' />
            <Tab label='File Requests' />
            <Tab label='Court Records' />
            <Tab label='Case Notes' />
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          {selectedTab === 0 && (
            <>
              <CaseDocuments caseinfo={formik.values?.caseSummary} />

              <br />
              <TableContainer component={Paper} variant='outlined'>
                <Table sx={{ minWidth: 650 }}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle1' color='gray' sx={{ fontSize: '.675rem' }}>
                          <strong>CERTIFICATION</strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <CertificationView
                          caseinfo={formik.values}
                          editing={formik.status.editing}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {selectedTab === 1 && (
            <ChargeableRequestsView caseinfo={formik.values} editing={formik.status.editing} />
          )}

          {selectedTab === 2 && <CourtDocumentsView />}

          {selectedTab === 3 && <CaseNotes department={AssignmentTypes.Records} />}
        </Grid>
      </Grid>

      {/* {showNotes && (
        <>
          <br />
          <br />
          <Grid item xs={12}>
            <CaseNotes department={AssignmentTypes.Records} />
          </Grid>
        </>
      )} */}
    </form>
  );
}
