import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // For success icon
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import { Document, Page, StyleSheet, Text, View, pdf } from '@react-pdf/renderer';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth/AuthService';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 8,
    fontFamily: 'Times-Roman',
  },
  stamp: {
    height: '2in',
    backgroundColor: '#eeeeee',
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  row: {
    position: 'relative',
    marginLeft: 5,
  },
  contentRow: {
    flexDirection: 'row',
    marginBottom: 2,
  },
  keyValuePair: {
    flexDirection: 'row',
  },
  contentKeyLeft: {
    fontFamily: 'Times-Bold',
  },
  contentValueLeft: {
    marginLeft: 3,
  },
  contentKeyRight: {
    position: 'absolute',
    left: 400,
    fontFamily: 'Times-Bold',
  },
  contentValueRight: {
    position: 'absolute',
    left: 435,
    fontWeight: 300,
  },
  boldUnderlineText: {
    fontFamily: 'Times-Bold',
    textDecoration: 'underline',
  },
  boldColumn: {
    fontFamily: 'Times-Bold',
    width: 120,
  },
  content: {
    width: 120,
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 12,
  },
  text: {
    lineHeight: 1.3,
  },
  spaceBreak: {
    marginTop: 20,
  },
  smallSpaceBreak: {
    marginTop: 10,
  },
  section: {
    marginBottom: 5,
    marginLeft: 5,
  },
  footer: {
    marginTop: 10,
    borderTop: 1,
    paddingTop: 2,
    textAlign: 'left',
    fontSize: 8,
  },
});

const formatName = name => {
  if (!name) return ''; // Return an empty string if no name is provided

  const [lname, fname] = name.split(',').map(part => part.trim());

  return `${fname} ${lname}`;
};

const splitDateTime = dateTimeString => {
  if (!dateTimeString) {
    return { date: null, time: null };
  }

  const [date, timeWithMilliseconds] = dateTimeString.split('T');
  const time = timeWithMilliseconds?.split('.')?.[0];

  return { date, time };
};

// Create PDF Document component
const INVDataSheet: React.FC<{
  formik: ReturnType<typeof useFormikContext<any>>;
  bodyLocationState: string;
  bodyLocationCountry: string;
}> = ({ formik, bodyLocationState, bodyLocationCountry }) => {
  const caseInvest = formik.values?.caseInvestigations;

  const { date, time } = splitDateTime(caseInvest?.meNotified);

  const next_of_kin = formik.values?.caseDemographics?.informantList
    ?.filter(informant => informant.nextOfKin == 1)
    .map(informant => ({
      name: `${informant.personLastName}, ${informant.personFirstName}`,
      relationship: informant?.relship?.optionName || null,
      phone_number: informant?.informantAddress?.contactItem?.[0]?.contactItemDetails || null,
    }));

  const identification = formik.values?.caseDemographics?.identifications
    ?.filter(identif => identif.isAccept === true)
    .map(identif => {
      const name = `${identif?.identifiedBy?.optionName}`;
      const kin = next_of_kin?.find(k => k.name === name);

      return {
        name: name,
        modality: identif?.modality?.optionName || null,
        relationship: kin ? kin.relationship : '-',
      };
    });

  const medications = caseInvest?.medicationHistory?.map(med => med.medicationName).join(', ');

  const medicalHistory = caseInvest?.pastMedicalHistory
    ?.map(med =>
      med.optionName === 'Other' ? caseInvest?.pastMedicalHistoryOther : med.optionName
    )
    .join(', ');

  const processList = (sourceList, fieldName, otherFormikField) => {
    return (
      sourceList
        ?.map(item => {
          let itemName = item[fieldName]?.optionName;

          if (itemName == 'Other' && item[otherFormikField]) {
            itemName = item[otherFormikField];
          }

          return itemName ? { item: itemName } : null;
        })
        .filter(Boolean) || []
    );
  };

  // Now, use the helper function to process each list
  const clothingList = processList(
    formik.values?.caseMortuary?.propertyInventory?.propertyClothing,
    'clothingItem',
    'otherClothing'
  );
  const documentList = processList(
    formik.values?.caseMortuary?.propertyInventory?.propertyDocument,
    'documentItem',
    'otherDocument'
  );
  const currencyList = processList(
    formik.values?.caseMortuary?.propertyInventory?.propertyCurrency,
    'documentItem',
    'otherDocument'
  );
  const jewelryList = processList(
    formik.values?.caseMortuary?.propertyInventory?.propertyJewelry,
    'jewelryItem',
    'otherJewelry'
  );
  const evidenceList = processList(
    formik.values?.caseMortuary?.propertyInventory?.propertyEvidence,
    'evidenceItem',
    'otherEvidence'
  );
  const otherPIList = processList(
    formik.values?.caseMortuary?.propertyInventory?.otherPI,
    'personalItem',
    'otherPropInventory'
  );

  const allItemsList = {
    clothing: clothingList.length > 0 ? clothingList : null,
    documents: documentList?.length > 0 ? documentList : null,
    evidence: evidenceList?.length > 0 ? evidenceList : null,
    jewelry: jewelryList?.length > 0 ? jewelryList : null,
    otherPI: otherPIList?.length > 0 ? otherPIList : null,
  };

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.stamp}>{/* <Text>Stamp Area</Text> */}</View>

        <Text style={styles.title}>INVESTIGATIVE DATA SHEET</Text>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.contentKeyLeft}>DECEDENT:</Text>
            <Text style={styles.contentValueLeft}>
              {formik.values?.caseSummary.decedentInfo?.decedentName ?? '-'}
            </Text>
          </View>
          <Text style={styles.contentKeyRight}>DATE:</Text>
          <Text style={styles.contentValueRight}>{date && date}</Text>
        </View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.contentKeyLeft}>CASEID:</Text>
            <Text style={styles.contentValueLeft}>
              {formik.values?.caseSummary?.caseNumber ?? '-'}
            </Text>
          </View>

          <Text style={styles.contentKeyRight}>TIME:</Text>
          <Text style={styles.contentValueRight}>{time && time}</Text>
        </View>

        <View style={styles.spaceBreak}></View>

        <View style={styles.row}>
          <Text style={styles.boldUnderlineText}>CASE SYNOPSIS:</Text>
          <Text style={styles.text}>{formik.values?.caseSummary?.caseSynopsis ?? '-'}</Text>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.boldUnderlineText}>INITIAL REPORTER:</Text>
            <Text style={styles.contentValueLeft}>
              {caseInvest?.caseReporter && formatName(caseInvest?.caseReporter) + ', '}
              {formik.values?.caseReporterSummary?.agency?.optionName ?? ''}
            </Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.boldUnderlineText}>LOCATION OF BODY:</Text>
            <Text style={styles.contentValueLeft}>
              {caseInvest?.bodyLocation?.[0]?.addressLine1 &&
                `${caseInvest?.bodyLocation[0]?.addressLine1}, `}
              {caseInvest?.bodyLocation?.[0]?.city ? `${caseInvest.bodyLocation[0].city} ` : ''}
              {caseInvest?.bodyLocation?.[0]?.stateSeq ? `${bodyLocationState}, ` : ''}
              {caseInvest?.bodyLocation?.[0]?.zip ? `${caseInvest?.bodyLocation?.[0]?.zip}, ` : ''}
              {caseInvest?.bodyLocation?.[0]?.countrySeq ? bodyLocationCountry : ''}
            </Text>
          </View>
        </View>

        <View style={styles.row}>
          <Text style={styles.boldUnderlineText}>INITIAL INFORMATION REPORTED:</Text>
          <Text style={styles.text}>
            {caseInvest?.deathInvestigationAdditionalInformation?.initialInfo ?? ''}
          </Text>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.section}>
          <Text style={styles.boldUnderlineText}>INVESTIGATION:</Text>
          <Text style={styles.text}>
            {caseInvest?.deathInvestigationAdditionalInformation?.investigationInfo ?? ''}
          </Text>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        {/** Next of kin */}
        <View style={styles.section}>
          {next_of_kin?.length === 0 ||
            (next_of_kin == undefined && (
              <View style={{ flexDirection: 'row' }}>
                <View style={styles.keyValuePair}>
                  <Text style={styles.boldUnderlineText}>NEXT OF KIN:</Text>
                  <Text style={styles.contentValueLeft}>Unknown at this time</Text>
                </View>
              </View>
            ))}

          {next_of_kin?.length > 0 && (
            <View>
              <Text style={styles.boldUnderlineText}>NEXT OF KIN:</Text>

              {/* Table Headers */}
              <View style={styles.contentRow}>
                <Text style={styles.boldColumn}>Name</Text>
                <Text style={styles.boldColumn}>Relationship</Text>
                <Text style={styles.boldColumn}>Contact Info</Text>
              </View>

              {/* Table Content */}
              {next_of_kin.map((kin, index) => (
                <View key={index} style={styles.contentRow}>
                  <Text style={styles.content}>{kin.name ?? '-'}</Text>
                  <Text style={styles.content}>{kin.relationship ?? '-'}</Text>
                  <Text style={styles.content}>{kin.phone_number ?? '-'}</Text>
                </View>
              ))}
            </View>
          )}
        </View>

        {/**Identification */}
        <View style={styles.section}>
          {identification?.length === 0 ||
            (identification == undefined && (
              <View style={{ flexDirection: 'row' }}>
                <View style={styles.keyValuePair}>
                  <Text style={styles.boldUnderlineText}>IDENTIFIED BY:</Text>
                  <Text style={styles.contentValueLeft}>Unknown at this time</Text>
                </View>
              </View>
            ))}

          {identification?.length > 0 && (
            <View>
              <Text style={styles.boldUnderlineText}>IDENTIFIED BY:</Text>

              {/* Table Headers */}
              <View style={styles.contentRow}>
                <Text style={styles.boldColumn}>Identified By</Text>
                <Text style={styles.boldColumn}>Modality</Text>
                <Text style={styles.boldColumn}>Relationship</Text>
              </View>

              {identification?.map((id, index) => (
                <View style={styles.contentRow}>
                  <Text style={styles.content}>{id.name ?? '-'}</Text>
                  <Text style={styles.content}>{id.modality ?? '-'}</Text>
                  <Text style={styles.content}>{id.relationship ?? '-'}</Text>
                </View>
              ))}
            </View>
          )}
        </View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.boldUnderlineText}>CONFIRMED ID DIRECTLY FROM SOURCE:</Text>
            <Text style={styles.contentValueLeft}>
              {caseInvest?.deathInvestigationAdditionalInformation?.confirmedIdFromSource
                ? 'Yes'
                : 'No'}
            </Text>
          </View>
        </View>

        <View style={styles.row}>
          <Text style={styles.boldUnderlineText}>WITNESS/FAMILY STATEMENT:</Text>
          <Text style={styles.text}>
            {caseInvest?.deathInvestigationAdditionalInformation?.witnessFamilyStatement ??
              'None At This Time'}
          </Text>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.boldUnderlineText}>PMD:</Text>
            <Text style={styles.contentValueLeft}>
              {formik.values?.caseDemographics?.privatePhysician?.lastName &&
              formik.values?.caseDemographics?.privatePhysician?.firstName
                ? `${formik.values?.caseDemographics?.privatePhysician?.firstName} ${formik.values?.caseDemographics?.privatePhysician?.lastName}`
                : 'Unknown at this time.'}
            </Text>
          </View>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <Text style={styles.boldUnderlineText}>MEDICAL HX:</Text>
          <Text style={styles.text}>{medicalHistory || 'Unknown at this time.'}</Text>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.boldUnderlineText}>MEDICATIONS:</Text>
            <Text style={styles.contentValueLeft}>{medications || 'Unknown at this time.'}</Text>
          </View>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <Text style={styles.boldUnderlineText}>SOCIAL HX:</Text>
          <Text style={styles.text}>
            {caseInvest?.pastSocialHistory ?? 'Unknown at this time.'}
          </Text>
        </View>
        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.section}>
          {/* Check if there are any items in the allItemsList */}
          {Object.entries(allItemsList).some(([_, items]) => items && items.length > 0) ? (
            <View>
              <Text style={styles.boldUnderlineText}>PROPERTY COLLECTED:</Text>

              {Object.entries(allItemsList).map(([category, items], index) => {
                if (items && items.length > 0) {
                  return (
                    <View key={index} style={{ flexDirection: 'row' }}>
                      <Text style={styles.contentKeyLeft}>{category.toUpperCase()}:</Text>
                      <Text style={styles.contentValueLeft}>
                        {items.map(item => item.item).join(', ')}
                      </Text>
                    </View>
                  );
                } else {
                  return null;
                }
              })}
            </View>
          ) : (
            // Render fallback if no items exist
            <View style={{ flexDirection: 'row' }}>
              <View style={styles.keyValuePair}>
                <Text style={styles.boldUnderlineText}>PROPERTY COLLECTED:</Text>
                <Text style={styles.contentValueLeft}>None</Text>
              </View>
            </View>
          )}
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.contentKeyLeft}>LIVERY:</Text>
            <Text style={styles.contentValueLeft}>
              {caseInvest?.liveryUser?.optionName ?? 'Unknown at this time'}
            </Text>
          </View>
        </View>

        <View style={styles.smallSpaceBreak}></View>

        <View style={styles.row}>
          <View style={styles.keyValuePair}>
            <Text style={styles.contentKeyLeft}>INVESTIGATOR:</Text>
            <Text style={styles.contentValueLeft}>
              {caseInvest.assignedInvestigator.optionName ?? 'Unknown at this time'}
            </Text>
          </View>
          <Text style={styles.contentKeyRight}>POC:</Text>
          <Text style={styles.contentValueRight}>
            {formik.values?.caseMortuary?.assignedUser.optionName ?? 'Unknown at this time'}
          </Text>
        </View>

        <View style={styles.smallSpaceBreak}></View>
        <View style={styles.spaceBreak}></View>

        <View style={{ marginTop: 10, borderTopWidth: 1, paddingTop: 2, alignItems: 'center' }}>
          <Text style={{ fontSize: 8, fontWeight: 'bold', color: '#888' }}>
            INFORMATION IS PRELIMINARY AND SUBJECT TO CHANGE
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const GenerateINVDataSheetButton: React.FC<{ label: string }> = ({ label }) => {
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;
  const formik = useFormikContext<any>();
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchStateOptions = async () => {
    fetch(REACT_APP_API_URL + 'getstateoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setStateOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };
  const fetchCountryOptions = async () => {
    fetch(REACT_APP_API_URL + 'getcountryoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setCountryOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  useEffect(() => {
    fetchStateOptions();
    fetchCountryOptions();
  }, [formik.values]);

  const matchSeq = (options, seq) => {
    return options.find(option => option.optionSeq === seq)?.optionName || '';
  };

  const stateseqToFind =
    formik.values?.caseInvestigations?.bodyLocation?.[0]?.stateSeq?.toUpperCase() || '';
  const countrySeqToFind =
    formik.values?.caseInvestigations?.bodyLocation?.[0]?.countrySeq?.toUpperCase() || '';

  const bodyLocationState = matchSeq(stateOptions, stateseqToFind);
  const bodyLocationCountry = matchSeq(countryOptions, countrySeqToFind);

  const handleClick = async () => {
    if (!user || !user.token || !user.userSeq) {
      alert("User information is missing. Please ensure you're logged in.");
      return;
    }

    if (uploadInProgress) {
      alert('please wait while we upload the document');
      return;
    }

    // Generate the PDF blob
    const blob = await pdf(
      <INVDataSheet
        formik={formik}
        bodyLocationState={bodyLocationState}
        bodyLocationCountry={bodyLocationCountry}
      />
    ).toBlob();
    const file_name = formik.values?.caseSummary?.caseNumber
      ? `${formik.values?.caseSummary?.caseNumber}-Investigative Data Sheet.pdf`
      : 'Investigative Data Sheet.pdf';

    const pdfFile = new File([blob], file_name, { type: 'application/octet-stream' });
    const blobUrl = URL.createObjectURL(blob);

    setPdfBlobUrl(blobUrl);
    setOpenModal(true);
  };

  const handleFileUpload = async pdfFile => {
    setUploadInProgress(true);
    setUploadSuccess(false);

    const formDataUploadFiles = new FormData();
    formDataUploadFiles.append(
      'FILEPARAMS[0].CASENO',
      formik.values?.caseSummary?.caseInformation?.caseID
    );
    formDataUploadFiles.append(
      'FILEPARAMS[0].CASESEQ',
      formik.values?.caseSummary?.caseInformation?.caseSeq
    );
    formDataUploadFiles.append(
      'FILEPARAMS[0].FILERELATEDTO',
      'd3bc96a5-79e9-411f-8c3e-0c3c8ad97e53'
    );
    formDataUploadFiles.append('FILEPARAMS[0].FILERELATEDTONAME', 'Data Sheet');
    formDataUploadFiles.append('FILEPARAMS[0].FILE', pdfFile);
    formDataUploadFiles.append('USERSEQ', user?.userSeq);

    try {
      const response = await fetch(REACT_APP_API_URL + 'fileuploads', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        body: formDataUploadFiles,
      });

      const data = await response.json();
      if (response.ok && data.status === 0) {
        setUploadSuccess(true);
      }
    } catch (error) {
      alert('Error during file upload: ' + error.message);
    } finally {
      setUploadInProgress(false);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setPdfBlobUrl(null);
    if (pdfBlobUrl) {
      URL.revokeObjectURL(pdfBlobUrl);
    }
  };

  const handleDownload = file_name => {
    if (pdfBlobUrl) {
      const a = document.createElement('a');
      a.href = pdfBlobUrl;
      a.download = `${file_name}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleSaveToMedexLab = async file_name => {
    if (pdfBlobUrl) {
      const blob = await fetch(pdfBlobUrl).then(r => r.blob());
      const pdfFile = new File([blob], `${file_name}.pdf`, {
        type: 'application/octet-stream',
      });
      await handleFileUpload(pdfFile);
      handleClose();
    }
  };

  const preview_name = formik.values?.caseSummary?.caseNumber
    ? `${formik.values?.caseSummary?.caseNumber}-Investigative Data Sheet`
    : 'Investigative Data Sheet';

  return (
    <div style={{ textAlign: 'center' }}>
      <Button variant='contained' color='primary' onClick={handleClick} disabled={isUploading}>
        {isUploading ? 'File Saved' : label}
      </Button>

      {isUploading && (
        <div style={{ marginTop: '10px' }}>
          <CircularProgress size={24} />
        </div>
      )}

      {uploadSuccess && (
        <div style={{ marginTop: '10px', color: 'green' }}>
          <CheckCircleIcon style={{ verticalAlign: 'middle' }} />
          <span style={{ marginLeft: '5px' }}>File Uploaded Successfully!</span>
        </div>
      )}

      <Dialog open={openModal} onClose={handleClose} maxWidth='md' fullWidth>
        <Box display='flex' alignItems='center' justifyContent='space-between' padding='16px'>
          <DialogTitle style={{ margin: 0, padding: 0 }}>{preview_name} Preview</DialogTitle>
          <Box>
            <Button
              onClick={() => handleDownload(preview_name)}
              color='primary'
              style={{ marginRight: 8 }}
            >
              Download PDF
            </Button>
            <Button
              onClick={() => handleSaveToMedexLab(preview_name)}
              color='secondary'
              style={{ marginRight: 8 }}
            >
              Save to Documents
            </Button>
            <Button onClick={handleClose} color='error'>
              Close
            </Button>
          </Box>
        </Box>
        <DialogContent dividers>
          {pdfBlobUrl && (
            <iframe
              src={`${pdfBlobUrl}#zoom=page-fit&view=FitH&toolbar=0`}
              style={{
                width: isMobile ? '70%' : '100%',
                minHeight: isMobile ? '30vh' : '80vh',
                border: 'none',
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default GenerateINVDataSheetButton;
