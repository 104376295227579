import { Autocomplete, Divider, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Grid from '@mui/material/Unstable_Grid2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ActionList } from '../../CaseView/CaseViewDateTimeField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../utils/auth/AuthService';
import AddressFieldWithoutSearch from '../../../utils/components/AddressFieldWIthoutSearch';
import { renderAutocompleteEditCell } from '../../../utils/components/CrudDataGrid/AutocompleteEditCell';
import CrudDataGrid from '../../../utils/components/CrudDataGrid/CrudDataGrid';
import { dateTimeColumnType } from '../../../utils/components/CrudDataGrid/DateTimeColumn';
import { TimeZone } from '../../../utils/constants/constants.js';

dayjs.extend(utc);
dayjs.extend(timezone);

const { REACT_APP_API_URL } = process.env;

const getLocalTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

function findPropertyName(obj, value) {
  console.log(obj);
  console.log(value);

  for (let prop in obj) {
    console.log(obj[prop]);
    console.log(value);
    console.log(obj[prop] === value);
    if (obj[prop] === value) {
      return prop;
    }
  }
  return null; // If value is not found in any property
}

// Gets the correct values for the caseReporter chosen given the jurisdiction (some agencies are
// only available in certain jurisdictions, others are available in every jurisdiction)
const getCaseReporterOptions = async (caseReporterType, jdxSeq) => {
  if (caseReporterType == null) {
    return [];
  }

  switch (caseReporterType.optionSeq?.toUpperCase()) {
    case '875C36E0-A559-4552-BA14-2C0F8368A632'.toUpperCase():
      var hcfoptions = [];
      // GET HCF OPTIONS
      await fetch(REACT_APP_API_URL + 'gethcfoptions?jdxSeq=' + jdxSeq, {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          hcfoptions = data;
        })
        .catch(e => {
          //alert(e);
          console.error(e);
        });
      return hcfoptions;

    case '85115ED1-039F-4B89-B15C-CFCB4F6046D3'.toUpperCase():
      var leoptions = [];
      // GET LAW ENFORCEMENT OPTIONS
      await fetch(REACT_APP_API_URL + 'getlawenforcementoptions?jdxSeq=' + jdxSeq, {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          leoptions = data;
        })
        .catch(e => {
          console.error(e);
          //alert(e);
        });
      return leoptions;

    case '3B758606-75D6-4068-A66D-21176A9F1C5F'.toUpperCase():
      var nhoptions = [];
      //GET NURSING HOME OPTIONS
      await fetch(REACT_APP_API_URL + 'getnursinghomeoptions?jdxSeq=' + jdxSeq, {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          nhoptions = data;
        })
        .catch(e => {
          //alert(e);
          console.error(e);
        });
      return nhoptions;

    case 'DC11F527-4CB4-4DF8-9B20-22F1F2731C6E'.toUpperCase():
      var fhoptions = [];
      // GET FUNERAL HOME OPTIONS
      await fetch(REACT_APP_API_URL + 'getfuneralhomeoptions?jdxSeq=' + jdxSeq, {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          fhoptions = data;
        })
        .catch(e => {
          //alert(e);
          console.error(e);
        });
      return fhoptions;

    case '8918DC13-59DD-45EF-87B0-B268D97C8156'.toUpperCase():
      var croptions = [];
      // GET CHARGEABLE REQUEST AGENCY OPTIONS
      await fetch(REACT_APP_API_URL + 'getchargeablerequestagencyoptions?jdxSeq=' + jdxSeq, {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          croptions = data;
        })
        .catch(e => {
          //alert(e);
          console.error(e);
        });
      return croptions;

    default:
      return [];
  }
};

// Returns the proper name of the caseReporter type given its sequence (for formik id purposes)
function getCaseReporterId(caseReporterType, currentChargeableRequestIndex) {
  switch (caseReporterType?.optionSeq?.toUpperCase()) {
    case '875C36E0-A559-4552-BA14-2C0F8368A632':
      return `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].hcf`;
    case '85115ED1-039F-4B89-B15C-CFCB4F6046D3':
      return `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].lawEnforcement`;
    case '3B758606-75D6-4068-A66D-21176A9F1C5F':
      return `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].nursingHome`;
    case 'DC11F527-4CB4-4DF8-9B20-22F1F2731C6E':
      return `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].funeralHome`;
    case '8918DC13-59DD-45EF-87B0-B268D97C8156':
      return `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].chargeableRequestAgency`;
    default:
      return null;
  }
}

// Returns the proper values of the caseReporter type given its sequence (for formik value purposes)
function getCaseReporterValue(caseReporterType, values) {
  switch (caseReporterType?.optionSeq?.toUpperCase()) {
    case '875C36E0-A559-4552-BA14-2C0F8368A632':
      return values?.hcf;
    case '85115ED1-039F-4B89-B15C-CFCB4F6046D3':
      return values?.lawEnforcement;
    case '3B758606-75D6-4068-A66D-21176A9F1C5F':
      return values?.nursingHome;
    case 'DC11F527-4CB4-4DF8-9B20-22F1F2731C6E':
      return values?.funeralHome;
    case '8918DC13-59DD-45EF-87B0-B268D97C8156':
      return values?.chargeableRequestAgency;
    default:
      return null;
  }
}

export default function ChargeableRequestsForm({ currentChargeableRequestIndex }) {
  const [caseReporterTypeOptions, setCaseReporterTypeOptions] = useState([]);
  const [caseReporterOptions, setCaseReporterOptions] = useState([]);
  const [contactTypeOptions, setContactTypeOptions] = useState([]);
  const [documentOptions, setDocumentOptions] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [requestMethodOptions, setRequestMethodOptions] = useState([]);
  const { user } = useAuth();
  const formik = useFormikContext();

  useEffect(() => {
    // INITIALIZE CASE REPORTER TYPE OPTIONS
    fetch(REACT_APP_API_URL + 'getcasereportertypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setCaseReporterTypeOptions(
          data.filter(
            cr =>
              cr.optionSeq.toUpperCase() !== '2006ADAE-1F69-4FD6-9CF0-05C8E83B6685' &&
              cr.optionSeq.toUpperCase() !== '5B11112C-42EA-46D3-8A99-02F4BFCCF148' &&
              cr.optionSeq.toUpperCase() !== '9ADE1C87-424B-464F-8843-2E175139E592' &&
              cr.optionSeq.toUpperCase() !== 'A44FBBA9-C726-4ED8-A325-1CCD17B9578B'
          )
        );
      })
      .catch(e => {
        //alert(e);
      });

    // INITIALIZE CONTACT TYPE OPTIONS
    fetch(REACT_APP_API_URL + 'getcontactitemtypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setContactTypeOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    // INITIALIZE DOCUMENT OPTIONS
    fetch(REACT_APP_API_URL + 'getrequesteddocumentoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setDocumentOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    // INITIALIZE PAYMENT OPTIONS
    fetch(REACT_APP_API_URL + 'getpaymentmodeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setPaymentOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    // INITIALIZE REQUEST METHOD OPTIONS
    fetch(REACT_APP_API_URL + 'getrequestmethodoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setRequestMethodOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  }, []);

  const updateClearingHoldStatus = async currentIndex => {
    const updatedStatus = {
      ...formik.status,
      clearingHoldDateTimeUpdated: formik.status?.clearingHoldDateTimeUpdated?.map((value, index) =>
        index === currentIndex ? true : value
      ),
    };

    formik.setStatus(await updatedStatus);
  };

  useEffect(() => {
    if (
      currentChargeableRequestIndex !== -1 &&
      !formik.status?.clearingHoldDateTimeUpdated?.[currentChargeableRequestIndex] &&
      formik.values?.caseRecords?.chargeableRequests?.length > 0
    ) {
      const localTimezone = getLocalTimezone();
      const parsedDateTime = dayjs(
        formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
          ?.clearingHoldDateTime
      ).tz(localTimezone);
      const timezoneOffset = parsedDateTime.utcOffset() / 60;
      const updatedDateTime = parsedDateTime.add(timezoneOffset, 'hour');

      formik.setFieldValue(
        `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].clearingHoldDateTime`,
        updatedDateTime
      );
      updateClearingHoldStatus(currentChargeableRequestIndex);
    }
  }, [currentChargeableRequestIndex]);

  const updateCaseReporterOptions = async () => {
    setCaseReporterOptions(
      await getCaseReporterOptions(
        formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
          ?.caseReporterType,
        formik.values?.caseSummary?.jdxSeq
      )
    );
  };

  useEffect(() => {
    if (caseReporterOptions.length === 0) {
      updateCaseReporterOptions();
    }
  }, [
    formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
      ?.caseReporterType,
  ]);

  const handleCaseReporterTypeChange = async (event, data) => {
    // Reset all the caseReporter values since the type is changing
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].caseReporterType`,
      data
    );
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].hcf`,
      null
    );
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].lawEnforcement`,
      null
    );
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].nursingHome`,
      null
    );
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].funeralHome`,
      null
    );
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].otherCaseReporter`,
      null
    );

    // Get the correct caseReporter options for the type chosen in this jurisdiction
    setCaseReporterOptions(await getCaseReporterOptions(data, formik.values?.caseSummary?.jdxSeq));
  };

  const handleRequestMethodChange = event => {
    const selectedOption = requestMethodOptions.find(
      option => option.optionSeq.toUpperCase() === event.target.value
    );
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requestMethod`,
      selectedOption
    );
  };

  const handleIsFamilyRequestChange = event => {
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].isFamilyRequest`,
      event.target.value === 'true' ? true : false
    );
  };

  const handleIsLegalReviewRequiredChange = event => {
    formik.setFieldValue(
      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].isLegalReviewRequired`,
      event.target.value === 'true' ? true : false
    );
  };

  const createRequestedDocument = newRow => {
    let formData = new FormData();
    formData.append('newRow', JSON.stringify(newRow));
    formData.append(
      'chargeableRequestSeq',
      formik.values?.caseRecords.chargeableRequests?.[currentChargeableRequestIndex]
        ?.chargeableRequestSeq
    );

    fetch(REACT_APP_API_URL + 'createrequesteddocuments', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        console.log('successfully created entry');
      })
      .catch(e => {
        console.log(e);
      });
  };

  const updateRequestedDocument = updatedRow => {
    console.log(updatedRow);

    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));
    formData.append('userSeq', user.userSeq);

    fetch(REACT_APP_API_URL + 'updaterequesteddocuments', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        console.log('successfully updated entry');
      })
      .catch(e => {
        console.log(e);
      });
  };

  const deleteRequestedDocument = id => {
    fetch(REACT_APP_API_URL + `deleterequesteddocuments/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .then(data => {
        console.log('successfully deleted entry');
      })
      .catch(e => {
        console.log(e);
      });
  };

  const requestedDocumentsColumns = [
    {
      flex: 1,
      minWidth: 250,
      field: 'dateRequested',
      headerName: 'Date Requested',
      headerAlign: 'left',
      align: 'left',
      // ...dateTimeColumnType('dateRequested'),
      ...dateTimeColumnType,
      valueSetter: params => {
        if (params?.value == null || params?.value == 'Invalid Date') {
          return { ...params.row, dateRequested: null };
        } else {
          const localDate = params?.value
            ? dayjs(params?.value).format('YYYY-MM-DDTHH:mm:ss')
            : null;

          return { ...params.row, dateRequested: localDate };
        }
      },
      editable: true,
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'itemRequested',
      headerName: 'Item Requested',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => {
        if (!value) return value;
        return value.optionName;
      },
      type: 'singleSelect',
      editable: true,
      valueOptions: documentOptions,
      renderEditCell: params => renderAutocompleteEditCell(params),
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'numberOfItemsRequested',
      headerName: '# of Items',
      type: 'number',
      editable: true,
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'costPerItem',
      headerName: 'Cost Per Item',
      type: 'number',
      editable: true,
      // add render cell here
    },
    {
      flex: 1,
      minWidth: 250,
      field: 'dateInvoiced',
      headerName: 'Date Invoiced',
      headerAlign: 'left',
      align: 'left',
      // ...dateTimeColumnType('dateInvoiced'),
      ...dateTimeColumnType,
      valueSetter: params => {
        if (params?.value == null || params?.value == 'Invalid Date') {
          return { ...params.row, dateInvoiced: null };
        } else {
          const localDate = params?.value
            ? dayjs(params?.value).format('YYYY-MM-DDTHH:mm:ss')
            : null;

          return { ...params.row, dateInvoiced: localDate };
        }
      },
      editable: true,
    },
    {
      flex: 1,
      minWidth: 250,
      field: 'dateReceived',
      headerName: 'Date Received',
      headerAlign: 'left',
      align: 'left',
      // ...dateTimeColumnType('dateReceived'),
      ...dateTimeColumnType,
      valueSetter: params => {
        if (params?.value == null || params?.value == 'Invalid Date') {
          return { ...params.row, dateReceived: null };
        } else {
          const localDate = params?.value
            ? dayjs(params?.value).format('YYYY-MM-DDTHH:mm:ss')
            : null;

          return { ...params.row, dateReceived: localDate };
        }
      },
      editable: true,
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'paymentMethod',
      headerName: 'Payment Method',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => {
        if (!value) return value;
        return value.optionName;
      },
      type: 'singleSelect',
      editable: true,
      valueOptions: paymentOptions,
      renderEditCell: params => renderAutocompleteEditCell(params),
    },
    {
      flex: 1,
      minWidth: 250,
      field: 'dateSent',
      headerName: 'Date Sent',
      headerAlign: 'left',
      align: 'left',
      // ...dateTimeColumnType('dateSent'),
      ...dateTimeColumnType,
      valueSetter: params => {
        if (params?.value == null || params?.value == 'Invalid Date') {
          return { ...params.row, dateSent: null };
        } else {
          const localDate = params?.value
            ? dayjs(params?.value).format('YYYY-MM-DDTHH:mm:ss')
            : null;

          return { ...params.row, dateSent: localDate };
        }
      },
      editable: true,
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'notes',
      headerName: 'Notes',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      editable: true,
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel>Request Made By:</FormLabel>
            <RadioGroup
              row
              id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].isFamilyRequest`}
              name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].isFamilyRequest`}
              value={
                formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                  ?.isFamilyRequest ?? false
              }
              onChange={handleIsFamilyRequestChange}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label='Individual'
                disabled={!formik.status.editing}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label='Facility/Agency/Institution'
                disabled={!formik.status.editing}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {!formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
          ?.isFamilyRequest && (
          <Grid item xs={12} md={6}>
            <Autocomplete
              {...formik.getFieldProps('caseReporterType')}
              id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].caseReporterType`}
              name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].caseReporterType`}
              options={caseReporterTypeOptions}
              value={
                formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                  ?.caseReporterType ?? null
              }
              isOptionEqualToValue={(option, value) => {
                return option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase();
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.caseReporterType && Boolean(formik.errors.caseReporterType)}
              helperText={formik.touched.caseReporterType && formik.errors.caseReporterType}
              size='small'
              required
              disabled={!formik.status.editing}
              getOptionLabel={option => option.optionName}
              onChange={handleCaseReporterTypeChange}
              fullWidth
              renderInput={params => (
                <TextField {...params} label='Agency Type' placeholder='Agency Type' required />
              )}
            />
          </Grid>
        )}

        {!formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
          ?.isFamilyRequest && (
          <Grid item xs={12} md={6}>
            {formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
              ?.caseReporterType != null &&
            formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
              ?.caseReporterType.optionSeq?.toUpperCase() ==
              '25439ADE-F997-451A-9122-2C904DAD52D9' ? (
              <TextField
                id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].otherCaseReporter`}
                name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].otherCaseReporter`}
                value={
                  formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                    ?.otherCaseReporter ?? ''
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.otherCaseReporter && Boolean(formik.errors.otherCaseReporter)}
                helperText={formik.touched.otherCaseReporter && formik.errors.otherCaseReporter}
                size='small'
                variant='outlined'
                label='Other CaseReporter'
                disabled={!formik.status.editing}
                fullWidth
                required
              />
            ) : (
              <Autocomplete
                id={() =>
                  getCaseReporterId(
                    formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                      ?.caseReporterType,
                    currentChargeableRequestIndex
                  )
                }
                name={() =>
                  getCaseReporterId(
                    formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                      ?.caseReporterType,
                    currentChargeableRequestIndex
                  )
                }
                options={caseReporterOptions}
                value={getCaseReporterValue(
                  formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                    ?.caseReporterType,
                  formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                )}
                isOptionEqualToValue={(option, value) =>
                  option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
                }
                size='small'
                getOptionLabel={option => option.optionName}
                onChange={(e, value) =>
                  formik.setFieldValue(
                    getCaseReporterId(
                      formik.values?.caseRecords?.chargeableRequests?.[
                        currentChargeableRequestIndex
                      ]?.caseReporterType,
                      currentChargeableRequestIndex
                    ),
                    value
                  )
                }
                fullWidth
                disabled={
                  formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                    ?.caseReporterType === null || !formik.status.editing
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Agency'
                    placeholder={
                      formik.values?.caseRecords?.chargeableRequests?.[
                        currentChargeableRequestIndex
                      ]?.caseReporterType?.optionName
                    }
                    onBlur={formik.handleBlur}
                    error={formik.touched.caseReporter && Boolean(formik.errors.caseReporter)}
                    helperText={formik.touched.caseReporter && formik.errors.caseReporter}
                    required
                  />
                )}
              />
            )}
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <TextField
            id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requester.[0].personFirstName`}
            name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requester.[0].personFirstName`}
            value={
              formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                ?.requester?.[0]?.personFirstName ?? ''
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.requester?.[0]?.personFirstName &&
              Boolean(formik.errors.requester?.[0]?.personFirstName)
            }
            helperText={
              formik.touched.requester?.[0]?.personFirstName &&
              formik.errors.requester?.[0]?.personFirstName
            }
            size='small'
            disabled={!formik.status.editing}
            variant='outlined'
            label='Requester First Name'
            fullWidth
            // required
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requester.[0].personMiddleName`}
            name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requester.[0].personMiddleName`}
            value={
              formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                ?.requester?.[0]?.personMiddleName ?? ''
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.requester?.[0]?.personMiddleName &&
              Boolean(formik.errors.requester?.[0]?.personMiddleName)
            }
            helperText={
              formik.touched.requester?.[0]?.personMiddleName &&
              formik.errors.requester?.[0]?.personMiddleName
            }
            size='small'
            variant='outlined'
            disabled={!formik.status.editing}
            label='Requester Middle Name'
            fullWidth
            // required
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requester.[0].personLastName`}
            name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requester.[0].personLastName`}
            value={
              formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                ?.requester?.[0]?.personLastName ?? ''
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.requester?.[0]?.personLastName &&
              Boolean(formik.errors.requester?.[0]?.personLastName)
            }
            helperText={
              formik.touched.requester?.[0]?.personLastName &&
              formik.errors.requester?.[0]?.personLastName
            }
            size='small'
            variant='outlined'
            disabled={!formik.status.editing}
            label='Requester Last Name'
            fullWidth
            // required
          />
        </Grid>

        <Grid item xs={12}>
          <Divider variant='middle'>Requester Address</Divider>
        </Grid>

        {formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
          ?.requesterAddress != null ? (
          formik.values?.caseRecords?.chargeableRequests?.[
            currentChargeableRequestIndex
          ]?.requesterAddress?.map((address, index) => (
            <Grid item xs={12} key={index}>
              <AddressFieldWithoutSearch
                placeholder='Requester Address'
                addressState={address}
                small
                setAddressState={data =>
                  formik.setFieldValue(
                    `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterAddress[${index}]`,
                    data
                  )
                }
                editing={formik.status.editing}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <AddressFieldWithoutSearch
              placeholder='Requester Address'
              addressState={
                formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                  ?.requesterAddress?.[0]
              }
              small
              setAddressState={data =>
                formik.setFieldValue(
                  `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterAddress.[0]`,
                  data
                )
              }
              editing={formik.status.editing}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Divider variant='middle'>Requester Contact Info</Divider>
        </Grid>

        {formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
          ?.requesterContactInfo != null ? (
          formik.values?.caseRecords?.chargeableRequests?.[
            currentChargeableRequestIndex
          ]?.requesterContactInfo?.map((contactInfo, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={contactTypeOptions}
                  value={contactInfo.contactItemType ?? null}
                  onChange={(e, data) =>
                    formik.setFieldValue(
                      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterContactInfo.[${index}].contactItemType`,
                      data
                    )
                  }
                  size='small'
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.optionSeq.toUpperCase() === value.optionSeq.toUpperCase()
                  }
                  disabled={!formik.status.editing}
                  getOptionLabel={option => option.optionName}
                  renderInput={params => <TextField {...params} label='Contact Type' />}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  value={contactInfo.contactItemDetails ?? ''}
                  onChange={e =>
                    formik.setFieldValue(
                      `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterContactInfo.[${index}].contactItemDetails`,
                      e.target.value
                    )
                  }
                  size='small'
                  fullWidth
                  disabled={!formik.status.editing}
                  variant='outlined'
                  id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterContactInfo.[${index}].contactItemDetails`}
                  label='Contact Info'
                  type='text'
                />
              </Grid>
            </React.Fragment>
          ))
        ) : (
          <React.Fragment>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={contactTypeOptions}
                value={
                  formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                    ?.requesterContactInfo?.[0].contactItemType ?? null
                }
                onChange={(e, data) =>
                  formik.setFieldValue(
                    `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterContactInfo.[0].contactItemType`,
                    data
                  )
                }
                isOptionEqualToValue={(option, value) =>
                  option.optionSeq.toUpperCase() === value.optionSeq.toUpperCase()
                }
                size='small'
                fullWidth
                disabled={!formik.status.editing}
                getOptionLabel={option => option.optionName}
                renderInput={params => <TextField {...params} label='Contact Type' />}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={
                  formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                    ?.requesterContactInfo?.[0].contactItemDetails ?? ''
                }
                onChange={e =>
                  formik.setFieldValue(
                    `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterContactInfo.[0].contactItemDetails`,
                    e.target.value
                  )
                }
                size='small'
                fullWidth
                variant='outlined'
                disabled={!formik.status.editing}
                id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requesterContactInfo.[0].contactItemDetails`}
                label='Contact Info'
                type='text'
              />
            </Grid>
          </React.Fragment>
        )}

        <Grid item xs={12}>
          {formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
            ?.isNew && (
            <Typography sx={{ pb: 1 }}>
              <i>
                Please save the case with the new chargeable request entry to add a requested
                document.
              </i>
            </Typography>
          )}
          <CrudDataGrid
            rows={
              formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                ?.requestedDocuments ?? []
            }
            setRows={data => {
              formik.setFieldValue(
                `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requestedDocuments`,
                data
              );
            }}
            initialColumns={requestedDocumentsColumns}
            createEntry={createRequestedDocument}
            updateEntry={updateRequestedDocument}
            deleteEntry={deleteRequestedDocument}
            title='Requested Document'
            disabled={
              formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]?.isNew
            }
            idcolumn='requestedDocumentListSeq'
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel>How Request Was Submitted:</FormLabel>
            <RadioGroup
              row
              id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requestMethod`}
              name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].requestMethod`}
              // Using string interpolation here because of odd timing error.  I'm assuming that the
              // code maps the Radio button values before formik.values is updated (so it's still
              // just an empty array) and it won't rerender when formik.values updates like other
              // components will.  Using string interpolation appears to force a rerender, and then
              // the correct value is displayed.  There might be a more elegant solution for this,
              // but I wasn't able to figure it out
              value={`${formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]?.requestMethod?.optionSeq}`}
              onChange={handleRequestMethodChange}
            >
              {requestMethodOptions.map(method => (
                <FormControlLabel
                  key={method.optionSeq.toUpperCase()}
                  value={method.optionSeq.toUpperCase()}
                  control={<Radio />}
                  disabled={!formik.status.editing}
                  label={method.optionName}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel>Is Legal Review Required?:</FormLabel>
            <RadioGroup
              row
              id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].isLegalReviewRequired`}
              name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].isLegalReviewRequired`}
              value={
                formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                  ?.isLegalReviewRequired ?? false
              }
              onChange={handleIsLegalReviewRequiredChange}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label='Yes'
                disabled={!formik.status.editing}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label='No'
                disabled={!formik.status.editing}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
          ?.isLegalReviewRequired && (
          <Grid item xs='auto'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].clearingHoldDateTime`}
                name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].clearingHoldDateTime`}
                value={dayjs(
                  formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                    ?.clearingHoldDateTime
                )}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                slots={{
                  actionBar: ActionList,
                }}
                onChange={updatedValue => {
                  formik.setFieldValue(
                    `caseRecords.chargeableRequests[${currentChargeableRequestIndex}].clearingHoldDateTime`,
                    updatedValue
                  );
                  // setClearingHoldDateTime(updatedValue);
                  // formik.setStatus(previousStatus => ({
                  //   ...previousStatus,
                  //   clearingHoldDateTimeUpdated: previousStatus.clearingHoldDateTimeUpdated.map(
                  //     (value, index) =>
                  //       index ===
                  //       previousStatus.clearingHoldDateTimeUpdated.indexOf(
                  //         currentChargeableRequestIndex
                  //       )
                  //         ? true
                  //         : value
                  //   ),
                  // }));
                  updateClearingHoldStatus(currentChargeableRequestIndex);
                }}
                timezone='UTC'
                // disableFuture
                label='Hold Cleared On'
                disabled={!formik.status.editing}
                size='small'
                format='L HH:mm'
              />
            </LocalizationProvider>
          </Grid>
        )}

        {formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
          ?.isLegalReviewRequired && (
          <Grid item xs={12} sm>
            <TextField
              id={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].holdClearedBy`}
              name={`caseRecords.chargeableRequests[${currentChargeableRequestIndex}].holdClearedBy`}
              value={
                formik.values?.caseRecords?.chargeableRequests?.[currentChargeableRequestIndex]
                  ?.holdClearedBy ?? ''
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.holdClearedBy && Boolean(formik.errors.holdClearedBy)}
              helperText={formik.touched.holdClearedBy && formik.errors.holdClearedBy}
              variant='outlined'
              label='Hold Cleared By'
              disabled={!formik.status.editing}
              size='small'
              // required
            />
          </Grid>
        )}
      </Grid>
    </form>
  );
}
