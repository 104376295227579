import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Tab,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Container } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from 'react';
import { CardHeader } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import { useAuth } from '../../../../utils/auth/AuthService';
import {
  NoticeMethod_Telephone,
  NoticeMethod_Other,
  ObjectionReason_Other,
} from '../../../../utils/constants/constants';
import { useFormikContext } from 'formik';
import CaseViewTextField from '../../../../components/CaseView/CaseViewTextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { ActionList } from '../../../../components/CaseView/CaseViewDateTimeField.jsx';

export default function ExamObjectionsView({
  autopsyObjection,
  informantList,
  handleObjectionChange,
  handleMEDetailsChange,
  editing,
}) {
  const [investigatorAndMEOptions, setInvestigatorAndMEOptions] = useState([]);
  const [objectionResolutionOptions, setObjectionResolutionOptions] = useState([]);
  const [objectionReasonOptions, setObjectionReasonOptions] = useState([]);
  const { REACT_APP_API_URL } = process.env;
  const { user } = useAuth();
  const authorizedToEdit = user.roleCheck([
    'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
    'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
    'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
    '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
    '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
  ]);
  const [selectedTab, setSelectedTab] = React.useState(1);
  const formik = useFormikContext();

  const objectors = autopsyObjection?.objector?.filter(objector => objector.isActive) || [];

  const informantOptions =
    informantList
      ?.filter(informant => informant.nextOfKin === 1)
      ?.map(option => ({
        informantSeq: option.informantSeq,
        personSeq: option.personSeq,
        personLastName: option.personLastName,
        personFirstName: option.personFirstName,
        relship: option.relship?.optionName,
        PersonContactInfoSeq: option.personContactInfoSeq,
      })) || [];

  const handleObjectonOnChange = (field, value, id) => {
    const tempPanels = objectors?.find(index => index.id === id);
    const updatedPanel = { ...tempPanels, [field]: value };
    if (field === 'noticeMethodSeq' && value !== NoticeMethod_Other) {
      updatedPanel['otherNoticeMethod'] = '';
    }
    if (field === 'autopsyObjectionReasonSeq' && value !== ObjectionReason_Other) {
      updatedPanel['otherAutopsyObjectionReason'] = '';
    }
    handleObjectionChange(updatedPanel);
  };

  const handleMEDetailsOnChange = (fieldKey, value) => {
    handleMEDetailsChange({
      [fieldKey]: value ? value : null,
    });
  };

  const removeObjectonTab = id => {
    const tempPanels = objectors?.find(index => index.id === id);
    const updatedPanel = { ...tempPanels, isActive: false };
    handleObjectionChange(updatedPanel);
    setSelectedTab(1);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const createNewTab = () => {
    let makeTab = true;
    objectors?.forEach(panel => {
      if (!panel.personSeq) {
        makeTab = false;
      }
    });
    if (!makeTab) {
      return;
    }
    handleObjectionChange({
      id: uuidv4(),
      personSeq: null,
      noticeMethodSeq: null,
      otherNoticeMethod: '',
      receivedDateTime: '',
      noticeReceiverSeq: null,
      autopsyObjectionReasonSeq: null,
      otherAutopsyObjectionReason: '',
      isActive: true,
    });
    setSelectedTab(objectors?.length + 1);
  };

  const fetchContactItem = async (field, ContactInfoSeq) => {
    fetch(REACT_APP_API_URL + 'getcontactitembycontactseq?ContactInfoSeq=' + ContactInfoSeq, {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setLastKnownToBeAliveContact(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchObjectionReasonOptions = async () => {
    fetch(REACT_APP_API_URL + 'getautopsyobjectionreasonoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setObjectionReasonOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchObjectionResolutionOptions = async () => {
    fetch(REACT_APP_API_URL + 'getautopsyobjectionresolutionoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setObjectionResolutionOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchInvestigatorAndMEOptions = () => {
    fetch(REACT_APP_API_URL + 'getinvestigatorandmeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setInvestigatorAndMEOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  useEffect(() => {
    fetchObjectionReasonOptions();
    fetchObjectionResolutionOptions();
    fetchInvestigatorAndMEOptions();
  }, []);

  return (
    <form>
      <Container maxWidth={false} style={{ padding: 0 }}>
        <Button
          disabled={!authorizedToEdit}
          onClick={createNewTab}
          startIcon={<AddCircleIcon />}
          variant='contained'
          sx={{ my: 0 }}
        >
          Objector
        </Button>
        <TabContext value={selectedTab}>
          <TabList onChange={handleTabChange} sx={{ minWidth: '30px' }}>
            {objectors?.map((tab, index) => (
              <Tab label={index + 1} value={index + 1} key={index + 1} sx={{ minWidth: '30px' }} />
            ))}
          </TabList>

          {
            // Object.keys(informantOptions)?.length ?  instead of not showing tab, just display even if no informat.
            objectors?.map((panel, index) => (
              <TabPanel label={index + 1} value={index + 1} key={panel?.value} sx={{ p: 0 }}>
                <fieldset style={{ marginBottom: '0px' }}>
                  <legend>
                    <Typography
                      variant='subtitle1'
                      color='gray'
                      gutterBottom
                      sx={{
                        mt: 0,
                        mb: 0,
                        py: 0,
                        fontWeight: 'bold',
                      }}
                    >
                      PERSON REQUESTING AUTOPSY OBJECTION: {panel?.index}
                    </Typography>
                  </legend>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid Container spacing={2}>
                      <Grid
                        item
                        lg={12}
                        xs={12}
                        md={12}
                        sm={12}
                        display='flex'
                        justifyContent='flex-end'
                      >
                        {objectors?.length > 0 && (
                          <IconButton
                            disabled={!editing || !authorizedToEdit}
                            onClick={() => removeObjectonTab(panel?.id)}
                          >
                            <DeleteIcon sx={{ color: '#4F51FF' }} />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <Autocomplete
                          disabled={!editing || !authorizedToEdit}
                          id='personSeq'
                          name='personSeq'
                          options={informantOptions}
                          value={
                            panel?.personSeq != null
                              ? informantOptions?.find(
                                  option =>
                                    option.personSeq?.toLowerCase() ===
                                    panel?.personSeq?.toLowerCase()
                                ) || null
                              : null
                          }
                          onChange={(event, value) => {
                            handleObjectonOnChange('personSeq', value?.personSeq, panel?.id);
                          }}
                          getOptionLabel={option =>
                            `${option.personLastName || ''}${
                              option.personLastName && option.personFirstName ? ', ' : ''
                            }${option.personFirstName || ''}`
                          }
                          renderInput={params => <TextField {...params} label='Objector' />}
                        />
                        <Typography variant='body1'>
                          <div>
                            {panel?.personSeq != null &&
                              informantOptions &&
                              `Relationship to Decedent: ${
                                informantOptions?.find(
                                  option =>
                                    option.personSeq?.toLowerCase() ===
                                    panel?.personSeq?.toLowerCase()
                                )?.relship
                              }`}
                          </div>
                        </Typography>
                      </Grid>
                    </Grid>
                    <br />
                    <Grid
                      container
                      spacing={2}
                      sx={{ display: panel?.personSeq != null ? 'flex' : 'none' }}
                    >
                      <Grid item xs={12}>
                        <Typography variant='subtitle1' color='gray'>
                          <strong>NOTICE</strong>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <FormControl component='fieldset'>
                          <FormLabel component='legend'>Method Of Notice</FormLabel>
                          <RadioGroup
                            name='noticeMethodSeq'
                            value={panel?.noticeMethodSeq || null}
                            onChange={e => {
                              handleObjectonOnChange('noticeMethodSeq', e.target.value, panel?.id);
                            }}
                            row
                          >
                            <FormControlLabel
                              disabled={!editing || !authorizedToEdit}
                              value={NoticeMethod_Telephone}
                              control={<Radio />}
                              label='Telephone'
                            />
                            <FormControlLabel
                              disabled={!editing || !authorizedToEdit}
                              value={NoticeMethod_Other}
                              control={<Radio />}
                              label='Other'
                            />
                          </RadioGroup>
                        </FormControl>
                        {panel?.noticeMethodSeq === NoticeMethod_Other && (
                          <TextField
                            fullWidth
                            id='otherNoticeMethod'
                            name='otherNoticeMethod'
                            label='Other Notice Method'
                            variant='outlined'
                            value={panel?.otherNoticeMethod || ''}
                            onChange={e =>
                              handleObjectonOnChange('otherNoticeMethod', e.target.value, panel?.id)
                            }
                            disabled={!editing || !authorizedToEdit}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            disabled={!editing || !authorizedToEdit}
                            name='receivedDateTime'
                            label='Notice Received DateTime'
                            value={
                              panel?.receivedDateTime
                                ? dayjs(panel?.receivedDateTime) || null
                                : null
                            }
                            onChange={date => {
                              const localDate = date
                                ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss')
                                : null;
                              handleObjectonOnChange('receivedDateTime', localDate, panel?.id);
                            }}
                            slotProps={{
                              textField: {
                                id: 'receivedDateTime',
                              },
                              field: { clearable: true },
                            }}
                            timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                            slots={{
                              actionBar: ActionList,
                            }}
                            // disableFuture={true}
                            ampm={false}
                            sx={{
                              minWidth: '100%',
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Autocomplete
                          disabled={!editing || !authorizedToEdit}
                          id='noticeReceiverSeq'
                          name='noticeReceiverSeq'
                          options={investigatorAndMEOptions}
                          value={
                            panel?.noticeReceiverSeq != null
                              ? investigatorAndMEOptions?.find(
                                  option => option.optionSeq === panel?.noticeReceiverSeq
                                ) || null
                              : null
                          }
                          onChange={(event, value) => {
                            handleObjectonOnChange(
                              'noticeReceiverSeq',
                              value?.optionSeq,
                              panel?.id
                            );
                          }}
                          getOptionLabel={option => option.optionName}
                          renderInput={params => (
                            <TextField {...params} label='Receiver Of Notice' />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Autocomplete
                          disabled={!editing || !authorizedToEdit}
                          id='autopsyObjectionReasonSeq'
                          name='autopsyObjectionReasonSeq'
                          options={objectionReasonOptions}
                          value={
                            panel?.autopsyObjectionReasonSeq != null
                              ? objectionReasonOptions?.find(
                                  option => option.optionSeq === panel?.autopsyObjectionReasonSeq
                                ) || null
                              : null
                          }
                          onChange={(event, value) => {
                            handleObjectonOnChange(
                              'autopsyObjectionReasonSeq',
                              value?.optionSeq,
                              panel?.id
                            );
                          }}
                          getOptionLabel={option => option.optionName}
                          renderInput={params => (
                            <TextField {...params} label='Reason Of Objection' />
                          )}
                        />
                      </Grid>
                      {panel?.autopsyObjectionReasonSeq === ObjectionReason_Other && (
                        <Grid item xs={12} md={8}>
                          <TextField
                            disabled={!editing || !authorizedToEdit}
                            id='otherAutopsyObjectionReason'
                            name='otherAutopsyObjectionReason'
                            label='Other Reason Of Objection'
                            type='text'
                            value={panel?.otherAutopsyObjectionReason || ''}
                            onChange={e =>
                              handleObjectonOnChange(
                                'otherAutopsyObjectionReason',
                                e.target.value,
                                panel?.id
                              )
                            }
                            fullWidth
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </fieldset>
              </TabPanel>
            ))
            // : null
          }
        </TabContext>
      </Container>

      <br />
      <Grid
        container
        spacing={2}
        sx={{
          display:
            autopsyObjection?.objector?.length > 0 &&
            autopsyObjection.objector.some(item => item.personSeq != null)
              ? 'flex'
              : 'none',
        }}
      >
        <Grid item xs={12}>
          <Typography variant='subtitle1' color='gray'>
            <strong>ME DETAILS</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            disabled={!editing || !authorizedToEdit}
            id='autopsyObjectionResolutionSeq'
            name='autopsyObjectionResolutionSeq'
            options={objectionResolutionOptions}
            value={
              autopsyObjection != null
                ? objectionResolutionOptions?.find(
                    option =>
                      option.optionSeq ===
                      autopsyObjection?.meDetails?.autopsyObjectionResolutionSeq
                  ) || null
                : null
            }
            onChange={(event, value) => {
              handleMEDetailsOnChange('autopsyObjectionResolutionSeq', value?.optionSeq);
            }}
            getOptionLabel={option => option.optionName}
            renderInput={params => <TextField {...params} label='Resolution' />}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              disabled={!editing || !authorizedToEdit}
              name='meContactedDateTime'
              label='DateTime ME Contacted The Family'
              value={
                autopsyObjection?.meDetails?.meContactedDateTime
                  ? dayjs(autopsyObjection?.meDetails?.meContactedDateTime) || null
                  : null
              }
              onChange={date => {
                const localDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
                handleMEDetailsOnChange('meContactedDateTime', localDate);
              }}
              slotProps={{
                textField: {
                  id: 'meContactedDateTime',
                },
                field: { clearable: true },
              }}
              timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
              slots={{
                actionBar: ActionList,
              }}
              // disableFuture={true}
              ampm={false}
              sx={{
                minWidth: '100%',
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              disabled={!editing || !authorizedToEdit}
              name='affidavitDateTime'
              label='DateTime Of Affidavit'
              value={
                autopsyObjection?.meDetails?.affidavitDateTime
                  ? dayjs(autopsyObjection?.meDetails?.affidavitDateTime) || null
                  : null
              }
              onChange={date => {
                const localDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
                handleMEDetailsOnChange('affidavitDateTime', localDate);
              }}
              slotProps={{
                textField: {
                  id: 'affidavitDateTime',
                },
                field: { clearable: true },
              }}
              timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
              slots={{
                actionBar: ActionList,
              }}
              // disableFuture={true}
              ampm={false}
              sx={{
                minWidth: '100%',
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              disabled={!editing || !authorizedToEdit}
              name='autopsyDateTime'
              label='Earliest DateTime Of Autospy If Needed'
              value={
                autopsyObjection?.meDetails?.autopsyDateTime
                  ? dayjs(autopsyObjection?.meDetails?.autopsyDateTime) || null
                  : null
              }
              onChange={date => {
                const localDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
                handleMEDetailsOnChange('autopsyDateTime', localDate);
              }}
              slotProps={{
                textField: {
                  id: 'autopsyDateTime',
                },
                field: { clearable: true },
              }}
              timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
              slots={{
                actionBar: ActionList,
              }}
              // disableFuture={true}
              ampm={false}
              sx={{
                minWidth: '100%',
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              disabled={!editing || !authorizedToEdit}
              name='exParteMotionDateTime'
              label='DateTime Of Ex Parte Motion'
              value={
                autopsyObjection?.meDetails?.exParteMotionDateTime
                  ? dayjs(autopsyObjection?.meDetails?.exParteMotionDateTime) || null
                  : null
              }
              onChange={date => {
                const localDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
                handleMEDetailsOnChange('exParteMotionDateTime', localDate);
              }}
              slotProps={{
                textField: {
                  id: 'exParteMotionDateTime',
                },
                field: { clearable: true },
              }}
              timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
              slots={{
                actionBar: ActionList,
              }}
              // disableFuture={true}
              ampm={false}
              sx={{
                minWidth: '100%',
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </form>
  );
}
