import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import '../../App.css';
import CustomDrawer from '../../utils/components/CustomDrawer';
import SettingsIcon from '@mui/icons-material/Settings';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import PropTypes from 'prop-types';
import MessageIcon from '@mui/icons-material/Message';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LockResetIcon from '@mui/icons-material/LockReset';
import {
  Box,
  Hidden,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { getDynamicMenu } from '../../services/utility-api';
import { useAuth } from '../../utils/auth/AuthService';
import SubmenuView from '../CMSAdmin/Menu/Submenu/SubmenuView';
import { ADMIN_MENU_ICON, SYSTEMS_ADMIN } from '../CMSAdmin/Menu/constants';
import CustomTabPanel from '../../utils/components/CustomTabPanel';
import MenuIcon from '@mui/icons-material/Menu';
import { Dashboard } from '@mui/icons-material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function SysAdminHome() {
  const [redirect, setredirect] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menu2Open, setMenu2Open] = useState(false);
  const [menu, setMenu] = useState([]);
  const [allMenu, setAllMenu] = useState([]);
  const [tabContent, setTabContent] = useState('Users');
  const { user } = useAuth();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenu2Click = () => {
    setMenu2Open(!menu2Open);
  };

  const handleTabChange = (event, newTab) => {
    if (event.target.textContent === 'User Admin') {
      setTabContent('Users');
    } else if (event.target.textContent === 'Roles Admin') {
      setTabContent('Roles');
    } else {
      setTabContent(event.target.textContent);
    }
    setCurrentTab(newTab);
  };

  const handleMenuClick = () => {
    setMenuOpen(true);
  };

  useEffect(() => {
    getDynamicMenu(user?.userSeq).then(response => {
      let formatMenu = [];
      response?.data?.data?.dynamicMenu?.[0]?.children?.filter(menuItems => {
        if (SYSTEMS_ADMIN?.[menuItems?.treeName]) {
          formatMenu.push({
            text: menuItems?.treeName,
            icon: ADMIN_MENU_ICON?.[menuItems?.treeName]?.icon,
          });
        }
      });
      setAllMenu(response?.data?.data?.dynamicMenu?.[0]?.children);
      setMenu(formatMenu);
    });
  }, []);

  const drawerContents = [
    { text: 'User Admin', icon: <ManageAccountsIcon /> },
    { text: 'Roles Admin', icon: <SettingsIcon /> },
    { text: 'Locked Cases', icon: <LockResetIcon /> },
    { text: 'Send Bulk Msg', icon: <MessageIcon /> },
    { text: 'Business Logic', icon: <AutoStoriesIcon /> },
    { text: 'Sites/Locations', icon: <AddBusinessIcon /> },

    { text: 'Server Dashboard', icon: <Dashboard /> },

    // { text: 'Upload Resources', icon: <CloudUploadIcon /> },
    { text: 'Global Settings', icon: <SettingsIcon /> },
    ...menu,
  ];

  const fetchComponent = () => {
    const menuName = allMenu?.filter(content => content?.treeName === tabContent);
    // if (menuName?.[0]?.children?.length > 0) {
    //   return <SubmenuView title={tabContent} children={menuName?.[0]?.children} />;
    // } else {
    return ADMIN_MENU_ICON?.[tabContent]?.component;
    // }
  };

  const drawerWidth = 240;

  return redirect ? (
    <Navigate to={redirect} />
  ) : (
    <Box
      component='section'
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      {/* Mobile and Desktop Drawer*/}
      <CustomDrawer
        drawerContents={drawerContents}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        // handleTabChange={handleTabChange}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        drawerWidth={drawerWidth}
        setTabContent={setTabContent}
      />
      {/* Page Contents */}
      <Box component='section' sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <CustomTabPanel sx={{ margin: 0 }} value={currentTab} index={currentTab}>
          <Box>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ ml: 'auto', mr: 2, mt: 1 }}>
                <Hidden mdUp>
                  <Tooltip title='Show Case Menu'>
                    <IconButton
                      size={isMediumScreen ? 'medium' : 'large'}
                      sx={{ width: 'max-content', height: 'max-content' }}
                      onClick={handleMenuClick}
                    >
                      <MenuIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
                    </IconButton>
                  </Tooltip>
                </Hidden>
              </Box>
            </Box>
          </Box>
          {fetchComponent()}
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
