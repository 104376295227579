import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useAuth } from '../../utils/auth/AuthService';
import dimensions from '../../utils/dimensions';
import { UserAvatar } from './UserAvatar';

export default function UserMenuDrawerItem({
  path = '',
  authorizedRoles = null,
  avatarIcon = <></>,
  userAvatar = false,
  primaryText,
  secondaryText = '',
  divider = false,
  handleCloseUserMenu,
  closeButton = false,
  handleUserLogOut = null,
  stillButton = false,
}) {
  const navigate = useNavigate();
  const { user } = useAuth();

  if (authorizedRoles == null) {
    return (
      <>
        {divider && <Divider sx={{ m: 1, opacity: 0.8 }} />}
        <ListItem
          key={path != '' ? path.substring(1) : primaryText}
          secondaryAction={
            closeButton && (
              <IconButton edge='end' onClick={handleCloseUserMenu}>
                <CloseIcon />
              </IconButton>
            )
          }
        >
          {path ? (
            <ListItemButton
              onClick={() => {
                console.log(handleUserLogOut);

                if (handleUserLogOut != null) {
                  handleUserLogOut();
                } else {
                  handleCloseUserMenu();
                  navigate(path);
                }
              }}
            >
              <ListItemAvatar>
                {userAvatar ? (
                  <UserAvatar
                    userseq={user?.userSeq ?? ''}
                    initials={`${user?.userFirstName?.charAt(0)}${user?.userLastName?.charAt(0)}`}
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                    }}
                  >
                    {avatarIcon}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText primary={primaryText} secondary={secondaryText} />
            </ListItemButton>
          ) : stillButton ? (
            <ListItemButton
              onClick={() => {
                if (handleUserLogOut != null) {
                  handleUserLogOut();
                }
              }}
            >
              <ListItemAvatar>
                {userAvatar ? (
                  <UserAvatar
                    userseq={user?.userSeq ?? ''}
                    initials={`${user?.userFirstName?.charAt(0)}${user?.userLastName?.charAt(0)}`}
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                    }}
                  >
                    {avatarIcon}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText primary={primaryText} secondary={secondaryText} />
            </ListItemButton>
          ) : (
            <>
              <ListItemAvatar>
                {userAvatar ? (
                  <UserAvatar
                    userseq={user?.userSeq ?? ''}
                    initials={`${user?.userFirstName?.charAt(0)}${user?.userLastName?.charAt(0)}`}
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                    }}
                  >
                    {avatarIcon}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText primary={primaryText} secondary={secondaryText} />
            </>
          )}
        </ListItem>
      </>
    );
  } else {
    return user?.roleCheck(authorizedRoles) ? (
      <>
        {divider && <Divider sx={{ m: 1, opacity: 0.8 }} />}
        <ListItem
          key={path != '' ? path.substring(1) : primaryText}
          secondaryAction={
            closeButton && (
              <IconButton edge='end' onClick={handleCloseUserMenu}>
                <CloseIcon />
              </IconButton>
            )
          }
        >
          {path ? (
            <ListItemButton
              onClick={() => {
                console.log(handleUserLogOut);

                if (handleUserLogOut != null) {
                  handleUserLogOut();
                } else {
                  handleCloseUserMenu();
                  navigate(path);
                }
              }}
            >
              <ListItemAvatar>
                {userAvatar ? (
                  <UserAvatar
                    userseq={user?.userSeq ?? ''}
                    initials={`${user?.userFirstName?.charAt(0)}${user?.userLastName?.charAt(0)}`}
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                    }}
                  >
                    {avatarIcon}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText primary={primaryText} secondary={secondaryText} />
            </ListItemButton>
          ) : stillButton ? (
            <ListItemButton
              onClick={() => {
                if (handleUserLogOut != null) {
                  handleUserLogOut();
                }
              }}
            >
              <ListItemAvatar>
                {userAvatar ? (
                  <UserAvatar
                    userseq={user?.userSeq ?? ''}
                    initials={`${user?.userFirstName?.charAt(0)}${user?.userLastName?.charAt(0)}`}
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                    }}
                  >
                    {avatarIcon}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText primary={primaryText} secondary={secondaryText} />
            </ListItemButton>
          ) : (
            <>
              <ListItemAvatar>
                {userAvatar ? (
                  <UserAvatar
                    userseq={user?.userSeq ?? ''}
                    initials={`${user?.userFirstName?.charAt(0)}${user?.userLastName?.charAt(0)}`}
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: dimensions.avatar_size,
                      width: dimensions.avatar_size,
                    }}
                  >
                    {avatarIcon}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText primary={primaryText} secondary={secondaryText} />
            </>
          )}
        </ListItem>
      </>
    ) : (
      <></>
    );
  }
}
