import { LockRounded } from '@mui/icons-material';
import { FormLabel, InputAdornment, Stack, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useOptionsAPI } from '../../utils/api/useOptions.hook';
import { CaseViewOptionsAutocomplete } from '../../utils/components/CaseViewOptionsAutocomplete';
import { FormikTextField } from '../../utils/components/FormikTextField';
import {
  AgeUnits,
  calculateAgeBirthToDeath,
  calculateAgeBirthToNow,
} from '../../utils/functions/calculateAgeBirthToDeath';
import { Option } from '../../views/Case/CaseViewModules/MortuaryModules/XrayRequestForm/xrayrequestform.constants';

export function CaseViewAge() {
  const formik = useFormikContext<any>();
  const { options: ageUnitOptions } = useOptionsAPI('getageunitoptions');
  const [previousAge, setPreviousAge] = useState<number | null>(null);

  const hasAge = Boolean(formik.values?.caseDemographics?.age);
  const hasBirth =
    Boolean(formik.values?.caseDemographics?.dateOfBirth) &&
    dayjs(formik.values?.caseDemographics?.dateOfBirth).isValid();

  const hasDeath =
    Boolean(formik.values?.caseDemographics?.dateOfDeath) &&
    dayjs(formik.values?.caseDemographics?.dateOfDeath).isValid();

  const use_DOB_to_DOD_Age = hasBirth && hasDeath;
  const use_DOB_to_Now_Age = hasBirth && !hasDeath;

  useEffect(() => {
    if (use_DOB_to_DOD_Age) {
      const { value: calculatedAge, unit: calculatedUnit } = calculateAgeBirthToDeath({
        birth: dayjs(formik.values?.caseDemographics?.dateOfBirth),
        death: dayjs(formik.values?.caseDemographics?.dateOfDeath),
      });

      if (previousAge === null) {
        setPreviousAge(formik.values?.caseDemographics?.age);
      }

      const unitName =
        Object.keys(AgeUnits).find(
          key => AgeUnits[key as keyof typeof AgeUnits] === calculatedUnit
        ) || 'Unknown';

      formik.setFieldValue('caseDemographics.age', calculatedAge);
      formik.setFieldValue('caseDemographics.ageUnit', {
        optionName: unitName,
        optionSeq: calculatedUnit,
      });
    } else if (use_DOB_to_Now_Age) {
      const { value: calculatedAge, unit: calculatedUnit } = calculateAgeBirthToNow(
        dayjs(formik.values?.caseDemographics?.dateOfBirth)
      );

      if (previousAge === null) {
        setPreviousAge(formik.values?.caseDemographics?.age);
      }

      const unitName =
        Object.keys(AgeUnits).find(
          key => AgeUnits[key as keyof typeof AgeUnits] === calculatedUnit
        ) || 'Unknown';

      formik.setFieldValue('caseDemographics.age', calculatedAge);
      formik.setFieldValue('caseDemographics.ageUnit', {
        optionName: unitName,
        optionSeq: calculatedUnit,
      });
    } else if (previousAge !== null) {
      formik.setFieldValue('caseDemographics.age', previousAge);
      setPreviousAge(null);
    }
  }, [
    use_DOB_to_DOD_Age,
    use_DOB_to_Now_Age,
    formik.values?.caseDemographics?.dateOfDeath,
    formik.values?.caseDemographics?.dateOfBirth,
  ]);

  useEffect(() => {
    if (Object.is(formik.values?.caseDemographics?.ageUnit, null)) {
      formik.setFieldValue('caseDemographics.ageUnit', {
        optionName: 'Years',
        optionSeq: AgeUnits.Years,
      });
    }
  }, [ageUnitOptions, formik.values?.caseDemographics?.ageUnit]);

  const selectedAgeUnit: Option = useMemo(() => {
    if (Boolean(formik.values?.caseDemographics?.ageUnit)) {
      return formik.values?.caseDemographics?.ageUnit;
    }
    return {
      optionName: 'Unknown',
      optionSeq: AgeUnits.Unknown,
    };
  }, [
    ageUnitOptions,
    formik.values?.caseDemographics?.dateOfDeath,
    formik.values?.caseDemographics?.dateOfBirth,
    formik.values?.caseDemographics?.age,
  ]);

  return (
    <>
      {/* Unit:{JSON.stringify(selectedAgeUnit.optionName)}, Age:
      {JSON.stringify(formik.values?.caseDemographics?.age)}, DOB:
      {JSON.stringify(formik.values?.caseDemographics?.dateOfBirth)}, DOD:
      {JSON.stringify(formik.values?.caseDemographics?.dateOfDeath)} */}
      <FormikTextField
        authorizedToEdit={r => [
          r.Investigator,
          r.Medical_Examiner,
          r.Administrative_Assistant,
          r.Historic_Dataentryonly,
          r.Systems_Admin,
        ]}
        type='number'
        onBlur={e => {
          const newValue = e.target.value === '' ? 0 : Number(e.target.value);
          formik.setFieldValue('caseDemographics.age', newValue);
        }}
        formikField='caseDemographics.age'
        label={
          <>
            {use_DOB_to_DOD_Age && (
              <Tooltip arrow title='Calculated using Date of Birth and Date of Death'>
                <Stack direction='row' gap={1}>
                  <FormLabel>Age</FormLabel>
                  {formik.status.editing && (
                    <FormLabel>
                      <LockRounded />
                    </FormLabel>
                  )}
                </Stack>
              </Tooltip>
            )}
            {use_DOB_to_Now_Age && (
              <Stack direction='row' gap={1}>
                <FormLabel>Age</FormLabel>
                {formik.status.editing && (
                  <FormLabel>
                    <LockRounded />
                  </FormLabel>
                )}
              </Stack>
            )}

            {!use_DOB_to_DOD_Age && !use_DOB_to_Now_Age && 'Age'}
          </>
        }
        disabled={use_DOB_to_DOD_Age || use_DOB_to_Now_Age}
        InputLabelProps={{
          shrink: true,
          sx: {
            width: '100%',
          },
        }}
        InputProps={{
          endAdornment: !formik.status.editing ? (
            <InputAdornment position='end'>{selectedAgeUnit?.optionName}</InputAdornment>
          ) : (
            <InputAdornment position='end' sx={{ width: '100%', padding: 0, margin: 0 }}>
              <CaseViewOptionsAutocomplete
                label={<></>}
                optionsEndpoint='getageunitoptions'
                formikField='caseDemographics.ageUnit'
                disableClearable={true}
                textFieldProps={{
                  hiddenLabel: true,
                  variant: 'filled',
                  sx: {
                    '& .MuiInputBase-root': {
                      padding: '0 24px 0 8px !important',
                      minHeight: '0px',
                    },
                    '& .MuiFilledInput-input': {
                      padding: '4px 0',
                    },
                  },
                }}
                sx={{
                  width: '100%',
                  '& .MuiAutocomplete-input': {
                    width: '100%',
                    textOverflow: 'ellipsis',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '& .MuiAutocomplete-endAdornment': {
                    right: '0px',
                  },
                }}
              />
            </InputAdornment>
          ),
        }}
        sx={
          !formik.status.editing
            ? undefined
            : {
                display: 'flex',
                width: '100%',
                '& .MuiInputBase-root': {
                  display: 'flex',
                  width: '100%',
                  padding: '0 !important',
                },
                '& .MuiInputBase-input[type="number"]': {
                  flexGrow: 1,
                  width: 'auto',
                  padding: '8px 12px !important',
                  minWidth: '40px',
                  marginRight: '8px',
                },
                '& .MuiInputAdornment-root': {
                  margin: 0,
                  height: 'auto',
                  maxWidth: '50%',
                },
              }
        }
      />
    </>
  );
}
