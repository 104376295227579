import { Box, Drawer, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormikContext } from 'formik';
import dimensions from '../../utils/dimensions';

export default function CaseViewDrawer({
  drawerContents,
  currentTab,
  setCurrentTab,
  menuOpen,
  setMenuOpen,
}) {
  const theme = useTheme();
  const formik = useFormikContext();
  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: theme.palette.background.default, // Use theme background color
      }}
    >
      <Box
        sx={{
          width: 'max-content',
          height: '100%',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Tabs
          orientation='vertical'
          value={currentTab}
          onChange={handleTabChange}
          textColor='primary'
          aria-label='Case view tabs'
          variant='scrollable'
          sx={{
            borderRight: 1,
            borderColor: 'divider',
            height: '100%',
            '& .MuiTabs-indicator': {
              backgroundColor:
                theme.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
            },
          }}
        >
          {drawerContents.map((content, index) =>
            content.disabled ? (
              <Tooltip
                title={
                  content.text +
                  ' module disabled ' +
                  (formik?.values?.caseInvestigations?.meAction?.optionName !== undefined
                    ? 'for ' + formik?.values?.caseInvestigations?.meAction?.optionName + ' cases'
                    : '')
                }
                arrow
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    color: '#777',
                    minHeight: '65px', // vertical size of tabs
                    padding: '6px 18px',
                    '&.Mui-selected': {
                      color:
                        theme.mode === 'dark'
                          ? theme.palette.primary.main
                          : theme.palette.primary.main,
                    },
                  }}
                >
                  <Stack direction='row' spacing={1}>
                    {content.icon}
                    <Typography
                      variant='body1'
                      sx={{
                        fontWeight: 500,
                        fontSize: '0.775rem', // Adjust font size as needed
                      }}
                    >
                      {content.text.toUpperCase()}
                    </Typography>
                  </Stack>
                </Box>
              </Tooltip>
            ) : (
              <Tab
                key={index + '-' + content.text}
                icon={content.icon}
                iconPosition='start'
                disabled={content.disabled}
                label={content.text}
                sx={{
                  width: '100%',
                  justifyContent: 'left',
                  fontSize: '0.775rem', // Adjust font size as needed
                  color: theme.palette.text.secondary, // Use light grey from theme
                  minHeight: '65px', // vertical size of tabs
                  padding: '6px 18px',
                  '&.Mui-selected': {
                    color:
                      theme.mode === 'dark'
                        ? theme.palette.primary.main
                        : theme.palette.primary.main,
                  },
                }}
              />
            )
          )}
        </Tabs>
      </Box>

      <Drawer
        variant='temporary'
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 'max-content',
            position: 'relative',
            top: dimensions.app_bar_height,
            height: `calc(100vh - ${dimensions.app_bar_height})`,
            borderRadius: 1.5,
            backgroundColor: theme.palette.background.default, // Use theme background color
          },
          zIndex: 1002,
        }}
      >
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={currentTab}
          onChange={handleTabChange}
          aria-label='Case view tabs'
          sx={{
            borderRight: 1,
            borderColor: 'divider',
            '& .MuiTabs-indicator': {
              backgroundColor:
                theme.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
            },
          }}
        >
          {drawerContents.map((content, index) =>
            content.disabled ? (
              <Tooltip
                title={
                  content.text +
                  ' module disabled ' +
                  (formik?.values?.caseInvestigations?.meAction?.optionName !== undefined
                    ? 'for ' + formik?.values?.caseInvestigations?.meAction?.optionName + ' cases'
                    : '')
                }
                arrow
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    // fontSize: '0.775rem', // Adjust font size as needed
                    color: '#777',
                    minHeight: '65px', // vertical size of tabs
                    padding: '6px 18px',
                    '&.Mui-selected': {
                      color:
                        theme.mode === 'dark'
                          ? theme.palette.primary.main
                          : theme.palette.primary.main,
                    },
                  }}
                >
                  <Stack direction='row' spacing={1}>
                    {content.icon}
                    <Typography
                      variant='body1'
                      sx={{
                        fontWeight: 500,
                        fontSize: '0.775rem', // Adjust font size as needed
                      }}
                    >
                      {content.text.toUpperCase()}
                    </Typography>
                  </Stack>
                </Box>
              </Tooltip>
            ) : (
              <Tab
                key={index + '-' + content.text}
                icon={content.icon}
                iconPosition='start'
                disabled={content.disabled}
                label={content.text}
                sx={{
                  width: '100%',
                  justifyContent: 'left',
                  fontSize: '0.775rem', // Adjust font size as needed
                  color: theme.palette.text.secondary, // Use light grey from theme
                  minHeight: '65px', // vertical size of tabs
                  padding: '6px 18px',
                  '&.Mui-selected': {
                    color:
                      theme.mode === 'dark'
                        ? theme.palette.primary.main
                        : theme.palette.primary.main,
                  },
                }}
              />
            )
          )}
          {/* {drawerContents.map((content, index) => (
            <Tab
              key={index + '-' + content.text}
              icon={content.icon}
              iconPosition='start'
              disabled={content.disabled}
              label={content.text}
              sx={{
                width: '100%',
                justifyContent: 'left',
                fontSize: '0.775rem', // Adjust font size as needed
                color: theme.palette.text.secondary, // Use light grey from theme
                minHeight: '65px', // vertical size of tabs
                padding: '6px 18px',
                '&.Mui-selected': {
                  color:
                    theme.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
                },
              }}
            />
          ))} */}
        </Tabs>
      </Drawer>
    </Box>
  );
}
