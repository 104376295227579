import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { DailyCase } from './DailyExamList';

// Import fonts
import InterBlack from '../../../assets/fonts/inter/static/Inter-Black.ttf';
import InterBold from '../../../assets/fonts/inter/static/Inter-Bold.ttf';
import InterExtraBold from '../../../assets/fonts/inter/static/Inter-ExtraBold.ttf';
import InterExtraLight from '../../../assets/fonts/inter/static/Inter-ExtraLight.ttf';
import InterLight from '../../../assets/fonts/inter/static/Inter-Light.ttf';
import InterMedium from '../../../assets/fonts/inter/static/Inter-Medium.ttf';
import InterRegular from '../../../assets/fonts/inter/static/Inter-Regular.ttf';
import InterSemiBold from '../../../assets/fonts/inter/static/Inter-SemiBold.ttf';
import InterThin from '../../../assets/fonts/inter/static/Inter-Thin.ttf';
import { Jurisdiction } from '../../../types/Jurisdiction.interface';
import { User } from '../../../types/User';

// Register fonts
Font.register({
  family: 'Inter',
  fonts: [
    { src: InterBlack, fontWeight: 800 }, // ultrabold
    { src: InterBold, fontWeight: 700 }, // bold
    { src: InterExtraBold, fontWeight: 800 }, // extra bold
    { src: InterExtraLight, fontWeight: 200 }, // extra light
    { src: InterLight, fontWeight: 300 }, // light
    { src: InterMedium, fontWeight: 500 }, // medium
    { src: InterRegular, fontWeight: 400 }, // regular
    { src: InterSemiBold, fontWeight: 600 }, // semibold
    { src: InterThin, fontWeight: 100 }, // thin
  ],
});

const styles = StyleSheet.create({
  page: {
    paddingTop: 5,
    paddingHorizontal: 20,
    fontSize: 10,
    fontFamily: 'Inter',
    fontWeight: 'normal',
    lineHeight: 1,
    height: '100vh',
  },
  title: {
    fontSize: 11,
    textAlign: 'left',
    marginBottom: 4,
    fontWeight: 'bold',
  },
  section: {
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 11,
    fontWeight: 'bold',
    marginBottom: 4,
    textDecoration: 'underline',
  },

  contentRow: {
    flexDirection: 'row',
    marginBottom: 2,
  },
  contentKey: {
    width: 120,
    fontWeight: 600,
  },
  caseInformationKey: {
    width: 240,
    fontWeight: 600,
  },
  nextOfKinTitle: {
    fontSize: 11,
    fontWeight: 'bold',
    marginBottom: 6,
    textDecoration: 'underline',
  },
  nextOfKinValue: {
    width: 120,
  },
  caseNumber: {
    width: 30,
  },
  history: {
    width: 500,
  },
  assignedME: {
    width: 120,
  },
  examType: {
    width: 30,
  },
  contentValue: {
    flex: 1,
  },
  synopsisSection: {
    marginTop: 10,
  },
  synopsisTitle: {
    fontSize: 16,
    marginBottom: 4,
    textDecoration: 'underline',
  },
  synopsisContent: {
    textAlign: 'justify',
    flexWrap: 'wrap',
  },
  divider: {
    height: 1, // Height of the divider line
    backgroundColor: '#000', // Color of the divider line
    marginTop: 5, // Space above the divider
    marginBottom: 5, // Space below the divider
    width: '100%', // Ensure it spans the entire width
  },
  footer: {
    marginTop: 10,
    borderTop: 1,
    paddingTop: 2,
    textAlign: 'left',
    fontSize: 8,
  },
});

export function DailyExamListDocument({
  cases = [],
  requestingUser,
  jdxList = [],
  sortBy = 'Unknown',
}: {
  cases: DailyCase[];
  requestingUser: User | null;
  jdxList: Jurisdiction[];
  sortBy: string;
}) {
  const formatTodaysDate = () => {
    const now = Date.now();
    const date = new Date(now).toDateString();
    const time = new Date(now).toLocaleTimeString();
    return `${date}`.toUpperCase();
  };

  const getFooterDate = () => {
    const now = Date.now();
    const date = new Date(now).toDateString();
    const time = new Date(now).toLocaleTimeString();
    return `${date} at ${time}`;
  };

  function truncateName(name: string) {
    let parts = name.split(', ');
    if (parts.length === 2) {
      return `${parts[0]}, ${parts[1].charAt(0)}`;
    }
    return name; // Return the original name if it doesn't match the expected format
  }

  const jurisdictions = Array.from(
    new Set(jdxList.map(jdx => jdx.jdxName).filter(name => name !== undefined))
  ).join(', ');

  const getDecedent = (c: DailyCase) => c.decedentName ?? 'Unknown Decedent';
  const getRace = (c: DailyCase) => c.decedentRace?.raceName ?? 'Unknown';
  const getAge = (c: DailyCase) => c.decedentAge?.ageValue ?? 'Unknown';
  const getNotes = (c: DailyCase) =>
    c.caseNotes
      .filter(n => n.isActive && n.supplementalNote.trim().length > 0)
      .map(n => n.supplementalNote);

  return (
    <Document>
      <Page size='A4' orientation='landscape' style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>
            DAILY CASE SHEET — {formatTodaysDate()} — {`${sortBy}`.toUpperCase()}
          </Text>
          <View style={styles.contentRow}>
            <Text style={styles.contentKey}>Jurisdictions:</Text>
            <Text style={styles.contentValue}>{jurisdictions}</Text>
          </View>
        </View>
        <View style={styles.section}>
          {/* <Text style={styles.nextOfKinTitle}>Next of Kin</Text> */}
          <View style={{ ...styles.contentRow, marginBottom: 10 }}>
            <Text style={{ ...styles.caseNumber, fontWeight: 600 }}>#</Text>
            <Text
              style={{
                width: 80,
                fontWeight: 600,
              }}
            >
              Case ID
            </Text>
            <Text style={{ width: 600, fontWeight: 600 }}>History</Text>
            <Text style={{ width: 100, fontWeight: 600 }}>Pathologist</Text>
            <Text style={{ width: 30, fontWeight: 600 }}>A/EE</Text>
          </View>

          {cases.map((c, index) => (
            <View key={index}>
              <View style={styles.contentRow} key={index}>
                <Text style={styles.caseNumber}>{index + 1}</Text>
                <Text style={{ width: 80 }}>{c.caseId ?? '-'}</Text>
                <Text style={{ width: 600 }}>
                  <Text
                    style={{
                      width: 600,
                      fontWeight: 600,
                      marginBottom: 1,
                    }}
                  >
                    {`${getDecedent(c)} (Age ${getAge(c)}, Race ${getRace(c)}):\n`.toUpperCase()}
                  </Text>
                  {c.caseSynopsis && c.caseSynopsis?.length > 0 && (
                    <Text>{`Synopsis: ${c.caseSynopsis?.toUpperCase()}\n`}</Text>
                  )}
                  <Text>
                    {getNotes(c).length > 0 && (
                      <Text>
                        Notes:
                        {getNotes(c).map((note, noteIndex) => (
                          <Text key={noteIndex}>{`\n• ${note}`}</Text>
                        ))}
                      </Text>
                    )}
                  </Text>
                </Text>
                <Text style={{ width: 100 }}>
                  {c.assignedPathologist?.optionName
                    ? truncateName(c.assignedPathologist.optionName)
                    : '-'}
                </Text>
                <Text style={{ width: 30 }}>{c.assignedExam?.optionName?.charAt(0) ?? '-'}</Text>
              </View>
              {index < cases.length - 1 && <View style={styles.divider} />}
            </View>
          ))}
        </View>
        <View style={styles.footer}>
          <Text>
            Generated by {requestingUser?.userName ?? '-'} on {getFooterDate()} from MedexLab
          </Text>
        </View>
      </Page>
    </Document>
  );
}
