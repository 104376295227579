import React, { useEffect, useState } from 'react';
import {Paper} from '@mui/material';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import { useNavigate } from 'react-router';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';
import { NavLink } from 'reactstrap';




// todp:  modify this page to enumerate all the batches and allow the user to click on a batch to view the samples in that batch
export default function ELabPMBatchesView() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const { REACT_APP_API_URL } = process.env;

  const columns = [
    {
      field: 'id',
      headerName: '',
      flex: 1,
      minWidth: 150,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'processbatchnumber',
      headerName: 'Process Group Num',
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      // renderCell: params => {
      //   return (
      //     <NavLink
      //       //variant="contained"
      //       color='primary'
      //       onClick={event => {
      //         handleBatchClick(event, params);
      //       }}
      //     >
      //       {params.formattedValue}
      //     </NavLink>
      //   );
      // },
    },
          
    {
      field: 'createddt',
      headerName: 'Created Date',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      renderCell: (params) => {
        const dateObj = new Date(params.value);
        const formattedDate = dateObj.toLocaleDateString(); // formats date based on locale, adjust format as needed
        return <span>{formattedDate}</span>;
      }
    },
   
  ];

  const handleBatchClick = (event, data) => {

     navigate('/batchview', { state: { batchprocessid: data.formattedValue } });
  };


  useEffect(() => {
    if (user === null) return;

    setLoading(true);

    if (user && user.token) {
      fetch(REACT_APP_API_URL + 'GetElabPMSampleBatches', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        }
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setlistresults(data);
          setLoading(false);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }
  }, [user]);

  return (
    <ReportDataGrid
      idcolumn="id"
      loading={loading}
      listresults={listresults}
      columnsInput={columns}
      title='PM Batches'
    />
  );
}
