import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useAuth } from '../../../auth/AuthService';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';

const { REACT_APP_API_URL } = process.env;

export interface PaginatedResponse<T> {
  data: T[];
  page: number;
  pageSize: number;
  totalCount: number;
  hasNextPage: boolean;
}

export interface Notification {
  notificationSeq: string;
  notificationTypeSeq: string;
  notificationSenderUserSeq: string;
  notificationReceiverUserSeq: string;
  notificationCreatedOn: string;
  notificationRead: boolean;
  notificationReadOn: string | null;
  notificationData: any;
  isActive: boolean;
}

export interface NotificationType {
  notificationTypeSeq: string;
  notificationTypeName: string;
  notificationTypeDescription: string;
  isActive: boolean;
}

interface NotificationsContext {
  notifications: Notification[];
  notificationTypes: NotificationType[];
  notificationsConnection: HubConnection | null;
  loading: boolean;
  error: string | null;
  fetchUserNotifications: () => Promise<PaginatedResponse<Notification>>;
  fetchNotificationTypes: () => Promise<NotificationType[]>;
  updateNotification: (n: Notification) => void;
  updateNotificationReadStatus: (notificationSeq: string, isAcknowledged: boolean) => Promise<void>;
}

const NotificationsContext = React.createContext<NotificationsContext>(null as any);

export const NotificationsProvider = ({ children }: { children: any }) => {
  const auth = useAuth();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationTypes, setNotificationTypes] = useState<NotificationType[]>([]);

  const [connection, setConnection] = useState<HubConnection | null>(null);

  const fetchUserNotifications = async (): Promise<PaginatedResponse<Notification>> => {
    setError(null);

    if (!auth.user?.accessToken)
      return {
        data: [],
        hasNextPage: false,
        page: 1,
        pageSize: 0,
        totalCount: 0,
      };

    try {
      const url = REACT_APP_API_URL + 'notifications/getusernotifications';
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + auth.user?.accessToken,
        },
        method: 'GET',
      });

      const notificationsJson: PaginatedResponse<Notification> = await response.json();
      setNotifications(notificationsJson.data);
      return notificationsJson;
    } catch (err) {
      setError(err as unknown as any);
      return {
        data: [],
        hasNextPage: false,
        page: 1,
        pageSize: 0,
        totalCount: 0,
      };
    } finally {
      setLoading(false);
    }
  };

  const fetchNotificationTypes = async (): Promise<NotificationType[]> => {
    setError(null);
    if (!auth.user?.accessToken) return [];

    try {
      const url = REACT_APP_API_URL + 'notifications/getnotificationtypes';
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + auth.user?.accessToken,
        },
        method: 'GET',
      });

      const notificationTypes = await response.json();
      setNotificationTypes(notificationTypes);

      return notificationTypes;
    } catch (err) {
      console.error(err);
      setError(err as unknown as any);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const fetchUserNotificationsAndTypes = async () => {
    if (!auth.user?.accessToken) return;

    const fetchUserNotificationsPromise = fetch(
      REACT_APP_API_URL + 'notifications/getusernotifications',
      {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + auth.user?.accessToken,
        },
        method: 'GET',
      }
    ).then(response => response.json());

    const fetchNotificationTypesPromise = fetch(
      REACT_APP_API_URL + 'notifications/getnotificationtypes',
      {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + auth.user?.accessToken,
        },
        method: 'GET',
      }
    ).then(response => response.json());

    try {
      setLoading(true);

      // Use Promise.all to wait for both promises to resolve
      const [notificationsJson, notificationTypesJson] = await Promise.all([
        fetchUserNotificationsPromise,
        fetchNotificationTypesPromise,
      ]);

      setNotifications(notificationsJson.data);
      setNotificationTypes(notificationTypesJson);
    } catch (err) {
      setError(err as unknown as any);
    } finally {
      setLoading(false);
    }
  };

  const updateNotification = async (n: Notification) => {
    try {
      setError(null);
      const notificationToUpdate = notifications.find(n => n.notificationSeq === n.notificationSeq);
      if (!notificationToUpdate) throw new Error('Could not find notifcationSeq');
      if (connection?.state === HubConnectionState.Connected) {
        const notificationUpdated = await connection.invoke('UpdateNotification', n);
        console.log(notificationUpdated);
        if (notificationUpdated) {
          setNotifications(notifications =>
            notifications.map(notification =>
              notification.notificationSeq === n.notificationSeq ? { ...n } : notification
            )
          );
        } else {
          throw new Error('Could not update notification');
        }
      } else {
        const url = `${REACT_APP_API_URL}notifications/updatenotification`;
        const response = await fetch(url, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.user?.accessToken}`,
          },
          body: JSON.stringify(n),
          method: 'POST',
        });

        if (response.ok) {
          setNotifications(notifications =>
            notifications.map(notification =>
              notification.notificationSeq === n.notificationSeq ? { ...n } : notification
            )
          );
        } else {
          throw new Error(response.statusText);
        }
      }
    } catch (err) {
      console.log(`Error updateNotification ${n.notificationSeq} to ${n}: ${err}`);
      setError(
        `Failed to update notification: ${err instanceof Error ? err.message : String(err)}`
      );
    }
  };

  const updateNotificationReadStatus = async (
    notificationSeq: string,
    notificationRead: boolean = false
  ) => {
    try {
      setError(null);
      const notificationToUpdate = notifications.find(n => n.notificationSeq === notificationSeq);
      if (!notificationToUpdate) throw new Error('Could not find notifcationSeq');

      if (connection?.state === HubConnectionState.Connected) {
        const notificationUpdated = await connection.invoke(
          'UpdateNotificationReadStatus',
          notificationSeq,
          notificationRead
        );

        if (notificationUpdated) {
          setNotifications(notifications =>
            notifications.map(notification =>
              notification.notificationSeq === notificationSeq
                ? { ...notification, notificationRead }
                : notification
            )
          );
        }
      } else {
        const url = `${REACT_APP_API_URL}notifications/updatenotificationreadstatus?notificationSeq=${notificationSeq}&notificationRead=${notificationRead}`;
        const response = await fetch(url, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${auth.user?.accessToken}`,
          },
          method: 'POST',
        });

        if (response.ok) {
          setNotifications(notifications =>
            notifications.map(notification =>
              notification.notificationSeq === notificationSeq
                ? { ...notification, notificationRead }
                : notification
            )
          );
        } else {
          throw new Error(response.statusText);
        }
      }
    } catch (err) {
      console.log(
        `Error updateNotificationReadStatus ${notificationSeq} to ${notificationRead}: ${err}`
      );
      setError(
        `Failed to update notification: ${err instanceof Error ? err.message : String(err)}`
      );
    }
  };

  const establishConnection = () => {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(
        REACT_APP_API_URL +
          `notificationHub?userSeq=${auth.user?.userSeq}&accessToken=${auth.user?.accessToken}`
      )
      .withAutomaticReconnect()
      .build();

    hubConnection.on('ReceiveTerminationMessage', (m: string) => {
      setError(m);
    });

    hubConnection
      .start()
      .then(() => {
        console.log('Connection to Notification hub established: ' + auth.user?.userSeq);
        setConnection(hubConnection);
      })

      .catch(error => console.error(error));
  };

  useEffect(() => {
    fetchUserNotificationsAndTypes();
    establishConnection();
  }, []);

  useEffect(() => {
    if (!connection) return;

    connection.on('ReceiveNotification', (n: Notification) => {
      setNotifications(prev => [...prev, n]);
    });

    connection.on('ReceiveErrorMessage', (error: string) => {
      setError(error ?? 'Unknown error');
    });

    () => {
      connection?.off('ReceiveNotification');
      connection?.off('ReceiveTerminationMessage');
      connection?.off('ReceiveErrorMessage');
      connection?.stop().then(() => console.log('Connection to NotificationHub stopped.'));
    };
  }, [connection]);

  const value = {
    notifications,
    notificationsConnection: connection,
    notificationTypes,
    loading,
    error,
    updateNotification,
    updateNotificationReadStatus,
    fetchUserNotifications,
    fetchNotificationTypes,
  };

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

export function useNotifications() {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error('useNotifications must be used within a NotificationsProvider ');
  }

  return context;
}
