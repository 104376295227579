import PlaceHolderView from './PlaceHolderView';

import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DailyCaseListIcon from '@mui/icons-material/RecentActors';

import BackupTableIcon from '@mui/icons-material/BackupTable';
import GradingIcon from '@mui/icons-material/Grading';

import CustomDrawer from '../../utils/components/CustomDrawer';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';

import '../../App.css';

import { useAuth } from '../../utils/auth/AuthService';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const drawerWidth = 240;

export default function SecurityAdminView() {
  const [redirect, setredirect] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const { user } = useAuth();

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  const handleMenuClick = () => {
    setMenuOpen(true);
  };

  useEffect(() => {
    if (!user) {
      setredirect('/login');
    }
  }, []);

  const drawerContents = [
    { text: 'Placeholder 1', icon: <GradingIcon /> },
    { text: 'Placeholder 2', icon: <GradingIcon /> },
  ];

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      {/* Mobile and Desktop Drawer*/}
      <CustomDrawer
        drawerContents={drawerContents}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        // handleTabChange={handleTabChange}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        drawerWidth={drawerWidth}
      />
      {/* Page Contents */}
      <Box
        sx={{
          width: {
            xs: '100%',
            md: `calc(100vw - ${drawerWidth}px)`,
          },
          display: 'flex',
        }}
      >
        <TabPanel style={{ width: '100%', alignItems: 'center' }} value={currentTab} index={0}>
          <Card style={{ width: '100%', height: 'max-content' }} className='card-main'>
            <CardHeader className='header-txt' style={{ display: 'flex' }}>
              <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>Placeholder 1</p>
              <IconButton
                sx={{
                  color: '#fff',
                  ml: 'auto',
                  display: { xs: 'block', md: 'none' },
                }}
                onClick={handleMenuClick}
              >
                <MenuIcon />
              </IconButton>
            </CardHeader>
            <CardBody>
              <PlaceHolderView />
            </CardBody>
          </Card>
        </TabPanel>
        <TabPanel style={{ width: '100%', alignItems: 'center' }} value={currentTab} index={1}>
          <Card style={{ width: '100%' }} className='card-main'>
            <CardHeader className='header-txt' style={{ display: 'flex' }}>
              <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>Placeholder 2</p>
              <IconButton
                sx={{
                  color: '#fff',
                  ml: 'auto',
                  display: { xs: 'block', md: 'none' },
                }}
                onClick={handleMenuClick}
              >
                <MenuIcon />
              </IconButton>
            </CardHeader>
            <CardBody>
              <PlaceHolderView />
            </CardBody>
          </Card>
        </TabPanel>
      </Box>
    </Box>
  );
}
