import { Grid, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useMotionValue } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { useOptionsAPI } from '../utils/api/useOptions.hook';
import { useAuth } from '../utils/auth/AuthService';
import { CaseViewOptionsAutocomplete } from '../utils/components/CaseViewOptionsAutocomplete';
import { FormikTextField } from '../utils/components/FormikTextField';
import { Livery } from '../utils/constants/Livery';
import { MEAction } from '../utils/constants/MEAction';
import { formatShortTimeDistance } from '../views/Livery/LiveryModules/CaseActivityLogTimeline';
import {
  LegacyCaseActivityLogRecord,
  LegacyLiveryStatus,
} from '../views/Livery/LiveryModules/livery.api';
import { useLiveryLog } from '../views/Livery/LiveryModules/useLiveryCase';

const springConfig = {
  type: 'tween',
  duration: 0.3,
  ease: 'easeInOut',
};

const exitConfig = {
  type: 'spring',
  stiffness: 300,
  damping: 30,
  scale: 0.8,
  opacity: 0,
};

const animationConfig = {
  initial: { scale: 0, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 0, opacity: 0 },
  transition: {
    type: 'spring',
    stiffness: 300,
    damping: 30,
    duration: 0.3,
  },
};

const { REACT_APP_API_URL } = process.env;

export function LiveryCard({ label = 'Livery' }) {
  const formik = useFormikContext<any>();
  const { user } = useAuth();
  const authorizedToEdit =
    user?.roleCheck([
      'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
      'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
      'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
      '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
      '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
    ]) || false;

  const { handleResetdisabledFields } = useResetDisabledFields();

  const Selected_ME_Action: string = useMemo(() => {
    return formik.values?.caseInvestigations?.meAction?.optionSeq?.toUpperCase() || '';
  }, [formik.values?.caseInvestigations?.meAction?.optionSeq]);

  const Selected_ME_Action_Type: string = useMemo(() => {
    return formik.values?.caseInvestigations?.meActionType?.optionSeq?.toUpperCase() || '';
  }, [formik.values?.caseInvestigations?.meActionType?.optionSeq]);

  const Selected_Livery_Option: string = useMemo(() => {
    return formik.values?.caseInvestigations?.liveryOption?.optionSeq?.toUpperCase() || '';
  }, [formik.values?.caseInvestigations?.liveryOption?.optionSeq]);

  const Selected_Livery_Items_Requested_Options: Option[] = useMemo(() => {
    if (Array.isArray(formik.values?.caseInvestigations?.itemsRequested)) {
      return formik.values?.caseInvestigations?.itemsRequested;
    }
    return [];
  }, [formik.values?.caseInvestigations?.itemsRequested]);

  const Show_Livery_Service_Users =
    Selected_Livery_Option === Livery.Constants.LiveryOptions.Livery_To_Transport_Body;

  const Show_Livery_Recovery_Seal_Number =
    Selected_Livery_Option === Livery.Constants.LiveryOptions.Investigator_To_Transport_Body ||
    Selected_Livery_Option === Livery.Constants.LiveryOptions.Law_Enforcement_To_Transport_Body;

  const Show_Livery_Items_Requested_Other_Items =
    Show_Livery_Service_Users &&
    Selected_Livery_Items_Requested_Options.findIndex(
      (o: Option) => o.optionSeq?.toUpperCase() === Livery.Constants.LiveryItemsRequested.Other
    ) > -1;

  const { options: noLiveryReasonOptions } = useOptionsAPI('getnoliveryreasonoptions');
  const overflow = useMotionValue('scroll');

  return (
    <Grid
      container
      spacing={2}
      // onAnimationStart={() => overflow.set('hidden')}
      // onAnimationComplete={() => overflow.set('scroll')}
      // layout

      overflow={'hidden'}
      sx={{ pt: 1.2 }}
    >
      <Grid item xs={12} md={1}>
        <Typography
          variant='subtitle1'
          color='gray'
          textTransform='uppercase'
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <strong>{label}</strong>
        </Typography>
      </Grid>

      <Grid item xs={12} md={3}>
        <CaseViewOptionsAutocomplete
          label='Method'
          optionsEndpoint='getliveryoptions'
          formikField='caseInvestigations.liveryOption'
          authorizedToEdit={r => [
            r.Investigator,
            r.Medical_Examiner,
            r.Administrative_Assistant,
            r.Historic_Dataentryonly,
            r.Systems_Admin,
          ]}
          onOptionChange={newOption => {
            handleResetdisabledFields('liveryOption', newOption);
          }}
          textFieldProps={{
            variant: 'outlined',
            hiddenLabel: true,
            InputLabelProps: {
              shrink: true,
            },
          }}
          fullWidth
        />
      </Grid>

      {Show_Livery_Recovery_Seal_Number && (
        <Grid key='recovery-seal' item xs={12} md={3}>
          <FormikTextField
            label='Recovery Seal No'
            formikField='caseInvestigations.remainTagID'
            authorizedToEdit={r => [
              r.Investigator,
              r.Medical_Examiner,
              r.Administrative_Assistant,
              r.Historic_Dataentryonly,
              r.Systems_Admin,
            ]}
            onTextChange={text => {
              formik.setFieldValue('caseMortuary.origRecoverySealNo', text);
              if (text?.trim()) {
                formik.setFieldValue('caseMortuary.recoverySealIntact', true);
              } else {
                formik.setFieldValue('caseMortuary.recoverySealIntact', false);
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      )}

      {Selected_Livery_Option === Livery.Constants.LiveryOptions.Livery_Not_Required && (
        <Grid key='livery-not-required-reasons' item xs={12} md={3}>
          <CaseViewOptionsAutocomplete
            label='Reason'
            optionsEndpoint='getnoliveryreasonoptions'
            formikField='caseInvestigations.noLiveryBodyTransportedBy'
            value={
              formik.values?.caseInvestigations?.noLiveryBodyTransportedBy != null
                ? noLiveryReasonOptions?.find(
                    option =>
                      option.optionName ===
                      (formik.values?.caseInvestigations?.noLiveryBodyTransportedBy === 'Hospital'
                        ? 'Hospital'
                        : typeof formik.values?.caseInvestigations?.noLiveryBodyTransportedBy !==
                            'undefined' &&
                          formik.values?.caseInvestigations?.noLiveryBodyTransportedBy
                        ? 'Other'
                        : null)
                  ) || null
                : null
            }
            textFieldProps={{
              InputLabelProps: {
                shrink: true,
              },
            }}
            onlyUseOptionName={true}
            authorizedToEdit={r => [
              r.Investigator,
              r.Medical_Examiner,
              r.Administrative_Assistant,
              r.Historic_Dataentryonly,
              r.Systems_Admin,
            ]}
            onOptionChange={newOption => {
              formik.setFieldValue(
                'caseInvestigations.noLiveryBodyTransportedByOther',
                newOption?.optionName?.toLowerCase() === 'hospital' ? '' : null
              );
            }}
          />
        </Grid>
      )}

      {Selected_Livery_Option === Livery.Constants.LiveryOptions.Livery_Not_Required &&
        formik.values?.caseInvestigations?.noLiveryBodyTransportedBy?.length > 0 &&
        formik.values?.caseInvestigations?.noLiveryBodyTransportedBy?.toLowerCase() !==
          'hospital' && (
          <Grid key='livery-not-required-other' item xs={12} md={3}>
            <FormikTextField
              label='Other Reason'
              formikField='caseInvestigations.noLiveryBodyTransportedByOther'
              authorizedToEdit={r => [
                r.Investigator,
                r.Medical_Examiner,
                r.Administrative_Assistant,
                r.Historic_Dataentryonly,
                r.Systems_Admin,
              ]}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        )}

      {Show_Livery_Service_Users && (
        <Grid key='livery-service-users' item xs={12} md={2.5}>
          <CaseViewOptionsAutocomplete
            label='Service'
            optionsEndpoint={`getliveryuseroptions?jdxSeq=${formik.values?.caseSummary?.jdxSeq}`}
            formikField='caseInvestigations.liveryUser'
            authorizedToEdit={r => [
              r.Investigator,
              r.Medical_Examiner,
              r.Administrative_Assistant,
              r.Historic_Dataentryonly,
              r.Systems_Admin,
            ]}
            textFieldProps={{
              helperText: <LiveryUserContactHelpText />,
              InputLabelProps: {
                shrink: true,
              },
            }}
          />
        </Grid>
      )}

      {Show_Livery_Service_Users && (
        <Grid key='livery-items-requested' item xs={12} md={2.5}>
          <CaseViewOptionsAutocomplete
            label={'Items Requested'}
            optionsEndpoint={`getitemoptions`}
            formikField='caseInvestigations.itemsRequested'
            mode='checkbox'
            multiple
            authorizedToEdit={r => [
              r.Investigator,
              r.Medical_Examiner,
              r.Administrative_Assistant,
              r.Historic_Dataentryonly,
              r.Systems_Admin,
            ]}
            onOptionChange={(checkedOptions, allOptions) => {
              console.log(checkedOptions);
            }}
            textFieldProps={{
              InputLabelProps: {
                shrink: true,
              },
            }}
          />
        </Grid>
      )}

      {Show_Livery_Items_Requested_Other_Items && (
        <Grid key='livery-items-other' item xs={12} md={2.9}>
          <FormikTextField
            label='Other requested items'
            formikField='caseInvestigations.otherItemRequested'
            authorizedToEdit={r => [
              r.Investigator,
              r.Medical_Examiner,
              r.Administrative_Assistant,
              r.Historic_Dataentryonly,
              r.Systems_Admin,
            ]}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

function useResetDisabledFields() {
  const formik = useFormikContext<any>();

  const handleResetdisabledFields = (fieldName: string, value: any) => {
    if (fieldName === 'meAction') {
      if (value?.optionSeq !== MEAction.Constants.Disposition.Accepted) {
        formik.setFieldValue('caseInvestigations.investigationReason', null);
        formik.setFieldValue('caseInvestigations.meActionType', null);
      }

      if (value?.optionSeq !== MEAction.Constants.Disposition.Release) {
        formik.setFieldValue('caseInvestigations.releaseReason', null);
        formik.setFieldValue('caseInvestigations.physician', null);
        formik.setFieldValue('casePathology.meApproval', '');
        formik.setFieldValue('casePathology.rejectedReason', '');
        formik.setFieldValue('caseInvestigations.approvingPathologistSeq', null);
      }

      if (value?.optionSeq !== MEAction.Constants.Disposition.Release) {
        formik.setFieldValue('caseInvestigations.transferReasonSeq', null);
        formik.setFieldValue('caseInvestigations.transferToJdxSeq', null);
        formik.setFieldValue('caseInvestigations.transferNotes', null);
      }

      if (
        value?.optionSeq !== MEAction.Constants.Disposition.Accepted &&
        value?.optionSeq !== MEAction.Constants.Disposition.StorageOnly
      ) {
        formik.setFieldValue('caseInvestigations.liveryOption', null);
      }
    }

    if (fieldName === 'liveryOption' || fieldName === 'meAction') {
      if (
        value?.optionSeq !== Livery.Constants.LiveryOptions.Livery_To_Transport_Body &&
        value?.optionSeq !== MEAction.Constants.Disposition.Accepted &&
        value?.optionSeq !== MEAction.Constants.Disposition.StorageOnly
      ) {
        formik.setFieldValue('caseInvestigations.liveryUserSeq', null);
        // setLiveryUserContact(null);
      }
    }

    if (fieldName === 'liveryOption') {
      if (value?.optionSeq !== Livery.Constants.LiveryOptions.Livery_Not_Required) {
        formik.setFieldValue('caseInvestigations.noLiveryBodyTransportedBy', null);
        formik.setFieldValue('caseInvestigations.noLiveryBodyTransportedByOther', '');
        formik.setFieldValue('caseInvestigations.remainTagID', null);
      }

      formik.setFieldValue('caseInvestigations.itemsRequested', []);
      formik.setFieldValue('caseInvestigations.otherItemRequested', null);
    }

    if (fieldName === 'meActionType') {
      if (value?.optionSeq !== MEAction.Constants.DispositionType.Admitted_To_Morgue) {
        formik.setFieldValue('caseInvestigations.liveryUserSeq', null);
        formik.setFieldValue('caseInvestigations.liveryOption', null);
        formik.setFieldValue('caseInvestigations.liveryUserSeq', null);
        formik.setFieldValue('caseInvestigations.liveryOption', null);
        formik.setFieldValue('caseInvestigations.noLiveryBodyTransportedBy', null);
        formik.setFieldValue('caseInvestigations.noLiveryBodyTransportedByOther', '');
        formik.setFieldValue('caseInvestigations.certifiedBy', null);
      }
    }
  };

  return { handleResetdisabledFields };
}

function LiveryUserContactHelpText() {
  const [contact, setContact] = useState<string | null>(null);
  const { user } = useAuth();
  const { values } = useFormikContext<any>();
  const liveryUserSeq: string | null = values?.caseInvestigations?.liveryUser?.optionSeq ?? null;

  //need to look at why initially status was not displayed until edit and save.
  // const {
  //   data: liveryLog = [],
  //   loading: liveryLogLoading,
  //   error: liveryLogErr,
  // } = useLiveryLog(values?.caseSummary?.caseSeq);

  const liveryLog = [];
  const liveryLogForSelectedLiveryUser = useMemo(
    () =>
      liveryLog
        .filter(l => l.actualLoggedByUserSeq.toUpperCase() === liveryUserSeq?.toUpperCase())
        .sort((a, b) => {
          const timeA = a.createdOn ? new Date(a.createdOn).getTime() : 0;
          const timeB = b.createdOn ? new Date(b.createdOn).getTime() : 0;
          return timeB - timeA;
        }),

    [liveryUserSeq, liveryLog]
  );

  const isDecedentCheckedIn = Boolean(values.caseMortuary?.checkInDate);
  const isAssigned =
    liveryLogForSelectedLiveryUser.find(
      a => a.activitySeq.toUpperCase() === LegacyLiveryStatus.ASSIGNED
    ) ?? false;

  const latestLog = Array.isArray(liveryLogForSelectedLiveryUser)
    ? liveryLogForSelectedLiveryUser[0]
    : [][0];

  function statusToEnglish(log: LegacyCaseActivityLogRecord) {
    const isAssignor = log.createdByUserSeq?.toUpperCase() === user?.userSeq.toUpperCase();

    if (log.activitySeq) {
      log.activitySeq = log.activitySeq?.toUpperCase();
    }

    if (log.activitySeq === LegacyLiveryStatus.ASSIGNED) {
      return 'Assigned, not yet accepted';
    }

    if (log.activitySeq === LegacyLiveryStatus.ACCEPTED) {
      if (isAssignor) {
        return 'Accepted by service';
      }
      return 'Accepted by service';
    }

    if (log.activitySeq === LegacyLiveryStatus.LIVERY_EN_ROUTE) {
      return 'En route to pickup location';
    }

    if (log.activitySeq === LegacyLiveryStatus.LIVERY_AT_SCENE) {
      return 'At the pickup location';
    }

    if (log.activitySeq === LegacyLiveryStatus.CUSTODY_OF_BODY_SEALS_BODY) {
      return 'In custody of decedent';
    }

    if (log.activitySeq === LegacyLiveryStatus.DEPARTED_SCENE) {
      return 'Departed pickup location';
    }

    if (log.activitySeq === LegacyLiveryStatus.DELAYED) {
      return 'Delayed';
    }

    if (log.activitySeq === LegacyLiveryStatus.DEFERRED) {
      if (isAssignor) {
        return 'Deferred by service';
      }

      return 'Deferred by service';
    }

    if (log.activitySeq === LegacyLiveryStatus.WAITING_FOR_ASSISTANCE) {
      return 'Awaiting assistance';
    }

    if (log.activitySeq === LegacyLiveryStatus.CANCELLED) {
      return 'Cancelled';
    }
  }

  useEffect(() => {
    if (!values?.caseInvestigations?.liveryUser?.optionSeq) {
      setContact(null);
      return;
    }

    const fetchContactItem = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_URL}getcontactitem?userSeq=${values?.caseInvestigations?.liveryUser?.optionSeq}`
        );
        if (response.status === 204) {
          setContact(null);
        } else {
          const data = await response.json();
          setContact(data?.contactItemDetails || null);
        }
      } catch (error) {
        console.error('Error fetching contact item:', error);
        setContact(null);
      }
    };

    fetchContactItem();
  }, [values?.caseInvestigations?.liveryUser?.optionSeq]);

  return (
    <Stack>
      {contact && <Typography>Contact: {contact}</Typography>}
      {/* {values?.caseInvestigations?.liveryUser?.optionSeq && isAssigned && !isDecedentCheckedIn && (
        <Stack direction='row' gap={1}>
          <Typography>Status: </Typography>
          <Typography>{statusToEnglish(latestLog)}</Typography>
          <Typography sx={{ ml: 1 }} color='text.secondary'>
            {latestLog?.setAt ? formatShortTimeDistance(dayjs(latestLog.setAt)) : 'Unknown'}
          </Typography>
        </Stack>
      )} */}
    </Stack>
  );
}
