import { ExpandMoreRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DataGridPremium,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
  GridTreeNodeWithRender,
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { useAuth } from '../../../utils/auth/AuthService';
import { LiveryCaseWithStatusOptions } from '../LiveryCheckInView';
import { AddressCell } from './AddressCell';
import { LegacyLiveryStatus, LiveryAPI, LiveryCase } from './livery.api';
import { YourLiveryDataGridNoRowsOverlay } from './YourLiveryDataGirdNoRowsOverlay';
import { YourLiveryDataGridAcceptedActions } from './YourLiveryDataGridAcceptedActions';
import { YourLiveryDataGridDetailPane } from './YourLiveryDataGridDetailPane';

export function YourLiveryDataGrid({
  liveryCases = [],
  filterModel = {
    items: [
      {
        field: 'status',
        operator: 'equals',
        value: 'assigned',
      },
    ],
  },
  setFilterModel,
  loading = true,
  refetchCases,
}: {
  liveryCases: LiveryCaseWithStatusOptions[];
  filterModel: GridFilterModel;
  setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>;
  loading: boolean;
  refetchCases: () => Promise<void>;
}) {
  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);
  const handleDetailPanelExpandedRowIdsChange = (
    ids: GridRowId[],
    details: GridCallbackDetails<any>
  ) => {
    setExpandedRowIds(ids);
  };

  const getDetailPanelContent = useCallback(
    ({ params }: { params: GridRowParams<LiveryCase> }) => (
      <Collapse in={expandedRowIds.includes(params.row.caseSeq)} unmountOnExit={false}>
        <Box component='div' sx={{ p: 0, mb: 2 }}>
          <Stack justifyContent='flex-start' justifyItems='flex-start'>
            <YourLiveryDataGridDetailPane {...params} />

            <Typography variant='caption' color='text.secondary' mt={1} ml={1}>
              Assigned on{' '}
              {new Date(
                params.row.activityLog.find(
                  a => a.activitySeq?.toUpperCase() === LegacyLiveryStatus.ASSIGNED
                )?.createdOn ?? Date.now()
              ).toLocaleDateString()}{' '}
              by{' '}
              {
                params.row.activityLog.find(
                  a => a.activitySeq?.toUpperCase() === LegacyLiveryStatus.ASSIGNED
                )?.loggedByUsername
              }
            </Typography>
          </Stack>
        </Box>
        <Divider component='hr' variant='fullWidth' />
      </Collapse>
    ),
    [expandedRowIds]
  );

  return (
    <Box component='div' height='100%'>
      <DataGridPremium
        density='comfortable'
        rows={liveryCases}
        columns={columns}
        filterModel={filterModel}
        onFilterModelChange={model => setFilterModel(model)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
          columns: {
            columnVisibilityModel: {
              status: false,
            },
          },
        }}
        slots={{
          noRowsOverlay: YourLiveryDataGridNoRowsOverlay,
          noResultsOverlay: YourLiveryDataGridNoRowsOverlay,
        }}
        slotProps={{
          row: {
            refetchCases,
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection={false}
        disableRowSelectionOnClick={true}
        disableDensitySelector={true}
        disableColumnSelector={true}
        disableMultipleRowSelection={true}
        disableColumnMenu={true}
        hideFooter={true}
        hideFooterRowCount={true}
        autoHeight={true}
        rowSelection={false}
        editMode='row'
        disableColumnResize={true}
        disableColumnReorder={true}
        loading={loading}
        getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        getDetailPanelContent={params => getDetailPanelContent({ params })}
        getDetailPanelHeight={() => 'auto'}
        detailPanelExpandedRowIds={expandedRowIds}
        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
        onCellDoubleClick={params => {
          const currentExpandedRowIds = expandedRowIds || [];
          const isExpanded = currentExpandedRowIds.includes(params.id);
          setExpandedRowIds(
            isExpanded
              ? currentExpandedRowIds.filter(id => id !== params.id)
              : [...currentExpandedRowIds, params.id]
          );
        }}
        getRowId={row => row.caseSeq}
        sx={{
          border: 'none',
          '& .MuiDataGrid-main': {
            border: 'none',
            overflow: 'hidden',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            borderTop: 'none',
            overflow: 'hidden',
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
          },
          '& .MuiDataGrid-cell': {
            overflow: 'hidden',
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .even': {
            backgroundColor: theme =>
              theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.04)',
          },
          '& .odd': {
            backgroundColor: theme =>
              theme.palette.mode === 'light' ? 'white' : theme.palette.background.default,
          },

          // '& .MuiDataGrid-row': {
          //   '&:nth-of-type(even)': {
          //     backgroundColor: theme =>
          //       theme.palette.mode === 'light'
          //         ? 'rgba(0, 0, 0, 0.04)'
          //         : 'rgba(255, 255, 255, 0.04)',
          //     '& .MuiCollapse-root': {
          //       backgroundColor: 'inherit',
          //     },
          //   },
          //   '&:nth-of-type(odd)': {
          //     backgroundColor: theme =>
          //       theme.palette.mode === 'light' ? 'white' : theme.palette.background.default,
          //     '& .MuiCollapse-root': {
          //       backgroundColor: 'inherit',
          //     },
          //   },
          // },
        }}
      />
    </Box>
  );
}

const columns: GridColDef<LiveryCaseWithStatusOptions>[] = [
  { field: 'caseID', headerName: 'CaseID', sortable: false, editable: false },
  {
    field: 'pickupAddressSeq',
    headerName: 'Pickup',
    align: 'left',
    sortable: false,
    editable: false,
    flex: 1,
    renderCell: params => <AddressCell address={params.row.address} />,
  },
  // {
  //   field: 'deliveryAddressSeq',
  //   headerName: 'Delivery',
  //   align: 'left',
  //   sortable: false,
  //   editable: false,
  //   flex: 1,
  //   renderCell: params => <AddressCell addressSeq={params.row.deliveryAddressSeq} />,
  // },
  {
    field: 'status',
    headerName: 'Status',
    align: 'left',
    sortable: false,
    editable: false,
    flex: 0.5,
  },
  {
    field: 'decedentFullName',
    headerName: 'Decedent',
    align: 'left',
    sortable: false,
    editable: false,
    flex: 0.5,
  },
  {
    field: 'setAt',
    headerName: 'Date Assigned',
    type: 'string',
    align: 'left',
    sortable: false,
    editable: false,
    flex: 0.5,
    valueGetter: params => {
      const activityLog = params.row.activityLog ?? [];
      const assignedActivity =
        activityLog.find(a => a.activitySeq?.toUpperCase() === LegacyLiveryStatus.ASSIGNED) ?? null;
      if (assignedActivity) {
        const localDate = assignedActivity.setAt
          ? dayjs(assignedActivity.setAt).format('YYYY-MM-DDTHH:mm:ss')
          : 'Unknown';

        return localDate;
      }
      return 'Unknown';
    },
  },
  {
    field: 'actions',
    headerName: '',
    align: 'left',
    sortable: false,
    editable: false,
    flex: 3,
    renderCell: params => (
      <>
        {params.row.status === 'assigned' && <AssignedActions {...params} />}
        {params.row.status === 'accepted' && <YourLiveryDataGridAcceptedActions {...params} />}
      </>
    ),
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: params => <CustomDetailPanelToggle id={params.id} value={params.value} />,
  },
];

function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);
  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id]);

  return (
    <IconButton
      size='small'
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Close' : 'Open'}
    >
      <Tooltip arrow title={isExpanded ? 'Less information' : 'More information'}>
        <ExpandMoreRounded
          sx={{
            overflow: 'hidden',
            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
            transition: (theme: {
              transitions: {
                create: (arg0: string, arg1: { duration: any }) => any;
                duration: { shortest: any };
              };
            }) =>
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
          }}
          fontSize='inherit'
        />
      </Tooltip>
    </IconButton>
  );
}

function AssignedActions(
  params: GridRenderCellParams<LiveryCaseWithStatusOptions, any, any, GridTreeNodeWithRender>
) {
  const auth = useAuth();
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [openDeferDialog, setOpenDeferDialog] = useState(false);

  const handleAcceptClick = () => {
    setOpenAcceptDialog(true);
  };
  const handleDeferClick = () => {
    setOpenDeferDialog(true);
  };

  const handleAcceptConfirm = async () => {
    try {
      await LiveryAPI.updateLiveryStatusForCase({
        accessToken: auth.user?.accessToken!,
        caseSeq: params.row.caseSeq,
        statusSeq: LegacyLiveryStatus.ACCEPTED,
        userSeq: auth.user?.userSeq!,
      });
    } catch {
    } finally {
      setOpenAcceptDialog(false);
      params.row.refetchCases();
    }
  };

  const handleDeferConfirm = async () => {
    try {
      await LiveryAPI.updateLiveryStatusForCase({
        accessToken: auth.user?.accessToken!,
        caseSeq: params.row.caseSeq,
        statusSeq: LegacyLiveryStatus.DEFERRED,
        userSeq: auth.user?.userSeq!,
      });
    } catch {
    } finally {
      setOpenDeferDialog(false);
      params.row.refetchCases();
    }
  };

  return (
    <>
      <Button variant='contained' size='small' sx={{ mr: 1 }} onClick={handleAcceptClick}>
        Accept
      </Button>
      <Button variant='text' color='error' size='small' onClick={handleDeferClick}>
        Defer
      </Button>
      <Dialog open={openAcceptDialog} onClose={() => setOpenAcceptDialog(false)}>
        <DialogTitle>Accept Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to accept this case?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAcceptDialog(false)}>Cancel</Button>
          <Button onClick={handleAcceptConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeferDialog} onClose={() => setOpenDeferDialog(false)}>
        <DialogTitle>Defer Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to defer this case?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeferDialog(false)}>Cancel</Button>
          <Button onClick={handleDeferConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
