import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { TailSpin } from 'react-loader-spinner';
import { Alert } from 'reactstrap';
import { useAuth } from '../auth/AuthService';
import { Module_Name, Photo_Types } from '../constants/constants';

const baseStyle = {
  minWidth: 550,
  minHeight: 400,
  border: '3px dashed #ccc',
  borderColor: '#ccc',
  borderRadius: 1,
  padding: 30,
  backgroundColor: '#eee',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function MLPhotoDropzone({
  isOpen,
  setIsOpen,
  documentTypeRequired,
  caseNumber,
  caseSeq,
  onSuccessUpload,
  moduleName,
}) {
  const { user } = useAuth();
  const [userSeq, setuserSeq] = useState(user?.userSeq);
  const { REACT_APP_API_URL } = process.env;
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [allPhotoType, setAllPhotoType] = useState([]);
  const [documentTypeOption, setDocumentTypeOption] = useState(null);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [thumbnails, setThumbnails] = useState({});

  const handleClose = () => {
    setIsOpen(false);
    setRejectedFiles([]);
    setAcceptedFiles([]);
    setDocumentTypeOption(null);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const dialogTitle = () => (
    <>
      <div style={{ paddingTop: '1rem' }}>
        {rejectedFiles?.length > 0 && (
          <Alert color='danger' isOpen={showErrorAlert} toggle={dismissAlert}>
            {rejectedFiles.map((file, index) => (
              <div style={{ fontSize: '14px' }} key={index}>
                {`${index + 1}. ${file.name.split('.')[0]} : ${file.rejectReason}`}
              </div>
            ))}
          </Alert>
        )}
      </div>
    </>
  );

  const dismissAlert = () => {
    setShowErrorAlert(false);
    setRejectedFiles([]);
  };

  const handlePreviewIcon = fileObject => {
    const { type, path } = fileObject;

    if (type.startsWith('image/')) {
      // Display image preview for photo files
      if (thumbnails[path]) {
        // If thumbnail is already loaded, use it
        return <img src={thumbnails[path]} alt={fileObject.name} style={{ height: '50px', width:'110px' }} />;
      } else {
        // Load thumbnail using FileReader
        const reader = new FileReader();
        reader.onload = event => {
          const thumbnailUrl = event.target.result;
          setThumbnails(prevThumbnails => ({ ...prevThumbnails, [path]: thumbnailUrl }));
        };
        reader.readAsDataURL(fileObject);

        // Return a temporary placeholder while the thumbnail is loading
        return <div style={{ width: '50px', height: '50px', background: '#eee' }}>Loading...</div>;
      }
    }

    // Fallback to the default icon if no specific preview is available
    return <TailSpin color='#00BFFF' height={20} width={20} />;
  };

  const handleDrop = async (acceptedFiles, rejectedFiles) => {
    const newRejectedFiles = [];
    const caseIds = [];

    // // Check each file to see if it has a prohibited extension
    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      const extension = file.name.split('.').pop();

      if (!['jpg', 'jpeg', 'png'].includes(extension.toLowerCase())) {
        file.rejectReason = 'This file has a filetype that is not allowed';
        file.accepted = false;
        newRejectedFiles.push(file);
        acceptedFiles.splice(i, 1);
        i--; // Adjust index to account for removed item
      }
      else{
        file.status = 'Pending';
      }
    }

    // // Perform validation on the DocumentType
    // acceptedFiles.forEach((file) => {
    //   const [caseId, documentType] = file.name.split('_');

    //   caseIds.push(caseId);

    //   if (documentTypeOption) {
    //     file.accepted = true;
    //     file.documentTypeName = documentTypeOption.photoTypeName;
    //     file.documentTypeSeq = documentTypeOption.photoTypeSeq;
    //   }
    //else {
    //     const isValidDocumentType = [
    //       'aut',
    //       'vie',
    //       'ToxReq',
    //       'ToxRpt',
    //       'MRF',
    //       'AF',
    //     ].includes(documentType?.split('.')[0]);

    //     if (isValidDocumentType) {
    //       file.accepted = true;
    //       const documentTypeName = getDocumentTypeName(documentType);
    //       file.documentTypeName = documentTypeName;
    //       file.documentTypeSeq = allPhotoType.find(
    //         (documentType) => documentType.photoTypeName === documentTypeName
    //       )?.photoTypeSeq;
    //     } else {
    //       file.rejectReason =
    //         'File was not processed due to Document Type not found.';
    //       file.accepted = false;
    //       newRejectedFiles.push(file);
    //     }
    //   }
    // });

    // if (caseSeq) {
    //   acceptedFiles.forEach((file) => {
    //     file.caseNumber = caseNumber;
    //     file.caseSeq = caseSeq;
    //   });
    // }
    //else {
    //   // Perform validation on the CaseId
    //   const validatedCaseIds = await validateCaseId(caseIds);

    //   if (validatedCaseIds.length > 0) {
    //     for (const file of acceptedFiles.filter((file) => file.accepted)) {
    //       const caseId = file.name.split('_')[0];
    //       const matchedCase = validatedCaseIds.find(
    //         (caseData) => caseData.caseId === caseId
    //       );
    //       if (matchedCase) {
    //         file.accepted = true;
    //         file.caseNumber = matchedCase.caseId;
    //         file.caseSeq = matchedCase.caseSeq;
    //       } else {
    //         file.accepted = false;
    //         file.rejectReason =
    //           'File was not processed due to CASEID not found.';
    //         newRejectedFiles.push(file);
    //       }
    //     }
    //   }
    // }
    setAcceptedFiles(acceptedFiles);
    setRejectedFiles(rejectedFiles);

    // if (newRejectedFiles.length > 0) {
    //   setShowErrorAlert(true);
    // }
  };

  const handleFileSave = async (file) => {
      const caseNo = caseNumber.replace(/-/g, '\\');
      const modifiedCaseNo = caseNo.substring(0, caseNo.length - 2) + '\\' + caseNo.substring(caseNo.length - 2);
  
      const formDataUploadFile = new FormData();
      formDataUploadFile.append(`qryParams[${0}].CaseSeq`, caseSeq);
      formDataUploadFile.append(
        `qryParams[${0}].PhotoTypeSeq`,
        documentTypeOption.photoTypeSeq
      );
      formDataUploadFile.append(
        `qryParams[${0}].EntityName`,
        `Cases\\${modifiedCaseNo}\\Records`
      );
      formDataUploadFile.append(`qryParams[${0}].OriginalFileName`, file.name);
      formDataUploadFile.append(`qryParams[${0}].UserSeq`, userSeq);
      formDataUploadFile.append(`qryParams[${0}].Photo`, file);

      file.status = 'Uploading';

      const retryUpload = async (retries = 2, delay = 2000) => {
        while (retries > 0) {
            try {
                const response = await fetch(REACT_APP_API_URL + 'casephotos/upload', {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + user.token,
                    },
                    body: formDataUploadFile,
                });

                if (!response.ok) {
                    throw new Error(`Upload failed for file: ${file.name}`);
                }

                return await response.json();
            } catch (error) {
                retries--;
                if (retries <= 0) {
                    throw error; 
                }
                console.warn(`Retrying upload for file: ${file.name}, attempts left: ${retries}`);
                await new Promise(resolve => setTimeout(resolve, delay)); 
                delay *= 2; 
            }
        }
    };

    try {
        const result = await retryUpload();
        return result;
    } catch (error) {
        console.error('Error occurred during file upload:', error);
        file.status = 'Failure';
        throw error;
    }
  };
  
  const handleFilesSave = async () => {
    if (uploadInProgress || acceptedFiles.length === 0) {
        return;
    }

    setUploadInProgress(true);
    let hasFailure = false;

    try {
    // Start all uploads simultaneously
    const uploadPromises = acceptedFiles.map(handleFileSave);

     // Use Promise.all to wait for all promises to settle
     await Promise.all(uploadPromises.map(async (uploadPromise, index) => {
      try {
          // Wait for each individual upload to complete
          const response = await uploadPromise;

          // Update the status of the corresponding file based on the response
          const file = acceptedFiles[index];
          if (response.data[0].status === 'Success') {
              file.status = 'Success';
          } else {
              file.status = 'Failure';
              hasFailure = true;
          }

          setAcceptedFiles(prevAcceptedFiles => {
            const updatedFiles = [...prevAcceptedFiles];
            updatedFiles[index] = file;
            return updatedFiles;
        });
      } catch (error) {
          console.error('Error occurred during file upload:', error);
          const file = acceptedFiles[index];
          file.status = 'Failure';
          hasFailure = true;
          setAcceptedFiles(prevAcceptedFiles => {
            const updatedFiles = [...prevAcceptedFiles];
            updatedFiles[index] = file;
            return updatedFiles;
        });
      }
  }));
  } catch (error) {
        console.error('Error occurred during file upload:', error);
    } finally {
      if (!hasFailure) {
        setIsOpen(false);
        setAcceptedFiles([]);
    }
      setUploadInProgress(false);
      if (acceptedFiles.length > 0) {
        onSuccessUpload();
      }
    }
};



  const handleDocumentTypeChange = (event, data) => {
    setDocumentTypeOption(data);
  };

  const getPhotoTypes = async () => {
    await fetch(REACT_APP_API_URL + 'getphototypes', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAllPhotoType(data);
      })
      .catch(e => {});
  };

  let filteredPhotoTypes = [];
  if (moduleName === Module_Name.Medical_Imaging) {
    filteredPhotoTypes = allPhotoType.filter(photoType =>
      Photo_Types.Medical_Imaging.includes(photoType.photoTypeSeq.toUpperCase())
    );
  } else if (moduleName === Module_Name.Scene) {
    filteredPhotoTypes = allPhotoType.filter(photoType =>
      Photo_Types.Scene.includes(photoType.photoTypeSeq.toUpperCase())
    );
  } else if (moduleName === Module_Name.Autopsy_EE) {
    filteredPhotoTypes = allPhotoType.filter(photoType =>
      Photo_Types.Autopsy_EE.includes(photoType.photoTypeSeq.toUpperCase())
    );
  } else {
    const excludedPhotoTypes = new Set([
      ...Photo_Types.Medical_Imaging,
      ...Photo_Types.Scene,
      ...Photo_Types.Autopsy_EE,
    ]);
    filteredPhotoTypes = allPhotoType.filter(
      photoType => !excludedPhotoTypes.has(photoType.photoTypeSeq.toUpperCase())
    );
  }

  useEffect(() => {
    getPhotoTypes();
    setRejectedFiles([]);
    setAcceptedFiles([]);
    setUploadInProgress(false);
  }, []);

  useEffect(() => {
    if (moduleName === Module_Name.Scene && filteredPhotoTypes?.length === 1) {
      setDocumentTypeOption(filteredPhotoTypes[0]);
    } else {
      setDocumentTypeOption(null);
    }
  }, [moduleName]);

  var { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png, image/gif', // Accept only image file types
    maxFiles: 200,
    maxSize: 2000000000,
    onDrop: (acceptedFiles, event) => {
      if (documentTypeRequired && !documentTypeOption) {
        // Prevent the drop if document type is required and not selected
        event.preventDefault();
        return;
      }
      handleDrop(acceptedFiles);
    },
  });

  const handleDelete = (fileObject) => {
    const updatedAcceptedFiles = acceptedFiles.filter(
        (file) => file.path !== fileObject.path
    );
    setAcceptedFiles(updatedAcceptedFiles);
};

  const renderIcon = (fileObject) => {
    if (fileObject?.status === 'Uploading') {
      return <CircularProgress size={24} color="secondary" />;
    } else if (fileObject?.status === 'Success') {
        return <CheckCircleIcon size={20} color="primary" />;
    } else if (fileObject?.status === 'Failure') {
        return <ErrorIcon color="error" />;
    } else {
        return <DeleteIcon />;
    }
};

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '60%',
            maxWidth: '80%',
            margin: '0 auto',
          },
        }}
      >
        <DialogTitle>{dialogTitle()}</DialogTitle>
        <DialogContent>
          <Grid style={{ margin: '1rem 0 1rem 0' }}>
            <Autocomplete
              id='photoType'
              size='small'
              options={filteredPhotoTypes}
              value={documentTypeOption}
              getOptionLabel={option => option.photoTypeName}
              onChange={(event, data) => handleDocumentTypeChange(event, data)}
              fullWidth
              sx={{ maxWidth: '25rem' }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>{option.photoTypeName}</li>
              )}
              renderInput={params => (
                <TextField {...params} label='Photo Type' placeholder='Photo Type' />
              )}
            />
            {documentTypeRequired && !documentTypeOption && (
              <Typography variant='body2' color='error' style={{ marginTop: 8 }}>
                Please select a photo type.
              </Typography>
            )}
          </Grid>
          <Box {...getRootProps({ style: { ...style, minHeight: '80px' } })}>
            <Box sx={{ display: 'block', textAlign: 'center' }}>
              <DriveFolderUploadIcon
                fontSize='large'
                sx={{ color: '#aaa', height: 50, width: 50, marginBottom: '1rem' }}
              />
              <input {...getInputProps()} />
              <Typography variant='h6' sx={{ color: '#aaa' }}>
                Drag & drop photos here, or click to select photos
              </Typography>
              <Typography variant='subtitle' color='gray'>
                (Only *.jpeg, *.jpg, *.png images will be accepted)
              </Typography>
              {uploadInProgress && (
                <Typography variant='body2' sx={{ marginTop: 2, color: '#C2750A' }}>
                  Uploading photos. This might take a while. Please be patient!
                </Typography>
              )}
            </Box>
          </Box>

          {acceptedFiles?.length > 0 ? (
            <>
              <Grid>
                <List
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    margin: '0.5rem',
                  }}
                >
                  {acceptedFiles?.map((fileObject, index) => (
                    <Card
                      key={fileObject.path}
                      style={{
                        margin: '0.5rem',
                        width: '200px',
                      }}
                    >
                      <CardContent
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          position: 'relative',
                        }}
                      >
                        {handlePreviewIcon(fileObject)}
                        <span
                          style={{
                            marginTop: '0.5rem',
                            wordBreak: 'break-all',
                            textAlign: 'center',
                          }}
                        >
                          {fileObject.path}
                        </span>

                        <IconButton
                          style={{ position: 'absolute', top: 0, right: 0 }}
                          onClick={() =>
                            fileObject?.status === 'Pending' && handleDelete(fileObject)
                          }
                          color={fileObject?.status === 'Failure' ? 'error' : 'secondary'}
                        >
                          {renderIcon(fileObject)}
                        </IconButton>
                      </CardContent>
                    </Card>
                  ))}
                </List>
              </Grid>
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleFilesSave(acceptedFiles)}
            autoFocus
            disabled={acceptedFiles && acceptedFiles?.length === 0}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {uploadInProgress ? <CircularProgress size={20} /> : 'Upload'}
          </Button>
          <Button onClick={handleClose} color='error'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
