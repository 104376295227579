import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slide,
  Slider,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { ActionList } from '../components/CaseView/CaseViewDateTimeField';
import { useAuth } from '../utils/auth/AuthService';
import AddressField from '../utils/components/AddressFieldWithSearch';
import ReportDataGrid from '../utils/components/ReportDataGrid/ReportDataGrid';
import { DateRange } from '@mui/x-date-pickers-pro/models';

export default function CaseSearch() {
  // Options for Autocomplete fields
  const [authorizedJurisdictionOptions, setAuthorizedJurisdictionOptions] = useState([]);
  const [meActionOptions, setMeActionOptions] = useState([]);
  const [mannerOfDeathOptions, setMannerOfDeathOptions] = useState([]);
  const [pathologistOptions, setPathologistOptions] = useState([]);
  const [investigatorOptions, setInvestigatorOptions] = useState([]);

  // Case results
  const [caseresults, setCaseResults] = useState([]);

  // Utility variables
  const [loading, setLoading] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [paramsVisible, setParamsVisible] = useState(true);
  const [dateRangeAlertVisible, setDateRangeAlertVisible] = useState(false);
  const [jdxAlertVisible, setJdxAlertVisible] = useState(false);

  // Theme, auth and env variables
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;

  // Create formik object here
  const formik = useFormik({
    initialValues: {
      reverseOrder: false,
      jurisdictions: [],
      reportedDateRange: [null, null],
      caseYear: null,
      decedentLastName: '',
      decedentFirstName: '',
      pathologists: [],
      investigators: [],
      dateOfBirth: null,
      pronouncementDateRange: [null, null],
      mannerOfDeaths: [],
      causeOfDeath: '',
      drugToxicitySuspected: false,
      drugToxicityConfirmed: false,
      meActions: [],
      includeVoidCases: false,
      caseStatus: 'All',
      ageRange: [0, 150],
      decedentResidenceAddress: {
        addressText: null,
        data: {
          addressLine1: null,
          addressLine2: null,
          address: null,
          aptSuite: null,
          floor: null,
          cityTown: null,
          state: null,
          zipCode: null,
          country: null,
        },
      },
      bodyLocationAddress: {
        addressText: null,
        data: {
          addressLine1: null,
          addressLine2: null,
          address: null,
          aptSuite: null,
          floor: null,
          cityTown: null,
          state: null,
          zipCode: null,
          country: null,
        },
      },
    },
    enableReinitialize: true,
    onSubmit: () => {
      handleSubmit();
    },
  });

  const ageMarks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 100,
      label: '100',
    },
    {
      value: 150,
      label: 'Any',
    },
  ];

  const shortcutsItems = [
    {
      label: 'This Week',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('week'), today.endOf('week')];
      },
    },
    {
      label: 'Last Week',
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, 'day');
        return [prevWeek.startOf('week'), prevWeek.endOf('week')];
      },
    },
    {
      label: 'Last 7 Days',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, 'day'), today];
      },
    },
    {
      label: 'Current Month',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today.endOf('month')];
      },
    },
    {
      label: 'Last Month',
      getValue: () => {
        const today = dayjs();
        const endOfLastMonth = today.startOf('month').subtract(1, 'day');
        return [endOfLastMonth.startOf('month'), endOfLastMonth];
      },
    },
    {
      label: 'Last 30 Days',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(30, 'day'), today];
      },
    },
    { label: 'Reset', getValue: () => [null, null] },
  ];

  const handleCaseClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  ) => {
    event.stopPropagation();
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const AgeRangeContent = () => {
    if (formik.values?.ageRange[0] == 0 && formik.values?.ageRange[1] == 150) {
      return <Typography>Age (range): Any</Typography>;
    } else if (formik.values?.ageRange[1] == 150) {
      return <Typography>Age (range): {formik.values?.ageRange[0]} and up</Typography>;
    } else if (formik.values?.ageRange[0] == formik.values?.ageRange[1]) {
      return <Typography>Age (range): {formik.values?.ageRange[0]}</Typography>;
    } else {
      return (
        <Typography>
          Age (range): {formik.values?.ageRange[0]} - {formik.values?.ageRange[1]}
        </Typography>
      );
    }
  };

  const ButtonOneContent = () => {
    if (paramsVisible) {
      return (
        <Button variant='contained' color='secondary' onClick={() => formik.handleSubmit()}>
          Search Cases
        </Button>
      );
    } else {
      return (
        <Button variant='contained' color='secondary' onClick={handleNewSearch}>
          New Search
        </Button>
      );
    }
  };

  const ButtonTwoContent = () => {
    if (paramsVisible) {
      return (
        <Button color='secondary' variant='outlined' onClick={() => formik.resetForm()}>
          Clear All
        </Button>
      );
    } else {
      return (
        <Button color='secondary' variant='outlined' onClick={handleReturnToSearch}>
          Return To Search
        </Button>
      );
    }
  };

  // Initialize options
  useEffect(() => {
    fetch(REACT_APP_API_URL + 'getmeactionoptions', {
      method: 'GET',
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setMeActionOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    fetch(REACT_APP_API_URL + 'getmannerofdeathoptions', {
      method: 'GET',
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setMannerOfDeathOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  }, []);

  // Initialize user specific options
  useEffect(() => {
    if (user === null) return;

    setAuthorizedJurisdictionOptions(user.jdxAccessList);

    // fetch(REACT_APP_API_URL + 'user/getmephysicians', {
    //   method: 'GET',
    // })
    //   .then(res => {
    //     return res.json();
    //   })
    //   .then(data => {
    //     setPathologistOptions(data);
    //   })
    //   .catch(e => {
    //     //alert(e);
    //   });

    // fetch(REACT_APP_API_URL + 'user/getinvestigators', {
    //   method: 'GET',
    // })
    //   .then(res => {
    //     return res.json();
    //   })
    //   .then(data => {
    //     setInvestigatorOptions(data);
    //   })
    //   .catch(e => {
    //     //alert(e);
    //   });
  }, [user]);

  useEffect(() => {
    if ((formik.values?.jurisdictions?.length ?? 0) > 0) {
      console.log(formik.values?.jurisdictions);

      const queryParams = new URLSearchParams();
      const jdxSeqList = formik.values.jurisdictions.map(x => x.jdxSeq).join(','); // Join the array into a single string
      queryParams.append('jdxList', jdxSeqList); // Append the single string

      fetch(`${REACT_APP_API_URL}get-pathologist-options?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          console.log(data);
          setPathologistOptions(data);
        })
        .catch(e => {
          alert(e);
        });

      fetch(`${REACT_APP_API_URL}get-investigator-options?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          console.log(data);
          setInvestigatorOptions(data);
        })
        .catch(e => {
          alert(e);
        });
    }
  }, [user, formik.values?.jurisdictions]);

  const columns = [
    {
      field: 'caseID',
      headerName: 'Case ID',
      width: 155,
      editable: false,
      renderCell: params => {
        return (
          <Button
            color='secondary'
            variant='text'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            <b>{params.formattedValue}</b>
          </Button>
        );
      },
    },
    {
      field: 'decedentName',
      headerName: 'Decedent Name',
      width: 200,
      editable: false,
    },
    {
      field: 'jdxName',
      headerName: 'Jurisdiction',
      width: 100,
      editable: false,
    },
    {
      field: 'meAction',
      headerName: 'ME Action',
      width: 150,
      editable: false,
    },
    {
      field: 'callTaken',
      headerName: 'Reported',
      type: 'dateTime',
      valueGetter: params => {
        return params.value != null ? new Date(params.value) : null;
      },
      valueFormatter: params => {
        return params.value ? format(new Date(params.value), 'MM/dd/yyyy, HH:mm') : null;
      },
      width: 200,
      editable: false,
    },
  ];

  const handleYearChange = (event, data) => {
    formik.setFieldValue('caseYear', data);
  };

  const handleAgeRangeChange = (event, data) => {
    formik.setFieldValue('ageRange', data);
  };

  const handlePathologistChange = (event, data) => {
    formik.setFieldValue('pathologists', data);
  };

  const handleInvestigatorChange = (event, data) => {
    formik.setFieldValue('investigators', data);
  };

  const handleJurisdictionChange = (event, data) => {
    formik.setFieldValue('jurisdictions', data);
  };

  const handleSelectAndDeselectAllJurisdictions = () => {
    if (
      JSON.stringify(formik.values?.jurisdictions) == JSON.stringify(authorizedJurisdictionOptions)
    ) {
      formik.setFieldValue('jurisdictions', []);
    } else {
      formik.setFieldValue('jurisdictions', authorizedJurisdictionOptions);
    }
  };

  const handleMODChange = (event, data) => {
    formik.setFieldValue('mannerOfDeaths', data);
  };

  const handleMEActionChange = (event, data) => {
    formik.setFieldValue('meActions', data);
  };

  const handleIncludeVoidChange = event => {
    formik.setFieldValue('includeVoidCases', event.target.checked);
  };

  const handleReverseOrderChange = event => {
    formik.setFieldValue('reverseOrder', !event.target.checked);
  };

  const handleNewSearch = () => {
    formik.resetForm();
    setParamsVisible(true);
    setResultsVisible(false);
  };

  const handleReturnToSearch = () => {
    setParamsVisible(true);
  };

  const dismissDateAlert = () => {
    setDateRangeAlertVisible(false);
  };

  const dismissJdxAlert = () => {
    setJdxAlertVisible(false);
  };

  //gather search params from form and FETCH/POST to API
  const handleSubmit = async () => {
    if (user === null) return;

    let formData = new FormData();
    var fieldRequired = false;

    if (formik.values?.jurisdictions.length == 0) {
      setJdxAlertVisible(true);
      fieldRequired = true;
    } else if (
      formik.values?.caseYear == null &&
      formik.values?.reportedDateRange.every(elem => elem === null)
    ) {
      setDateRangeAlertVisible(true);
      fieldRequired = true;
    } else if (
      formik.values?.reportedDateRange.every(elem => elem !== null) &&
      formik.values?.reportedDateRange[1].diff(formik.values?.reportedDateRange[0], 'year', true) >
        1
    ) {
      setDateRangeAlertVisible(true);
      fieldRequired = true;
    }

    if (fieldRequired) return;

    formData.append('qryParams', JSON.stringify(formik.values));

    setResultsVisible(true);
    setLoading(true);
    setParamsVisible(false);
    setDateRangeAlertVisible(false);
    setJdxAlertVisible(false);

    if (user && user.token) {
      fetch(process.env.REACT_APP_API_URL + 'SearchCases', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setCaseResults(data);
          setLoading(false);
        })
        .catch(e => {
          alert(e);
          setParamsVisible(true);
        });
    }
  };

  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '75%' },
      }}
    >
      <CardHeader
        title='Case Search'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.secondary.main,
        }}
      />

      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

      <CardContent sx={{ py: 0, mb: 2 }}>
        <Slide direction='right' in={dateRangeAlertVisible} mountOnEnter unmountOnExit>
          <Alert severity='warning' onClose={dismissDateAlert}>
            Reported range must be within 1 year or year dropdown must be set.
          </Alert>
        </Slide>

        <Slide direction='right' in={jdxAlertVisible} mountOnEnter unmountOnExit>
          <Alert severity='warning' onClose={dismissJdxAlert}>
            Please select at least one jurisdiction.
          </Alert>
        </Slide>
      </CardContent>

      <Collapse in={paramsVisible}>
        <FormikProvider value={formik}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid xs={12} sm={6} md={3}>
                <Autocomplete
                  multiple
                  id='jurisdictions'
                  // name='jurisdictions'
                  options={authorizedJurisdictionOptions.sort((a, b) =>
                    a.jdxName.localeCompare(b.jdxName)
                  )}
                  value={formik.values?.jurisdictions}
                  disableCloseOnSelect
                  limitTags={4}
                  size='small'
                  getOptionLabel={option => option.jdxName}
                  onChange={(event, data) => handleJurisdictionChange(event, data)}
                  renderOption={(props, option, { selected }) => {
                    if (
                      option.jdxName ==
                      authorizedJurisdictionOptions.sort((a, b) =>
                        a.jdxName.localeCompare(b.jdxName)
                      )[0].jdxName
                    ) {
                      return (
                        <Stack>
                          <Button
                            sx={{
                              backgroundColor: theme.palette.mode === 'dark' ? '#444' : '#cccccc',
                              color: theme.palette.text.primary,
                              p: 1,
                              m: 0.5,
                              boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.2)',
                            }}
                            onClick={handleSelectAndDeselectAllJurisdictions}
                          >
                            {JSON.stringify(formik.values?.jurisdictions) ==
                            JSON.stringify(authorizedJurisdictionOptions) ? (
                              <Typography variant='button'>Deselect All</Typography>
                            ) : (
                              <Typography variant='button'>Select All</Typography>
                            )}
                          </Button>
                          <li {...props}>
                            <Checkbox
                              icon={<MdCheckBoxOutlineBlank />}
                              checkedIcon={<MdOutlineCheckBox />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.jdxName}
                          </li>
                        </Stack>
                      );
                    }
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={<MdCheckBoxOutlineBlank />}
                          checkedIcon={<MdOutlineCheckBox />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.jdxName}
                      </li>
                    );
                  }}
                  fullWidth
                  renderInput={params => (
                    <TextField {...params} label='Jurisdictions' placeholder='Jurisdictions' />
                  )}
                  // ListboxComponent={'ul'}
                />
              </Grid>

              <Grid xs={12} sm={6} md={3}>
                <Stack direction='row' spacing={2} alignItems='center'>
                  <Autocomplete
                    id='caseYear'
                    // name='caseYear'
                    options={Array.from(
                      { length: 13 },
                      (_, index) => new Date().getFullYear() - index
                    )}
                    disabled={!formik.values?.reportedDateRange.every(elem => elem === null)}
                    value={formik.values?.caseYear}
                    size='small'
                    getOptionLabel={option => option.toString()}
                    onChange={(event, data) => handleYearChange(event, data)}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<MdCheckBoxOutlineBlank />}
                          checkedIcon={<MdOutlineCheckBox />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    fullWidth
                    renderInput={params => (
                      <TextField {...params} label='Case Year' placeholder='Case Year' />
                    )}
                  />
                  <Typography>OR</Typography>
                </Stack>
              </Grid>

              <Grid xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateRangePicker
                    // id='reportedDateRange'
                    name='reportedDateRange'
                    value={formik.values?.reportedDateRange as DateRange<any>}
                    onChange={data => formik.setFieldValue('reportedDateRange', data)}
                    localeText={{ start: 'Reported From', end: 'Reported To' }}
                    disabled={formik.values?.caseYear}
                    slotProps={{
                      shortcuts: {
                        items: shortcutsItems,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </CardContent>

          <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

          <CardContent>
            <Grid container spacing={2} alignItems='center' columns={15}>
              <Grid xs={15} md={3}>
                <TextField
                  id='decedentLastName'
                  name='decedentLastName'
                  label='Decedent Last Name'
                  value={formik.values?.decedentLastName}
                  type='text'
                  fullWidth
                  size='small'
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid xs={15} md={3}>
                <TextField
                  id='decedentFirstName'
                  name='decedentFirstName'
                  label='Decedent First Name'
                  value={formik.values?.decedentFirstName}
                  type='text'
                  fullWidth
                  size='small'
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid xs={15} md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // id='dateOfBirth'
                    name='dateOfBirth'
                    value={formik.values?.dateOfBirth}
                    onChange={data => formik.setFieldValue('dateOfBirth', data)}
                    label='Date of Birth'
                    slots={{
                      actionBar: ActionList,
                    }}
                    sx={{ width: '100%' }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid xs={15} md={6}>
                {/* <TextField
                id='dateOfDeath'
                name='dateOfDeath'
                label='Date of Death'
                value={formik.values?.dateOfDeath}
                size='small'
                fullWidth
                type='date'
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateRangePicker
                    // id='pronouncementDateRange'
                    name='pronouncementDateRange'
                    value={formik.values?.pronouncementDateRange as DateRange<any>}
                    onChange={data => formik.setFieldValue('pronouncementDateRange', data)}
                    localeText={{ start: 'Pronounced From', end: 'Pronounced To' }}
                    slotProps={{
                      shortcuts: {
                        items: shortcutsItems,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid xs={115} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Stack sx={{ mx: 2, width: '100%' }}>
                  <AgeRangeContent />
                  <Slider
                    id='ageRange'
                    name='ageRange'
                    value={formik.values?.ageRange}
                    onChange={handleAgeRangeChange}
                    valueLabelDisplay='auto'
                    color='secondary'
                    step={1}
                    marks={ageMarks}
                    min={0}
                    max={150}
                  />
                </Stack>
              </Grid>

              <Grid xs={15} md={9}>
                <AddressField
                  placeholder='Decedent Residence Address'
                  fieldName='decedentResidenceAddress'
                />
              </Grid>
            </Grid>
          </CardContent>

          <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

          <CardContent>
            <Grid container spacing={2}>
              <Grid xs={12} md={3}>
                <Autocomplete
                  multiple
                  id='pathologists'
                  // name='pathologists'
                  size='small'
                  fullWidth
                  options={pathologistOptions}
                  value={formik.values?.pathologists}
                  disableCloseOnSelect
                  getOptionLabel={option => option.optionName}
                  onChange={(event, data) => handlePathologistChange(event, data)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank />}
                        checkedIcon={<MdOutlineCheckBox />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.optionName}
                    </li>
                  )}
                  renderInput={params => (
                    <TextField {...params} label='Pathologist' placeholder='Pathologist' />
                  )}
                />
              </Grid>

              <Grid xs={12} md={3}>
                <Autocomplete
                  multiple
                  id='investigators'
                  // name='investigators'
                  size='small'
                  fullWidth
                  options={investigatorOptions}
                  value={formik.values?.investigators}
                  disableCloseOnSelect
                  getOptionLabel={option => option.optionName}
                  onChange={(event, data) => handleInvestigatorChange(event, data)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank />}
                        checkedIcon={<MdOutlineCheckBox />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.optionName}
                    </li>
                  )}
                  renderInput={params => <TextField {...params} label='MLDI' placeholder='MLDI' />}
                />
              </Grid>

              <Grid xs={12} md={3}>
                <Autocomplete
                  multiple
                  id='meActions'
                  // name='meActions'
                  size='small'
                  options={meActionOptions.filter(o => o.isActive !== false)}
                  fullWidth
                  value={formik.values?.meActions}
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) =>
                    option.optionSeq.toUpperCase() === value.optionSeq.toUpperCase()
                  }
                  getOptionLabel={option => option.optionName}
                  onChange={(event, data) => handleMEActionChange(event, data)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank />}
                        checkedIcon={<MdOutlineCheckBox />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.optionName}
                    </li>
                  )}
                  renderInput={params => (
                    <TextField {...params} label='ME Action' placeholder='ME Action' />
                  )}
                />
              </Grid>

              <Grid xs={12} md={3}>
                <FormControl sx={{ width: '100%' }} size='small'>
                  <InputLabel id='caseStatusSelectLabel'>Case Status</InputLabel>
                  <Select
                    labelId='caseStatusSelectLabel'
                    id='caseStatus'
                    name='caseStatus'
                    value={formik.values?.caseStatus}
                    label='Case Status'
                    onChange={formik.handleChange}
                  >
                    <MenuItem value='All'>All</MenuItem>
                    <MenuItem value='O'>Open</MenuItem>
                    <MenuItem value='C'>Closed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} md={3}>
                <TextField
                  id='causeOfDeath'
                  name='causeOfDeath'
                  label='Cause of Death'
                  fullWidth
                  value={formik.values?.causeOfDeath}
                  type='text'
                  size='small'
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid xs={12} md={3}>
                <Autocomplete
                  multiple
                  id='mannerOfDeaths'
                  // name='mannerOfDeaths'
                  options={mannerOfDeathOptions}
                  value={formik.values?.mannerOfDeaths}
                  disableCloseOnSelect
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.optionSeq.toUpperCase() === value.optionSeq.toUpperCase()
                  }
                  size='small'
                  getOptionLabel={option => option.optionName}
                  onChange={(event, data) => handleMODChange(event, data)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank />}
                        checkedIcon={<MdOutlineCheckBox />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.optionName}
                    </li>
                  )}
                  renderInput={params => (
                    <TextField {...params} label='Manner of Death' placeholder='Manner of Death' />
                  )}
                />
              </Grid>

              <Grid xs={12} md={6}>
                <AddressField
                  placeholder='Decedent Body Location Address'
                  fieldName='bodyLocationAddress'
                />
              </Grid>

              <Grid xs='auto'>
                <FormControlLabel
                  label='Drug Toxicity Suspected'
                  control={
                    <Checkbox
                      id='drugToxicitySuspected'
                      name='drugToxicitySuspected'
                      checked={formik.values?.drugToxicitySuspected}
                      color='secondary'
                      onChange={event =>
                        formik.setFieldValue('drugToxicitySuspected', event.target.checked)
                      }
                    />
                  }
                />
              </Grid>

              <Grid xs='auto'>
                <FormControlLabel
                  label='Drug Toxicity Confirmed'
                  control={
                    <Checkbox
                      id='drugToxicityConfirmed'
                      name='drugToxicityConfirmed'
                      checked={formik.values?.drugToxicityConfirmed}
                      color='secondary'
                      onChange={event =>
                        formik.setFieldValue('drugToxicityConfirmed', event.target.checked)
                      }
                    />
                  }
                />
              </Grid>

              <Grid xs='auto'>
                <FormControlLabel
                  label='Display Most Recent Cases First'
                  control={
                    <Checkbox
                      id='reverseOrder'
                      name='reverseOrder'
                      checked={!formik.values?.reverseOrder}
                      color='secondary'
                      onChange={handleReverseOrderChange}
                    />
                  }
                />
              </Grid>

              <Grid xs='auto'>
                <FormControlLabel
                  label='Include Void Cases'
                  control={
                    <Checkbox
                      id='includeVoidCases'
                      name='includeVoidCases'
                      checked={formik.values?.includeVoidCases}
                      color='secondary'
                      onChange={handleIncludeVoidChange}
                    />
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </FormikProvider>
      </Collapse>

      <Collapse in={resultsVisible}>
        <CardContent>
          <ReportDataGrid
            loading={loading}
            listresults={caseresults}
            columnsInput={columns}
            rowsperpage={8}
            title='Case Results'
            idcolumn='caseID'
            headerAlignment='left'
            columnAlignment='left'
            casecount
            chatcontent={
              <Paper
                elevation={3}
                sx={{
                  p: '10px',
                  px: '20px',
                  borderRadius: '20px',
                  width: 'max-content',
                }}
              >
                <Typography style={{ textAlign: 'center' }}>
                  I've listed the detailed case records below, you can export the list to csv
                  format.
                </Typography>
              </Paper>
            }
          />
        </CardContent>
      </Collapse>

      <CardContent>
        <Divider sx={{ my: 2, opacity: 1 }} />

        <Stack
          direction='row'
          sx={{
            width: '100%',
            justifyContent: 'right',
          }}
          spacing={2}
        >
          <ButtonOneContent />
          <ButtonTwoContent />
        </Stack>
      </CardContent>
    </Card>
  );
}
