import { Box, Grid, SxProps, TextField, TextFieldProps, Theme } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useAuth } from '../auth/AuthService';
import { AllRoles } from '../constants/AllRoles';

interface FormikTextFieldProps extends Omit<TextFieldProps, 'name' | 'error'> {
  label: React.ReactNode;
  formikField: string;
  authorizedToEdit: (allRoles: typeof AllRoles) => string[] | boolean;
  disabled?: boolean;
  value?: string;
  gridProps?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    sx?: SxProps<Theme>;
  };
  onTextChange?: (
    value: string | null,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const FormikTextField: React.FC<FormikTextFieldProps> = ({
  label,
  formikField,
  authorizedToEdit,
  id = formikField,
  onTextChange,
  gridProps = {},
  disabled = false,
  InputProps,
  ...props
}) => {
  const { user } = useAuth();
  const formik = useFormikContext<any>();

  const userCanEdit = useMemo(() => {
    if (typeof authorizedToEdit === 'function') {
      const roles = authorizedToEdit(AllRoles) as string[];
      return user?.roleCheck(roles) || false;
    }

    if (typeof authorizedToEdit === 'boolean') {
      return authorizedToEdit;
    }

    return false;
  }, [user, authorizedToEdit]);

  const { value } = formik.getFieldProps(formikField);
  const [state, setState] = useState(value);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setState(event.target.value);
    },
    []
  );

  const handleBlur = useCallback(() => {
    formik.setFieldTouched(formikField, true);
    formik.setFieldValue(formikField, state);
  }, [formik, formikField, state]);

  return (
    <Grid item {...gridProps}>
      <Box
        component='div'
        sx={{
          position: 'relative',
          display: 'inline-block',
          width: '100%',
        }}
      >
        <TextField
          fullWidth
          disabled={!formik.status.editing || !userCanEdit || disabled}
          id={id}
          label={label}
          value={state ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          InputProps={InputProps}
          {...props}
        />
        {(!formik?.status?.editing || !authorizedToEdit) && (
          <Box
            onClick={() =>
              formik.setStatus({
                ...formik.status,
                disabledClickCount: formik.status?.disabledClickCount + 1,
              })
            }
            component='div'
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              cursor: 'pointer',
            }}
          />
        )}
      </Box>
    </Grid>
  );
};
