import { Option } from '../../Case/CaseViewModules/MortuaryModules/XrayRequestForm/xrayrequestform.constants';
const { REACT_APP_API_URL } = process.env;

const getLiveryCasesForUser = async (
  userSeq: string,
  accessToken: string
): Promise<LiveryCase[]> => {
  try {
    const response = await fetch(
      REACT_APP_API_URL + `livery/getliverycasesforuser?userSeq=${userSeq}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return [];
  } finally {
  }
};

interface UpdateLiveryStatusForCaseProps {
  accessToken: string;
  caseSeq: string;
  statusSeq: LegacyLiveryStatus;
  userSeq: string;
  geocode?: {
    latitude: number;
    longitude: number;
  };
}
const updateLiveryStatusForCase = async ({
  accessToken,
  caseSeq,
  statusSeq,
  userSeq,
  geocode,
}: UpdateLiveryStatusForCaseProps) => {
  try {
    const response = await fetch(REACT_APP_API_URL + `liverystatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      body: JSON.stringify({
        caseSeq,
        statusSeq, // GUID value of Livery Activity Seq,
        userSeq,
        geocode,
      }),
    });
    const data = await response.json();
    // Get the response status and message.
    // Code for next action
    return data;
  } catch (error) {
    console.error(error);
  } finally {
    // Action
  }
};

export interface TransportationLogRecord {
  caseActivityLogSeq?: string;
  description?: Option;
  timeStamp?: Date;
  loggedByUser?: string;
}

const getTransportionLogForCaseSeq = async (
  caseSeq: string,
  accessToken: string
): Promise<LiveryCase | null> => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}livery/getcase?caseSeq=${caseSeq}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data: LiveryCase = await response.json();

    if (data) {
      data.activityLog = [...data.activityLog].sort((a, b) => {
        const timeA = a.createdOn ? new Date(a.createdOn).getTime() : 0;
        const timeB = b.createdOn ? new Date(b.createdOn).getTime() : 0;
        return timeB - timeA;
      });
    }

    return data as LiveryCase;
  } catch (error) {
    console.error('There was a problem with the fetch operation: ', error);
    return null;
  }
};

const getLiveryLog = async (
  caseSeq: string,
  accessToken: string
): Promise<LegacyCaseActivityLogRecord[]> => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}livery/getliverylog?caseSeq=${caseSeq}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data: LegacyCaseActivityLogRecord[] = await response.json();
    return data;
  } catch (error) {
    console.error('There was a problem with the fetch operation: ', error);
    return null;
  }
};

export interface CaseAddressUpdated {
  addressTypeSeq?: string;
  addressLine1?: string;
  addressLine2?: string;
  address?: string;
  aptSuite?: string;
  floor?: string;
  cityTown?: string;
  state?: Option;
  zipCode?: string;
  country?: Option;
  latitude: number;
  longitude: number;
}

async function getAddress(addressSeq: string, accessToken: string) {
  try {
    const response = await fetch(`${REACT_APP_API_URL}livery/getaddress?addressSeq=${addressSeq}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }

    const data = await response.json();
    return data as CaseAddressUpdated;
  } catch (error) {
    console.error('There was a problem with the fetch operation: ', error);
    throw error;
  }
}

export interface UpdateLiveryCaseRequest {
  caseSeq: string;
  latitude?: number;
  longitude?: number;
  recoverySealNumber?: string;
  latestStatusSeq: string;
  latestSatusOtherSeq?: string;
  checkinTime?: string;
  transporter1PersonSeq?: string;
  transporter2PersonSeq?: string;
}

async function updateLiveryCase(
  request: UpdateLiveryCaseRequest,
  accessToken: string
): Promise<LiveryCase> {
  try {
    const response = await fetch(`${REACT_APP_API_URL}livery/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      body: JSON.stringify(request),
    });

    return await response.json();
  } catch (error) {
    console.error('There was a problem updating the livery case: ', error);
    throw error;
  }
}

export enum LegacyLiveryStatus {
  CANCELLED = '010CF940-80D4-4840-B61C-0237169543C9',
  FORCE_LIVERY_CLOSED = '201415B6-06ED-479A-8642-5299F056EB4F',
  REOPEN_TRANS = 'D2465302-F7D0-11E0-9380-70C84724019B',
  ASSIGNED = '28EAF5CD-09E2-4419-8BC7-948F19D66719',
  ACCEPTED = '28EAF5CD-09E2-4419-8BC7-948F19D66720',
  DEFERRED = '28EAF5CD-09E2-4419-8BC7-948F19D66721',
  LIVERY_EN_ROUTE = '28EAF5CD-09E2-4419-8BC7-948F19D66737',
  LIVERY_AT_SCENE = '28EAF5CD-09E2-4419-8BC7-948F19D66738',
  OTHER = '28EAF5CD-09E2-4419-8BC7-948F19D66739',
  WAITING_FOR_ASSISTANCE = '28EAF5CD-09E2-4419-8BC7-948F19D66740',
  CUSTODY_OF_BODY_SEALS_BODY = '28EAF5CD-09E2-4419-8BC7-948F19D66741',
  DEPARTED_SCENE = '28EAF5CD-09E2-4419-8BC7-948F19D66742',
  DELAYED = '28EAF5CD-09E2-4419-8BC7-948F19D66746',
  ENTER_REPLACEMENT_RECOVERY_SEAL = '28EAF5CD-09E2-4419-8BC7-948F19D66755',
  FORCE_LIVERY_OPEN = '551910AD-A8B2-4073-9005-AD42DA18813A',
  COMPLETED = '00000000-0000-0000-0000-000000000000',
}

export interface LegacyAssignment {
  assignmentSeq: string | null;
  assignmentTypeSeq: string | null;
  otherAssignmentTypeName: string | null;
  caseSeq: string;
  isActive: boolean | null;
  assigneeUserSeq: string | null;
  assignerUserSeq: string | null;
  shiftSeq: string | null;
  acceptDateTime: Date | null;
  deferDateTime: Date | null;
  deferReason: string | null;
  isNotificationSend: boolean | null;
  notificationSentAt: Date | null;
  setAssignmentAt: Date | null;
}

export interface LegacyCaseAssignmentActivityStatusRecord {
  assignmentTypeName: string | null;
  assignmentTypeSeq: string | null;
  statusTypeName: string | null;
  statusTypeSeq: string | null;
  statusValueCode: string | null;
  setAt: Date | null;
  isActive: boolean | null;
}

export interface LegacyActivity {
  activitySeq: string | null;
  activityName: string | null;
  activityDesc: string | null;
  assignmentTypeSeq: string | null;
  isManuallyFormed: boolean | null;
  showInCaseOverview: boolean | null;
  isNoteRequired: boolean | null;
  isPartInQC: boolean | null;
  maxAllotedTaskTime: number | null;
  isActive: boolean | null;
  activityCategorySeq: string | null;
  notShowInStatusManagement: boolean | null;
  operationModeType: string | null;
}

export interface LegacyCaseActivityLogRecord {
  caseActivityLogSeq: string | null;
  caseSeq: string | null;
  activitySeq: string | null;
  setAt: Date | null;
  actualLoggedByUserSeq: string | null;
  loggedByUserSeq: string | null;
  loggedByUsername: string | null;
  notes: string | null;
  otherReasonText: string | null;
  holdReason: string | null;
  isActive: boolean | null;
  createdByUserSeq: string | null;
  createdOn: Date | null;
  latitude: number | null;
  longitude: number | null;
  loggedByPersonFullName: string | null;
  activity: LegacyActivity | null;
}

export interface LiveryCase {
  refetchCases: () => Promise<void>;
  caseID: string | null;
  status: string;
  liveryUserSeq: string | null;
  originalRecoverySealNumber: string | null;
  recoverySealNumber: string | null;
  decedentFullName: string | null;
  pickupAddressSeq: string | null;
  addressSeq: string | null;
  address: string | null;
  locationToSeq: string | null;
  liveryNotes: string | null;
  caseSeq: string;
  statusTypeSeq: string | null;
  statusValueCode: string | null;
  setAt: Date;
  expiresAt: Date | null;
  activitySeq: string | null;
  activityStatusTypeSeq: string | null;
  activityName: string | null;
  activityLog: LegacyCaseActivityLogRecord[];
  transporter1: Option;
  transporter2: Option;
  isActive: boolean;
}

export const LiveryAPI = {
  getLiveryCasesForUser,
  updateLiveryStatusForCase,
  getTransportionLogForCaseSeq,
  updateLiveryCase,
  getAddress,
  getLiveryLog,
};
