import { DownloadRounded, OpenInNewRounded } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Portal,
  Snackbar,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../auth/AuthService';
import { FileAPI } from './FileAPI';
// <iframe src="${blobUrl}#zoom=FitH&toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0" type="application/pdf" title="${filename}"></iframe>

const saveFile = async (blob: Blob, suggestedName: string) => {
  const supportsFileSystemAccess =
    'showSaveFilePicker' in window &&
    (() => {
      try {
        return window.self === window.top;
      } catch {
        return false;
      }
    })();

  if (supportsFileSystemAccess) {
    try {
      const handle = await (window as any).showSaveFilePicker({
        suggestedName,
      });
      const writable = await handle.createWritable();
      await writable.write(blob);
      await writable.close();
      return;
    } catch (err) {
      console.log(err);
      // if (err.name !== 'AbortError') {
      //   console.error(err.name, err.message);
      //   return;
      // }
    }
  }

  const blobURL = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = blobURL;
  a.download = suggestedName;
  a.style.display = 'none';
  document.body.append(a);
  a.click();
  setTimeout(() => {
    URL.revokeObjectURL(blobURL);
    a.remove();
  }, 1000);
};

type TriggerPrintDialogParams = {
  file?: File;
  blob?: Blob;
};

export const triggerPrintDialog = ({ file, blob }: TriggerPrintDialogParams) => {
  let printBlob: Blob;
  if (file) {
    printBlob = file;
  } else if (blob) {
    printBlob = blob;
  } else {
    console.error('No file or blob provided for printing');
    return;
  }

  const blobUrl = URL.createObjectURL(printBlob);

  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = blobUrl;
  document.body.appendChild(iframe);

  iframe.onload = () => {
    try {
      if (iframe.contentWindow) {
        iframe.contentWindow.print();
      }
    } catch (e) {
      console.error("Couldn't print the document", e);
    }
  };

  iframe.onclose = () => {
    document.body.removeChild(iframe);
    URL.revokeObjectURL(blobUrl);
  };
};

type OpenDocumentParams = {
  file?: File;
  blob?: Blob;
  filename: string;
};

export const openDocumentInNewTab = ({ file, blob, filename }: OpenDocumentParams) => {
  let documentBlob: Blob;

  if (file) {
    documentBlob = file;
  } else if (blob) {
    documentBlob = blob;
  } else {
    console.error('No file or blob provided for opening');
    return;
  }

  // Create an object URL instead of a data URL
  const blobUrl = URL.createObjectURL(documentBlob);

  const newWindow = window.open('', '_blank');
  if (newWindow) {
    newWindow.document.write(`
        <html>
          <head>
            <title>${filename}</title>
            <style>
              body {
                margin: 0;
                paddingTop: 20px;
                font-family: Arial, sans-serif;
                background-color: #f4f4f4;
                display: flex;
                flex-direction: column;
                align-items: center;
              }
              iframe {
                border: none;
                width: 60%; /* Makes the PDF not take the entire width */
                height: 90vh; /* Ensures the PDF takes up most of the height */
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                margin-top: 20px;
                overflow: hidden;
              }
              #download-btn {
                padding: 10px 20px;
                margin-top: 20px;
                background-color: #007bff;
                color: white;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                text-align: center;
                font-size: 16px;
              }
              #download-btn:hover {
                background-color: #0056b3;
              }
            </style>
          </head>
          <body>
            <button id="download-btn">Download ${filename}</button>
            <iframe src="${blobUrl}#zoom=page-fit&view=FitH&toolbar=0" type="application/pdf" title="${filename}"></iframe>
            <script>
              document.getElementById('download-btn').onclick = function() {
                const a = document.createElement('a');
                a.href = '${blobUrl}';
                a.download = '${filename}';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              };
              window.onbeforeunload = function() {
                URL.revokeObjectURL('${blobUrl}');
              };
            </script>
          </body>
        </html>
      `);
    newWindow.document.close();
  } else {
    console.error('Failed to open new window');
    URL.revokeObjectURL(blobUrl);
  }
};

type OpenFileButtonParams = {
  fileSeq: string;
  caseid: string;
  filename: string;
  buttonSx?: SxProps<Theme>;
  buttonVariant?: 'text' | 'outlined' | 'contained';
  buttonColor?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  buttonSize?: 'small' | 'medium' | 'large';
  onOpenSuccess?: () => void;
  onOpenError?: (error: Error) => void;
};

type OpenFileError = {
  display: boolean;
  title: string;
  message: string;
};

export function PrintFileButton({
  fileSeq,
  caseid,
  filename,
  buttonVariant = undefined,
  buttonColor = undefined,
  buttonSize = undefined,
  buttonSx = undefined,
  onOpenSuccess,
  onOpenError,
}: OpenFileButtonParams) {
  const auth = useAuth();
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState<OpenFileError>({ display: false, title: '', message: '' });
  const [progressSize, setProgressSize] = useState(16);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  useEffect(() => {
    if (buttonRef.current) {
      const { fontSize } = window.getComputedStyle(buttonRef.current);
      setProgressSize(parseFloat(fontSize));
    }
  }, [filename]);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  async function handleFileAction(action: 'open' | 'download') {
    try {
      setDownloading(true);
      const { blob } = await FileAPI.downloadFileBlob({
        accessToken: auth.user?.accessToken!,
        caseid,
        fileSeq,
      });

      if (blob instanceof Blob) {
        const file = new File([blob], filename, { type: blob.type });
        if (file.type !== 'application/pdf') {
          saveFile(blob, filename);
          return;
        }
        if (action === 'open') {
          openDocumentInNewTab({ file, filename });
        } else {
          saveFile(blob, filename);
        }
      }

      onOpenSuccess?.();
    } catch (error) {
      setError({
        display: true,
        title: 'File not found',
        message: `We could not find a file named ${filename}`,
      });
      onOpenError?.(error as Error);
    } finally {
      setDownloading(false);
      handleClose();
    }
  }

  return (
    <>
      <Portal>
        <Snackbar
          open={error.display}
          autoHideDuration={5000}
          onClose={() => setError({ display: false, title: '', message: '' })}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <Alert severity='error'>
            <AlertTitle>{error.title}</AlertTitle>
            {error.message}
          </Alert>
        </Snackbar>
      </Portal>

      {/* <Tooltip title={filename?.trim()}> */}
      <Button
        disabled={downloading}
        startIcon={downloading && <CircularProgress size={progressSize} />}
        onClick={() => handleFileAction('open')}
        onContextMenu={handleContextMenu}
        variant={buttonVariant}
        color={buttonColor}
        size={buttonSize}
        sx={buttonSx}
        ref={buttonRef}
      >
        <Typography variant='body2'>{filename?.trim()}</Typography>
      </Button>
      {/* </Tooltip> */}

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={
          contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
        }
      >
        <MenuItem onClick={() => handleFileAction('open')}>
          <ListItemIcon>
            <OpenInNewRounded fontSize='small' />
          </ListItemIcon>
          <ListItemText>Open in New Tab</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleFileAction('download')}>
          <ListItemIcon>
            <DownloadRounded fontSize='small' />
          </ListItemIcon>
          <ListItemText>Download</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
