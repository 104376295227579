import PrintIcon from '@mui/icons-material/Print';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Document, Font, Page, StyleSheet, Text, View, usePDF } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import InterBlack from '../../assets/fonts/inter/static/Inter-Black.ttf';
import InterBold from '../../assets/fonts/inter/static/Inter-Bold.ttf';
import InterExtraBold from '../../assets/fonts/inter/static/Inter-ExtraBold.ttf';
import InterExtraLight from '../../assets/fonts/inter/static/Inter-ExtraLight.ttf';
import InterLight from '../../assets/fonts/inter/static/Inter-Light.ttf';
import InterMedium from '../../assets/fonts/inter/static/Inter-Medium.ttf';
import InterRegular from '../../assets/fonts/inter/static/Inter-Regular.ttf';
import InterSemiBold from '../../assets/fonts/inter/static/Inter-SemiBold.ttf';
import InterThin from '../../assets/fonts/inter/static/Inter-Thin.ttf';
import { CaseSummary } from '../../types/CaseSummary.type';
import { useAuth } from '../auth/AuthService';
import { triggerPrintDialog } from '../functions/triggerPrintDialog';

Font.register({
  family: 'Inter',
  fonts: [
    { src: InterBlack, fontWeight: 'ultrabold' },
    { src: InterBold, fontWeight: 'bold' },
    { src: InterExtraBold, fontWeight: 'heavy' },
    { src: InterExtraLight, fontWeight: 'ultralight' },
    { src: InterLight, fontWeight: 'light' },
    { src: InterRegular, fontWeight: 'normal' },
    { src: InterMedium, fontWeight: 'medium' },
    { src: InterSemiBold, fontWeight: 'semibold' },
    { src: InterThin, fontWeight: 'thin' },
  ],
});
// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 8,
    paddingHorizontal: 10,
    fontSize: 8,
    fontFamily: 'Inter',
    fontWeight: 'normal',
    lineHeight: 0.8,
  },
  title: {
    fontSize: 10,
    textAlign: 'center',
    marginBottom: 12,
    // textDecoration: 'underline',
    fontWeight: 'bold',
    paddingBottom: 3,
  },
  section: {
    marginBottom: 5,
  },
  sectionTitle: {
    fontSize: 8,
    fontWeight: 'bold',
    marginBottom: 4,
    // textDecoration: 'underline',
  },

  contentRow: {
    flexDirection: 'row',
    marginBottom: 2,
  },
  contentKey: {
    width: 120,
    fontWeight: 600,
  },
  contentKey2: {
    marginRight: 5,
    fontWeight: 600,
  },
  contentValue2: {
    textAlign: 'left',
  },
  dateTime: {
    fontSize: 8,
    textAlign: 'left',
    flexShrink: 1,
  },
  caseInformationKey: {
    width: 240,
    fontWeight: 600,
  },
  nextOfKinTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 6,
    textDecoration: 'underline',
  },
  nextOfKinValue: {
    width: 120,
  },
  contentValue: {
    flex: 1,
  },
  synopsisSection: {
    marginTop: 10,
  },
  synopsisTitle: {
    fontSize: 12,
    marginBottom: 4,
    textDecoration: 'underline',
  },
  synopsisContent: {
    textAlign: 'justify',
    flexWrap: 'wrap',
    lineHeight: 1,
  },
  footer: {
    marginTop: 10,
    borderTop: 1,
    paddingTop: 2,
    textAlign: 'left',
    fontSize: 8,
  },
  fillableFieldContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    paddingTop: 5,
  },
  fillableFieldWrapper: {
    alignItems: 'center',
    width: '30%',
    marginBottom: 0,
  },
  fillableField: {
    width: '100%',
    borderBottom: 1,
    textAlign: 'center',
    paddingTop: 0,
    paddingBottom: 3,
    lineHeight: 1,
  },
  fillableFieldText: {
    fontSize: 10,
    textAlign: 'center',
    marginTop: 4,
  },
  fullWidthRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 3,
    width: '100%',
  },
  halfWidthBlock: {
    width: '48%',
  },
  line: {
    width: '100%',
    height: 1,
    marginBottom: 2,
    backgroundColor: 'black',
  },
});

const getRelationshipByName = (name, nextOfKinList) => {
  const kin = nextOfKinList?.find(k => k.name === name);
  return kin ? kin.relationship : '-';
};

const formatDateTime = dateTime => {
  if (!dateTime) return '-';

  const date = dayjs(dateTime);

  if (!date.isValid()) return '-';

  return date.format('MM/DD/YYYY HH:mm');
};

const CaseSummaryPDF = (caseSummary: CaseSummary) => {
  let injuryByLabel, injuryOnLabel, injuryLabel;
  const foundOnsetIllnessByType = caseSummary.injury.extra;
  if (foundOnsetIllnessByType) {
    injuryByLabel =
      foundOnsetIllnessByType === 'I'
        ? 'Onset Of Illness By'
        : foundOnsetIllnessByType === 'O'
        ? 'Injury Observed By'
        : 'Found By';

    injuryOnLabel =
      foundOnsetIllnessByType === 'I'
        ? 'Onset Of Illness On'
        : foundOnsetIllnessByType === 'O'
        ? 'Injury Observed On'
        : 'Found On';

    injuryLabel =
      foundOnsetIllnessByType === 'I'
        ? 'Onset Of Illness'
        : foundOnsetIllnessByType === 'O'
        ? 'Injury Observed'
        : 'Found';
  }
  return (
    <Document>
      <Page wrap={true} size='A4' style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>REPORT OF INVESTIGATION BY MEDICAL EXAMINER</Text>
          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Case Number:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.case_number} —{' '}
                  {caseSummary.decedent_information?.decedent_name?.toUpperCase()}
                </Text>
              </View>
            </View>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Jurisdiction:</Text>
                <Text style={styles.contentValue2}>{caseSummary.jurisdiction?.toUpperCase()}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.line}></View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Decedent Information</Text>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Name:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.decedent_information?.decedent_name?.toUpperCase()}
                </Text>
              </View>
            </View>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>AKA:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.decedent_information.aka?.toUpperCase() ?? '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.line}></View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Residence</Text>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Address:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.residence.address?.toUpperCase() ?? '-'}
                </Text>
              </View>
            </View>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Zip Code:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.residence.zip?.toUpperCase() ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>City / Town:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.residence.city_or_town?.toUpperCase() ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>State:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.residence.state?.toUpperCase() ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Country:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.residence.country?.toUpperCase() ?? '-'}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.contentRow}>
            <Text style={styles.contentKey2}>Apt / Suite:</Text>
            <Text style={styles.contentValue2}>
              {caseSummary.residence.apt_suite?.toUpperCase() ?? '-'}
            </Text>
          </View>
          <View style={styles.contentRow}>
            <Text style={styles.contentKey2}>Floor:</Text>
            <Text style={styles.contentValue2}>
              {caseSummary.residence.floor?.toUpperCase() ?? '-'}
            </Text>
          </View>
        </View>

        <View style={styles.line}></View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Demographics</Text>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Birth Date:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.demographic_information.birth_date?.toUpperCase() ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Age:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.demographic_information.age ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>SSN:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.demographic_information.ssn ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Private Physician:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.demographic_information.private_physician ?? '-'}
                </Text>
              </View>
            </View>
          </View>

          {/* next row */}
          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Marital Status:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.demographic_information.marital_status ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Ethnicity / Race:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.demographic_information.ethnicity_or_race ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Gender:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.demographic_information.gender ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Occupation:</Text>
                <Text style={styles.contentValue2}>
                  {caseSummary.demographic_information.occupation ?? '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.line}></View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Next Of Kin</Text>
          <View style={styles.contentRow}>
            <Text style={styles.contentKey}>Name</Text>
            <Text style={styles.contentKey}>Relationship</Text>
            <Text style={styles.contentKey}>Contact Info</Text>
          </View>

          {caseSummary.next_of_kin_information?.map((kin, index) => (
            <View style={styles.contentRow}>
              <Text style={styles.nextOfKinValue}>{kin.name ?? '-'}</Text>
              <Text style={styles.nextOfKinValue}>{kin.relationship ?? '-'}</Text>
              <Text style={styles.nextOfKinValue}>{kin.phone_number ?? '-'}</Text>
            </View>
          ))}
        </View>

        <View style={styles.line}></View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Identification Of Decedent</Text>
          <View style={styles.contentRow}>
            <Text style={styles.contentKey}>Identified By</Text>
            <Text style={styles.contentKey}>Modality</Text>
            <Text style={styles.contentKey}>Relationship</Text>
          </View>

          {caseSummary.identification_information?.map((id, index) => (
            <View style={styles.contentRow}>
              <Text style={styles.nextOfKinValue}>{id.name ?? '-'}</Text>
              <Text style={styles.nextOfKinValue}>{id.modality ?? '-'}</Text>
              <Text style={styles.nextOfKinValue}>
                {getRelationshipByName(id.name, caseSummary.next_of_kin_information) ?? '-'}
              </Text>
            </View>
          ))}
        </View>

        <View style={styles.line}></View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Scene Examination</Text>
          <View style={styles.contentRow}>
            <Text style={styles.contentKey}>Examined By</Text>
            <Text style={styles.contentKey}>Date & Time</Text>
          </View>

          {caseSummary.scene_examined_by?.map((scene, index) => (
            <View style={styles.contentRow}>
              <Text style={styles.nextOfKinValue}>{scene.notified_by ?? '-'}</Text>
              <Text style={styles.dateTime}>{scene.date ?? '-'}</Text>
            </View>
          ))}
        </View>

        <View style={styles.line}></View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Case Synopsis</Text>
          <View style={styles.synopsisContent}>
            <Text style={styles.synopsisContent}>{caseSummary.case_synopsis ?? '-'}</Text>
          </View>
        </View>

        <View style={styles.line}></View>
        {/* Case Information */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Case Information</Text>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>ME Action:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.ME_action ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Assigned Investigator:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.assigned_investigator ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Reason For Investigation:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.investigation_reason ?? '-'}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Police Notified On:</Text>
                <Text style={styles.contentValue}>{caseSummary.police_notified.date ?? '-'}</Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Police Notified By:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.police_notified.notified_by ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Police Case Number:</Text>
                <Text style={styles.contentValue}>{caseSummary.police_notified.extra ?? '-'}</Text>
              </View>
            </View>
          </View>

          <View style={styles.line}></View>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Last Known To Be Alive On:</Text>
                <Text style={styles.contentValue}>{caseSummary.last_date_alive.date ?? '-'}</Text>
              </View>
            </View>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Last Date Alive Notified By:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.last_date_alive.notified_by ?? '-'}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Physician:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.physician ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Physician to whom case was released:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.physician_to_whom_case_was_released ?? '-'}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.line}></View>

          <View>
            {foundOnsetIllnessByType && (
              <>
                <View style={styles.fullWidthRow}>
                  <View style={styles.halfWidthBlock}>
                    <View style={styles.contentRow}>
                      <Text style={styles.contentKey2}>Discovered:</Text>
                      <Text style={styles.contentValue}>{injuryLabel}</Text>
                    </View>
                  </View>

                  <View style={styles.halfWidthBlock}>
                    <View style={styles.contentRow}>
                      <Text style={styles.contentKey2}>{injuryByLabel}:</Text>
                      <Text style={styles.contentValue}>{caseSummary.injury.notified_by}</Text>
                    </View>
                  </View>

                  <View style={styles.halfWidthBlock}>
                    <View style={styles.contentRow}>
                      <Text style={styles.contentKey2}>{injuryOnLabel}:</Text>
                      <Text style={styles.contentValue}>{caseSummary.injury.date}</Text>
                    </View>
                  </View>
                </View>
              </>
            )}
          </View>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Cause of Death:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.cause_of_death ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Manner of Death:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.manner_of_death ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>POC at time of case intake:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.physician_on_call_at_time_of_case_intake ?? '-'}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Exam Type:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.type_of_exam ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Exam Date / Time:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.exam_date_and_time
                    ? formatDateTime(caseSummary.case_information.exam_date_and_time)
                    : '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Rime Status:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.rime_status ?? '-'}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Body Location:</Text>
                <Text style={styles.contentValue}>
                  {`${caseSummary.body_location_information.address ?? ''} ${
                    caseSummary.body_location_information.city ?? ''
                  }, ${caseSummary.body_location_information.state ?? ''}, ${
                    caseSummary.body_location_information.zipcode ?? ''
                  }` || '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Identification Status:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.identification_status ?? '-'}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.line}></View>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2} break>
                  Check-out Authorized by:
                </Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.check_out_authorized_by ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Checked-out to:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.check_out_to ?? '-'}
                </Text>
              </View>
            </View>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Check-out Status:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.check_out_status ?? '-'}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.fullWidthRow}>
            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Pronouncing Physician:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.pronouncing_physician ?? '-'}
                </Text>
              </View>
            </View>

            <View style={styles.halfWidthBlock}>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey2}>Pronounced Date / Time:</Text>
                <Text style={styles.contentValue}>
                  {caseSummary.case_information.pronouncing_date_and_time
                    ? formatDateTime(caseSummary.case_information.pronouncing_date_and_time)
                    : '-'}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.contentRow}>
            <Text style={styles.contentKey2}>EDRS Case Number:</Text>
            <Text style={styles.contentValue}>
              {caseSummary.case_information.edrs_case_number ?? '-'}
            </Text>
          </View>
          <View style={styles.contentRow}>
            <Text style={styles.contentKey2}>Lab Number:</Text>
            <Text style={styles.contentValue}>
              {caseSummary.case_information.lab_number ?? '-'}
            </Text>
          </View>
          {/* <View style={styles.contentRow}>
            <Text style={styles.contentKey2}>Tissue Number:</Text>
            <Text style={styles.contentValue2}>
              {caseSummary.case_information.tissue_number ?? '-'}
            </Text>
          </View> */}

          <View style={styles.fillableFieldContainer}>
            <View style={styles.fillableFieldWrapper}>
              <Text style={styles.fillableField}>
                {caseSummary.case_information.physician ?? ' '}
              </Text>
              <Text style={styles.fillableFieldText}>Medical Examiner</Text>
            </View>
            <View style={styles.fillableFieldWrapper}>
              <Text style={styles.fillableField}>
                {caseSummary.case_information.exam_date_and_time
                  ? dayjs(caseSummary.case_information.exam_date_and_time).format('MM/DD/YYYY')
                  : ' '}
              </Text>
              <Text style={styles.fillableFieldText}>Date</Text>
            </View>
            <View style={styles.fillableFieldWrapper}>
              <Text style={styles.fillableField}>{caseSummary.jurisdiction ?? ' '} </Text>
              <Text style={styles.fillableFieldText}>Jurisdiction</Text>
            </View>
          </View>

          <View style={styles.footer}>
            <Text>
              Generated by {caseSummary.requesting_user ?? '-'} on {getTodaysDate()}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
function getTodaysDate() {
  const suffix = (n: number) => {
    if (n >= 11 && n <= 13) {
      return 'th';
    }
    switch (n % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const currentDate = new Date();
  const date = currentDate.getDate();
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  let hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

  return `${month} ${date}${suffix(date)}, ${year} at ${hours}:${formattedMinutes} ${ampm} EST`;
}

export const PrintRIMEButton = ({ caseSummary, isMediumScreen }) => {
  const [instance, updateInstance] = usePDF({ document: <CaseSummaryPDF {...caseSummary} /> });
  const [isUploading, setIsUploading] = useState(false);
  const [open, setOpen] = useState(false);
  const [notificationDialog, setNotificationDialog] = useState({
    open: false,
    message: '',
    type: '',
  });
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;

  useEffect(() => {
    updateInstance(<CaseSummaryPDF {...caseSummary} />);
  }, [caseSummary]);

  const handleFileUpload = async pdfFile => {
    setIsUploading(true);

    const formDataUploadFiles = new FormData();
    formDataUploadFiles.append('FILEPARAMS[0].CASENO', caseSummary?.case_number);
    formDataUploadFiles.append('FILEPARAMS[0].CASESEQ', caseSummary?.case_seq);
    formDataUploadFiles.append(
      'FILEPARAMS[0].FILERELATEDTO',
      '77d4218a-a421-455d-877d-e992126980f3' // will only ever be a rime report
    );
    formDataUploadFiles.append('FILEPARAMS[0].FILERELATEDTONAME', 'Rime Report');
    formDataUploadFiles.append('FILEPARAMS[0].FILE', pdfFile);
    formDataUploadFiles.append('USERSEQ', user?.userSeq);

    try {
      const response = await fetch(REACT_APP_API_URL + 'fileuploads', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        body: formDataUploadFiles,
      });

      const data = await response.json();
      if (response.ok && data.status === 0) {
        setNotificationDialog({
          open: true,
          message: 'File uploaded successfully!',
          type: 'success',
        });
      } else {
        throw new Error(data.message || 'Unknown error occurred during file upload');
      }
    } catch (error) {
      setNotificationDialog({
        open: true,
        message: `Error during file upload: ${error.message}`,
        type: 'error',
      });
    }
  };

  const handleSaveToDocuments = async () => {
    if (instance.blob) {
      const pdfFile = new File([instance.blob], `${caseSummary?.case_number}-RIME.pdf`, {
        type: 'application/pdf',
      });
      try {
        await handleFileUpload(pdfFile);
      } finally {
        handleCloseDialog();
      }
    } else {
      setNotificationDialog({
        open: true,
        message: 'Failed to generate the PDF file',
        type: 'error',
      });
    }
  };

  const handleDownloadPDF = () => {
    if (instance.blob) {
      const link = document.createElement('a');
      link.href = instance.url;
      link.download = `${caseSummary?.case_number}-RIME.pdf`;
      link.click();
    } else {
      setNotificationDialog({
        open: true,
        message: 'Failed to generate the PDF file for download',
        type: 'error',
      });
    }
  };

  // leave here for when/if we want to add the printing functionality for the RIME
  const handlePrint = () => {
    if (instance.blob) {
      triggerPrintDialog({ blob: instance.blob });
    } else {
      setNotificationDialog({
        open: true,
        message: 'Failed to generate the PDF file for printing',
        type: 'error',
      });
    }
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleCloseNotificationDialog = () => {
    setNotificationDialog(prevState => ({ ...prevState, open: false }));
    setTimeout(() => {
      setNotificationDialog({ open: false, message: '', type: '' });
    }, 200);
  };

  return (
    <>
      <Tooltip title='Print RIME'>
        <IconButton
          size={isMediumScreen ? 'medium' : 'large'}
          sx={{ width: 'max-content', height: 'max-content' }}
          onClick={handleOpenDialog}
        >
          <PrintIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleCloseDialog} maxWidth='lg' fullWidth>
        <Box display='flex' alignItems='center' justifyContent='space-between' padding='16px'>
          <DialogTitle style={{ margin: 0, padding: 0 }}>
            {caseSummary.case_number} RIME Preview
          </DialogTitle>
          <Box>
            <Button onClick={handleDownloadPDF} color='primary' style={{ marginRight: 8 }}>
              Download PDF
            </Button>
            <Button
              onClick={handleSaveToDocuments}
              color='secondary'
              style={{ marginRight: 8 }}
              disabled={isUploading}
            >
              {isUploading ? 'Saving...' : 'Save to Documents'}
            </Button>
            <Button onClick={handleCloseDialog} color='error'>
              Close
            </Button>
          </Box>
        </Box>
        <DialogContent dividers>
          <iframe
            title='PDF Preview'
            src={`${instance.url}#zoom=page-fit&view=FitH&toolbar=0`}
            style={{
              width: '100%',
              minHeight: '90vh',
              border: 'none',
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={notificationDialog.open}
        onClose={handleCloseNotificationDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {notificationDialog.type === 'success' ? 'Success' : 'Error'}
        </DialogTitle>
        <DialogContent>
          <Typography id='alert-dialog-description'>{notificationDialog.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotificationDialog} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
