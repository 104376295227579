import React, { useState} from 'react';
import Gallery from 'react-image-gallery';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import HomeIcon from '@mui/icons-material/Home';
import { useMediaQuery, useTheme } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import GetAppIcon from '@mui/icons-material/GetApp';
import 'react-image-gallery/styles/css/image-gallery.css';

const CustomGallery = ({ images, selectedIndex }) => {
    const appTheme = useTheme();
    const isMobile = useMediaQuery(appTheme.breakpoints.down('sm'));
    const isLaptop = useMediaQuery(appTheme.breakpoints.up('md'));
    const [zoomLevel, setZoomLevel] = useState(1);
    const [dragging, setDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
    const [galleryImages, setGalleryImages] = useState(images);

    const renderCustomControls = () => {
      const iconStyle = { color: '#fff' }; // since background is dark, used white color for icons
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <GetAppIcon style={iconStyle} onClick={handleDownloadClick} />
                <ZoomInIcon style={iconStyle} onClick={handleZoomInClick} />
                <ZoomOutIcon style={iconStyle} onClick={handleZoomOutClick} />
                <RotateLeftIcon style={iconStyle} onClick={handleRotateLeftClick} />
                <RotateRightIcon style={iconStyle} onClick={handleRotateRightClick} />
                <HomeIcon style={iconStyle} onClick={handleHomeClick} />
            </div>
        );
    };


    const handleMouseDown = (event) => {
        event.preventDefault();
        setDragging(true);
        setDragStart({ x: event.clientX, y: event.clientY });
      };
    
      const handleMouseMove = (event) => {
        if (dragging) {
          const offsetX = event.clientX - dragStart.x;
          const offsetY = event.clientY - dragStart.y;
          setDragOffset({ x: offsetX, y: offsetY });
        }
      };
    
      const handleMouseUp = () => {
        setDragging(false);
      };
    
      const handleMouseLeave = () => {
        setDragging(false);
      };
    
      const handleTouchStart = (event) => {
        event.preventDefault();
        const touch = event.touches[0];
        setDragging(true);
        setDragStart({ x: touch.clientX, y: touch.clientY });
      };
    
      const handleTouchMove = (event) => {
        event.preventDefault();
        if (!dragging) return;
        const touch = event.touches[0];
        const offsetX = touch.clientX - dragStart.x;
        const offsetY = touch.clientY - dragStart.y;
        setDragOffset({ x: offsetX, y: offsetY });
      };
    
      const handleTouchEnd = () => {
        setDragging(false);
      };
    
      const handleDoubleClick = () => {
        setZoomLevel(zoomLevel === 1 ? 2 : 1); 
      };

    const handleZoomInClick = () => {
        setZoomLevel(prevZoom => prevZoom + 0.1);
    };

    const handleZoomOutClick = () => {
        setZoomLevel(prevZoom => Math.max(0.1, prevZoom - 0.1));
    };

    const handleHomeClick = () => {
        setZoomLevel(1); 
        setDragOffset({ x: 0, y: 0 }); 
    };

    const handleDownloadClick = () => {
        const anchor = document.createElement('a');
        anchor.href = galleryImages[selectedIndex].midsize; 
        anchor.download = galleryImages[selectedIndex].originalFileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      };

      const handleRotateLeftClick = () => {
        const rotatedImages = galleryImages.map(image => {
          const rotatedImage = {
            ...image,
            rotationAngle: (image.rotationAngle || 0) - 90
          };
          return rotatedImage;
        });
        setGalleryImages(rotatedImages); 
      };

      const handleRotateRightClick = () => {
        const rotatedImages = galleryImages.map(image => {
          const rotatedImage = {
            ...image,
            rotationAngle: (image.rotationAngle || 0) + 90
          };
          return rotatedImage;
        });
        setGalleryImages(rotatedImages); 
      };


    return (
      <>
        {galleryImages.length > 0 ? (
          <div style={{ width: '100%', maxWidth: '100%', height: '100%', maxHeight: '100%' }}>
            <Gallery
              items={galleryImages}
              startIndex={selectedIndex}
              showIndex={true}
              renderCustomControls={renderCustomControls}
              lazyLoad={true}
              useTranslate3D={true}
              renderItem={item => (
                <div
                  style={{
                    paddingTop: isMobile ? '100%' : isLaptop ? '45%' : '50%',
                    position: 'relative',
                    overflow: 'hidden',
                    cursor: dragging ? 'grabbing' : 'grab',
                  }}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onDoubleClick={handleDoubleClick}
                  onMouseLeave={handleMouseLeave}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                >
                  <img
                    key={item.photoSeq}
                    src={item.midsize}
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      transform: `translate(${dragOffset.x}px, ${dragOffset.y}px) rotate(${
                        item.rotationAngle || 0
                      }deg) scale(${zoomLevel})`,
                      objectFit: 'contain',
                      width: '100%',
                      height: '100%',
                    }}
                    loading='lazy'
                  />
                  {/* <div
                    style={{
                      position: 'absolute',
                      bottom: '30px',
                      left: '110px',
                      color: 'white',
                      padding: '5px',
                    }}
                  >
                    {item.originalFileName}
                  </div> */}
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      left: '110px',
                      color: 'white',
                      padding: '5px',
                    }}
                  >
                    {item.photoLabel}
                  </div>
                </div>
              )}
            />
          </div>
        ) : (
          <div>No images to display.</div>
        )}
      </>
    );
}

export default CustomGallery;