import { Box, Breadcrumbs, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

export default function AddressFieldWithoutSearch({
  placeholder,
  addressState,
  setAddressState,
  editing = false,
  small = false,
  hideBreadcrumbRoot = true,
  breadcrumbRoot = '',
}) {
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const formik = useFormikContext();
  const { REACT_APP_API_URL } = process.env;
  const [tempAddress, setTempAddress] = useState(null);

  // const handleAddressLine1Change = event => {
  //   setAddressState({
  //     ...addressState,
  //     addressLine1: event.target.value,
  //   });
  // };

  // const handleAddressLine2Change = event => {
  //   setAddressState({
  //     ...addressState,
  //     addressLine2: event.target.value,
  //   });
  // };

  // const handleAptSuiteChange = event => {
  //   setAddressState({
  //     ...addressState,
  //     aptSuite: event.target.value,
  //   });
  // };

  // const handleFloorChange = event => {
  //   setAddressState({
  //     ...addressState,
  //     floor: event.target.value,
  //   });
  // };

  // const handleCityTownChange = event => {
  //   setAddressState({
  //     ...addressState,
  //     cityTown: event.target.value,
  //   });
  // };

  // const handleStateChange = (event, data) => {
  //   setAddressState({
  //     ...addressState,
  //     state: data,
  //   });
  // };

  // const handleZipCodeChange = event => {
  //   setAddressState({
  //     ...addressState,
  //     zipCode: event.target.value,
  //   });
  // };

  // const handleCountryChange = (event, data) => {
  //   setAddressState({
  //     ...addressState,
  //     country: data,
  //   });
  // };

  const handleTempAddressChange = (name, value) => {
    setTempAddress((prev) => ({ ...prev, [name]: value })); 
  };

  const handleBlur = () => {
    setAddressState(tempAddress); 
  };

  useEffect(() => {
    setTempAddress(addressState || []);
  }, [addressState]); 

  useEffect(() => {
    // INITIALIZE STATE OPTIONS
    fetch(REACT_APP_API_URL + 'getstateoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setStateOptions(data);
      })
      .catch(e => {
        //alert(e);
      });

    // INITIALIZE COUNTRY OPTIONS
    fetch(REACT_APP_API_URL + 'getcountryoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setCountryOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
            width: '100%',
          }}
        >
          <TextField
            value={tempAddress?.addressLine1 ?? ''}
            onChange={(e) => handleTempAddressChange(e.target.name, e.target.value)}
            onBlur={handleBlur}
            size={small ? 'small' : 'medium'}
            fullWidth
            disabled={!editing}
            variant='outlined'
            id='addressLine1'
            name='addressLine1'
            label={
              Boolean(hideBreadcrumbRoot) ? (
                'Address Line 1'
              ) : (
                <>
                  <Breadcrumbs>
                    <Typography>{breadcrumbRoot}</Typography>
                    <Typography color='text.primary'>Address Line 1</Typography>
                  </Breadcrumbs>
                </>
              )
            }
            type='text'
            InputLabelProps={{
              shrink: addressState?.addressLine1 !== null,
            }}
          />
          {!editing && (
            <Box
              onClick={() =>
                formik.setStatus({
                  ...formik.status,
                  disabledClickCount: formik.status?.disabledClickCount + 1,
                })
              }
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
            width: '100%',
          }}
        >
          <TextField
            value={tempAddress?.addressLine2 ?? ''}
            onChange={(e) => handleTempAddressChange(e.target.name, e.target.value)}
            onBlur={handleBlur}
            size={small ? 'small' : 'medium'}
            fullWidth
            disabled={!editing}
            variant='outlined'
            id='addressLine2'
            name='addressLine2'
            label={
              Boolean(hideBreadcrumbRoot) ? (
                'Address Line 2'
              ) : (
                <>
                  <Breadcrumbs>
                    <Typography>{breadcrumbRoot}</Typography>
                    <Typography color='text.primary'>Address Line 2</Typography>
                  </Breadcrumbs>
                </>
              )
            }
            type='text'
            InputLabelProps={{
              shrink: addressState?.addressLine2 !== null,
            }}
          />
          {!editing && (
            <Box
              onClick={() =>
                formik.setStatus({
                  ...formik.status,
                  disabledClickCount: formik.status?.disabledClickCount + 1,
                })
              }
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
      </Grid>

      <Grid item xs={12} md={4}>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
            width: '100%',
          }}
        >
          <TextField
            value={tempAddress?.aptSuite ?? ''}
            onChange={(e) => handleTempAddressChange(e.target.name, e.target.value)}
            onBlur={handleBlur}
            size={small ? 'small' : 'medium'}
            fullWidth
            disabled={!editing}
            variant='outlined'
            id='aptSuite'
            name='aptSuite'
            label={
              Boolean(hideBreadcrumbRoot) ? (
                'Apartment #'
              ) : (
                <>
                  <Breadcrumbs>
                    <Typography>{breadcrumbRoot}</Typography>
                    <Typography color='text.primary'>Apartment #</Typography>
                  </Breadcrumbs>
                </>
              )
            }
            type='text'
            InputLabelProps={{
              shrink: addressState?.aptSuite !== null,
            }}
          />
          {!editing && (
            <Box
              onClick={() =>
                formik.setStatus({
                  ...formik.status,
                  disabledClickCount: formik.status?.disabledClickCount + 1,
                })
              }
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
      </Grid>

      <Grid item xs={12} md={4}>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
            width: '100%',
          }}
        >
          <TextField
            value={tempAddress?.floor ?? ''}
            onChange={(e) => handleTempAddressChange(e.target.name, e.target.value)}
            onBlur={handleBlur}
            size={small ? 'small' : 'medium'}
            fullWidth
            disabled={!editing}
            variant='outlined'
            id='floor'
            name='floor'
            label={
              Boolean(hideBreadcrumbRoot) ? (
                'Floor #'
              ) : (
                <>
                  <Breadcrumbs>
                    <Typography>{breadcrumbRoot}</Typography>
                    <Typography color='text.primary'>Floor #</Typography>
                  </Breadcrumbs>
                </>
              )
            }
            type='text'
            InputLabelProps={{
              shrink: addressState?.floor !== null,
            }}
          />
          {!editing && (
            <Box
              onClick={() =>
                formik.setStatus({
                  ...formik.status,
                  disabledClickCount: formik.status?.disabledClickCount + 1,
                })
              }
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
      </Grid>

      <Grid item xs={12} md={4}>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
            width: '100%',
          }}
        >
          <TextField
            value={tempAddress?.cityTown ?? ''}
            onChange={(e) => handleTempAddressChange(e.target.name, e.target.value)}
            onBlur={handleBlur}
            size={small ? 'small' : 'medium'}
            fullWidth
            disabled={!editing}
            variant='outlined'
            id='cityTown'
            name='cityTown'
            label={
              Boolean(hideBreadcrumbRoot) ? (
                'City/Town'
              ) : (
                <>
                  <Breadcrumbs>
                    <Typography>{breadcrumbRoot}</Typography>
                    <Typography color='text.primary'>City/Town</Typography>
                  </Breadcrumbs>
                </>
              )
            }
            type='text'
            InputLabelProps={{
              shrink: addressState?.cityTown !== null,
            }}
          />
          {!editing && (
            <Box
              onClick={() =>
                formik.setStatus({
                  ...formik.status,
                  disabledClickCount: formik.status?.disabledClickCount + 1,
                })
              }
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
      </Grid>

      <Grid item xs={12} md={4}>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
            width: '100%',
          }}
        >
          <Autocomplete
            options={stateOptions}
            value={tempAddress?.state ?? null}
            onChange={(event, newValue) => handleTempAddressChange('state', newValue)}
            onBlur={handleBlur}
            size={small ? 'small' : 'medium'}
            isOptionEqualToValue={(option, value) =>
              option.optionSeq.toUpperCase() === value.optionSeq.toUpperCase()
            }
            disabled={!editing}
            fullWidth
            getOptionLabel={option => option.optionName}
            renderInput={params => (
              <TextField
                {...params}
                label={
                  Boolean(hideBreadcrumbRoot) ? (
                    'State'
                  ) : (
                    <>
                      <Breadcrumbs>
                        <Typography>{breadcrumbRoot}</Typography>
                        <Typography color='text.primary'>State</Typography>
                      </Breadcrumbs>
                    </>
                  )
                }
              />
            )}
          />
          {!editing && (
            <Box
              onClick={() =>
                formik.setStatus({
                  ...formik.status,
                  disabledClickCount: formik.status?.disabledClickCount + 1,
                })
              }
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
      </Grid>

      <Grid item xs={12} md={4}>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
            width: '100%',
          }}
        >
          <TextField
            value={tempAddress?.zipCode ?? ''}
            onChange={(e) => handleTempAddressChange(e.target.name, e.target.value)}
            onBlur={handleBlur}
            size={small ? 'small' : 'medium'}
            fullWidth
            disabled={!editing}
            variant='outlined'
            id='zipCode'
            name='zipCode'
            label={
              Boolean(hideBreadcrumbRoot) ? (
                'Zip Code'
              ) : (
                <>
                  <Breadcrumbs>
                    <Typography>{breadcrumbRoot}</Typography>
                    <Typography color='text.primary'>Zip Code</Typography>
                  </Breadcrumbs>
                </>
              )
            }
            type='text'
            InputLabelProps={{
              shrink: addressState?.zipCode !== null,
            }}
          />
          {!editing && (
            <Box
              onClick={() =>
                formik.setStatus({
                  ...formik.status,
                  disabledClickCount: formik.status?.disabledClickCount + 1,
                })
              }
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
      </Grid>

      <Grid item xs={12} md={4}>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
            width: '100%',
          }}
        >
          <Autocomplete
            options={countryOptions}
            value={tempAddress?.country ?? null}
            onChange={(event, newValue) => handleTempAddressChange('country', newValue)}
            onBlur={handleBlur}
            size={small ? 'small' : 'medium'}
            disabled={!editing}
            isOptionEqualToValue={(option, value) =>
              option.optionSeq.toUpperCase() === value.optionSeq.toUpperCase()
            }
            fullWidth
            getOptionLabel={option => option.optionName}
            renderInput={params => (
              <TextField
                {...params}
                label={
                  Boolean(hideBreadcrumbRoot) ? (
                    'Country'
                  ) : (
                    <>
                      <Breadcrumbs>
                        <Typography>{breadcrumbRoot}</Typography>
                        <Typography color='text.primary'>Country</Typography>
                      </Breadcrumbs>
                    </>
                  )
                }
              />
            )}
          />
          {!editing && (
            <Box
              onClick={() =>
                formik.setStatus({
                  ...formik.status,
                  disabledClickCount: formik.status?.disabledClickCount + 1,
                })
              }
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
