import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Fab } from '@mui/material';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium';
import React from 'react';
import dimensions from '../utils/dimensions';

export default function ExpandedDataGrid({
  listresults,
  columns,
  gridcolor,
  setExpanded,
  idcolumn,
  columnsVisibility,
}) {
  const apiRef = useGridApiRef();
  const theme = useTheme();
  const fabTheme = createTheme({
    palette: {
      primary: {
        main: gridcolor,
      },
    },
  });

  return (
    <Box
      sx={{
        position: 'fixed',
        width: '100%',
        height: `calc(100vh - ${dimensions.app_bar_height})`,
        top: dimensions.app_bar_height,
        left: 0,
        zIndex: theme => theme.zIndex.drawer + 20,
        backgroundColor: theme.palette.background.default,
        '& .MuiDataGrid-columnHeaders': {
          borderRadius: 0,
          backgroundColor: gridcolor,
          color: 'white',
          border: 'none',
          borderRadius: '0px',
        },
      }}
    >
      <ThemeProvider theme={fabTheme}>
        <Fab
          color='primary'
          size='small'
          sx={{
            position: 'fixed',
            top: 105,
            right: 25,
            color: 'white',
          }}
          onClick={() => setExpanded(false)}
        >
          <OpenInFullIcon fontSize='small' />
        </Fab>
      </ThemeProvider>
      <DataGridPremium
        getRowId={row => row[idcolumn]}
        columns={columns}
        rows={listresults}
        apiRef={apiRef}
        sx={{
          border: 'none',
          borderRadius: '0 0 2px 2px',
          '& .MuiDataGrid-cell': {
            borderRight: '1px solid #ddd',
          },
          '& .MuiDataGrid-cell:last-child': {
            borderRight: 'none',
          },
          '& .header-theme': {
            borderRight: '1px solid #ddd',
          },
          '& .header-theme:last-child': {
            borderRight: 'none',
          },
          '& .MuiDataGrid-iconSeparator': {
            display: 'none',
          },
        }}
        rowHeight={28}
        columnHeaderHeight={28}
        hideFooter
        initialState={{
          columns: {
            columnVisibilityModel: columnsVisibility,
          },
        }}
      />
    </Box>
  );
}
