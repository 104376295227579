import { CloseRounded } from '@mui/icons-material';
import {
  Alert,
  Button,
  CardMedia,
  Dialog,
  DialogContent,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { format } from 'date-fns';
import Hls from 'hls.js';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import { VideoWithContent } from './Videos';

interface VideoModalProps {
  open: boolean;
  handleClose: () => void;
  video: VideoWithContent | null;
  isLoadingVideo: boolean;
}

const { REACT_APP_API_URL } = process.env;

interface QualityLevel {
  index: number;
  height: number;
  bitrate: number;
}

const VideoModal: React.FC<VideoModalProps> = ({ open, handleClose, video, isLoadingVideo }) => {
  const {
    user: { accessToken = '' },
  } = useAuth();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [hlsInstance, setHlsInstance] = useState<Hls | null>(null);
  const [qualityLevels, setQualityLevels] = useState<QualityLevel[]>([]);
  const [currentQuality, setCurrentQuality] = useState<number>(-1); // -1 for auto
  const [error, setError] = useState<string | null>(null); // For snackbar error
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  useEffect(() => {
    if (video && videoRef.current && !isLoadingVideo) {
      const videoElement = videoRef.current;
      const videoSrc = `${REACT_APP_API_URL}videos/${video.videoSeq}/playlist.m3u8`;

      if (Hls.isSupported()) {
        const hls = new Hls({
          startLevel: -1, // Start with adaptive quality
          enableWorker: true, // Use worker for better performance
          capLevelToPlayerSize: true, // Cap quality based on player size
          lowLatencyMode: true,
        });

        hls.config.xhrSetup = function (xhr) {
          xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
        };

        hls.loadSource(videoSrc);
        hls.attachMedia(videoElement);
        setHlsInstance(hls);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          const levels = hls.levels.map((level, index) => ({
            index,
            height: level.height,
            bitrate: level.bitrate,
          }));
          setQualityLevels(levels);
        });

        hls.on(Hls.Events.ERROR, function (event, data) {
          if (data.fatal) {
            console.error('Fatal error with HLS.js:', data);
            setError('Failed to load video: ' + data.details);
            setSnackbarOpen(true);
            hls.destroy();
          }
        });
      } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
        videoElement.src = videoSrc;
      } else {
        console.error('This browser does not support HLS or hls.js.');
        setError('This browser does not support HLS or hls.js.');
        setSnackbarOpen(true);
      }
    }
  }, [video, isLoadingVideo, accessToken]);

  const handleQualityChange = (event: SelectChangeEvent<number>) => {
    const selectedQuality = Number(event.target.value);
    setCurrentQuality(selectedQuality);

    if (hlsInstance) {
      if (selectedQuality === -1) {
        hlsInstance.currentLevel = -1; // Enable adaptive quality switching
      } else {
        hlsInstance.loadLevel = selectedQuality; // Load the selected quality level
        hlsInstance.currentLevel = selectedQuality; // Set the current quality level
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (!video) return null;

  const displayDate = video.dateTime ? video.dateTime : format(new Date(), 'PPP');

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='lg'
      slotProps={{
        backdrop: { sx: { backgroundColor: 'rgba(0, 0, 0, 0.85)', backdropFilter: 'blur(10px)' } },
      }}
    >
      <DialogContent dividers sx={{ mx: 0, px: 0 }}>
        <Stack sx={{ mb: 1, px: 2 }}>
          <Stack>
            <Typography variant='h5' fontWeight='bold'>
              {video.videoLabel}
            </Typography>
            <Typography variant='h6' color='text.secondary'>
              {video.videoNotes}
            </Typography>
          </Stack>

          <Button
            variant='text'
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            startIcon={<CloseRounded />}
          >
            Close
          </Button>
        </Stack>

        <Stack sx={{ mx: 0, px: 0 }}>
          <CardMedia
            component='video'
            id='video'
            ref={videoRef}
            controls
            autoPlay
            sx={{ height: 'auto' }}
          />
          <Typography variant='subtitle1' sx={{ mt: 1, px: 2 }}>
            Uploaded by {video.uploadedBy}
          </Typography>
          <Typography variant='body2' sx={{ color: grey[500], px: 2 }} gutterBottom>
            Uploaded on {new Date(displayDate).toLocaleDateString()}
          </Typography>

          {/* Quality Selector */}
          {qualityLevels.length > 0 && (
            <Stack direction='row' spacing={2} alignItems='center' sx={{ mt: 1, pl: 2 }}>
              <Typography>Quality:</Typography>
              <Select
                value={currentQuality}
                onChange={handleQualityChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Select video quality' }}
                variant='outlined'
                size='small'
              >
                <MenuItem value={-1}>Auto</MenuItem>
                {qualityLevels.map(level => (
                  <MenuItem key={level.index} value={level.index}>
                    {level.height}p
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          )}
        </Stack>
      </DialogContent>

      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='error' variant='filled'>
          <Stack>
            <Typography>{typeof error === 'string' && error}</Typography>
            <Typography>Please try again later</Typography>
          </Stack>
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default VideoModal;
