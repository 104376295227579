import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import InboxIcon from '@mui/icons-material/Inbox';
import ListAltIcon from '@mui/icons-material/ListAlt';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ScienceIcon from '@mui/icons-material/Science';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import CustomDrawer from '../../utils/components/CustomDrawer';
import CustomHeader from '../../utils/components/CustomHeader';
import CustomTabPanel from '../../utils/components/CustomTabPanel';
import BulkFileUpload from '../../views/CMSAdmin/CaseAdminModules/BulkFileUploadModule';
import ELabPMBatchesView from './LMSModules/ELabPMBatchesView';
import ELabPMCaseListView from './LMSModules/ELabPMCaseListView';

export default function LMSView() {
  const [currentTab, setCurrentTab] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  // Adding the new item for Elab PM Batches in the drawer
  const drawerContents = [
    // { text: 'Requests', icon: <InboxIcon /> },  -- Commented out to remove the Requests tab while under construction
    { text: 'eLab PM Cases', icon: <ListAltIcon /> },
    { text: 'eLab PM Batches', icon: <ScienceIcon /> },
    // { text: 'Final Review', icon: <NoteAltIcon /> }, -- Commented out to remove the Final Review tab while under construction
    { text: 'Bulk Upload', icon: <DriveFolderUploadIcon /> },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CustomDrawer
        drawerContents={drawerContents}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        {/* <CustomTabPanel value={currentTab} index={0} sx={{ margin: 0 }}>
          <CustomHeader
            title='Requests'
            description='View all current Client Tox requests and schedule testing'
            handleMenuClick={handleMenuClick}
          />
          <LMSTestRequestsView />  
        </CustomTabPanel> */}
        <CustomTabPanel value={currentTab} index={0} sx={{ margin: 0 }}>
          <CustomHeader
            title='eLab PM Case List'
            description='View all current eLab post-mortem cases'
            handleMenuClick={handleMenuClick}
          />
          <ELabPMCaseListView />
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={1} sx={{ margin: 0 }}>
          <CustomHeader
            title='eLab PM Specimen Batches'
            description='View all  batches of eLab specimes for testing'
            handleMenuClick={handleMenuClick}
          />
          <ELabPMBatchesView />
        </CustomTabPanel>
        {/* <CustomTabPanel value={currentTab} index={3} sx={{ margin: 0 }}>
          <CustomHeader
            title='Awaiting Final Review'
            description='View cases that are awaiting  final review'
            handleMenuClick={handleMenuClick}
          />
           <ELabPMAwaitingReview /> 
        </CustomTabPanel> */}
        <CustomTabPanel value={currentTab} index={2} sx={{ margin: 0 }}>
          <CustomHeader
            title='Bulk Upload Tox to CMS'
            description='Upload multiple final tox reports (PDF) to CMS at once'
            handleMenuClick={handleMenuClick}
          />
          <BulkFileUpload />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
